import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import CancelButton from '../../components/CancelButton';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import { useSendNotificationMutation, useUpdateOrderMutation } from '../../state/api';
import { useEffect, useState } from 'react';
import { IoMdArrowBack, IoMdCall } from 'react-icons/io';
import ons from 'onsenui';
import ContactBottomSheet from '../../components/ContactBottomSheet';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OrderDetail = () => {
  const location = useLocation();
  const [order, setOrder] = useState(location.state.order);
  let isFromCart = location.state.cart;
  const [updateOrder] = useUpdateOrderMutation();
  const [sendNotification] = useSendNotificationMutation();
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showBottom, setShowBottom] = useState(false);
  const [refreshUseEffect, setRefreahUseEffect] = useState(false);

  const handleShowAlert = () => {
    setAlertOpen(true);
  };

  const handleOrderUpdate = () => {
    if (order.attributes.status === 'User-Cancelled') {
      setAlertOpen(false);
      alert('Order already cancelled');
    } else {
      const data = { id: order.id, status: 'User-Cancelled' };
      setLoading(true);
      updateOrder({ order: data, token: localStorage.getItem('token') })
        .unwrap()
        .then((d) => {
          const data = {
            to: `merchant_${order.attributes.merchant.data.attributes.phone.replace('+', '')}`,
            title: 'Order Update!',
            ack: 'zm100',
            content: `Order ${order.id} has been cancelled by the user!`,
          };
          sendNotification({ body: data, token: localStorage.getItem('token') })
            .unwrap()
            .then((v) => {
              var attributes = {
                ...d.data.attributes,
                merchant: location.state.order.attributes.merchant,
              };
              var tempOrder = { id: d.data.id, attributes: attributes };

              setOrder(tempOrder);
              setAlertOpen(false);
              setLoading(false);
            })
            .catch((e) => {
              alert(e.message);
              setLoading(false);
            });
        })
        .catch((e) => {
          alert(e.message);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    ons.ready(() => {
      ons.enableDeviceBackButtonHandler();
      ons.setDefaultDeviceBackButtonListener((ev) => {
        if (showBottom === true) {
          setShowBottom(false);
        } else {
          navigate(-1);
          // window.location.reload();
        }
      });
    });
  }, [showBottom]);

  const navigate = useNavigate();
  const handleCall = () => {
    window.location.href = `tel:${order.attributes.merchant.data.attributes.phone}`;
  };

  useEffect(() => {
    window.addEventListener('MyPushEvent', function (msg) {
      // if (msg.title == "Order Update!") {
      //   refreshData();
      //   toast.info("Order status updated !");
      // }
      // if (msg.title === "Delivery Partner Assigned") {
      //   console.log("From foreground: ", msg.title);
      //   refreshData();
      // }
    });

    window.addEventListener('ack', function (msg) {
      if (msg.ack == 'zm100') {
        refreshData();
        toast.info('Order status updated !');
      }

      if (msg.ack === 'zm202') {
        refreshData();
      }
    });
  }, []);

  const refreshData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/orders/${order.id}?populate=*`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((response) => {
        setOrder(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/orders/${order.id}?populate=*`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((response) => {
        setOrder(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refreshUseEffect]);

  return (
    <div>
      {/* <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
        closeOnClick={true}
        draggable={true}
        pauseOnHover
        theme="colored"
        transition={Zoom}
      /> */}
      <ContactBottomSheet setShowBottom={setShowBottom} showBottom={showBottom} setShowMap={setShowMap} showMap={showMap} contacts={order.attributes.merchant.data.attributes.contacts && order.attributes.merchant.data.attributes.contacts.length > 0 ? order.attributes.merchant.data.attributes.contacts : [order.attributes.merchant.data.attributes.phone]} />

      <div className="bg-background-primary z-10 fixed top-0 text-header-text w-full py-3 px-4 flex justify-center shadow-sm items-center ">
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center gap-4">
            <IoMdArrowBack
              onClick={() => {
                navigate(-1);
                // window.location.reload();
              }}
              size={24}
            />
          </div>
          <div className="w-full font-bold  text-lg items-center text-center flex justify-center">Order Details</div>
          <div
            onClick={() => {
              const phoneNumber = '+919940285902'; // Replace with the actual phone number

              window.open(`tel:${phoneNumber}`);
            }}
            className=" font-normal text-md underline gap-1 text-primary items-center text-end flex justify-center"
          >
            Support
          </div>
        </div>
      </div>
      {isAlertOpen && (
        <div className="fixed z-20 p-2 bg-background-secondary bg-opacity-30 w-full h-screen inset-0 flex items-center justify-center">
          <div className="bg-background-primary max-w-md p-6 rounded-2xl shadow-lg relative">
            <div className="flex justify-between">
              <h2 className="text-xl font-semibold text-primary"></h2>
              <FaTimes className="text-content-text cursor-pointer" onClick={() => setAlertOpen(false)} />
            </div>
            <div className="text-center">
              <h2 className="text-xl font-semibold mb-2  text-primary"> Do you want to cancel this order?</h2>
              {/* <p className="text-header-text mb-4">
            By clicking on 'Yes'  your order will be cancelled. 
            </p> */}
              <strong className="font-bold text-alert">Warning! </strong>
              <span className="block sm:inline mb-">Your order cancellation is in progress. Too many cancellations may lead to your account being blocked.</span>
              <br />

              {loading && (
                <div className="p-4   flex justify-center items-center mt-1 gap-4">
                  {' '}
                  <div role="status" className="flex items-center justify-center h-8 w-8">
                    <svg className="w-24 h-24 mr-2 text-sub-text animate-spin dark:text-content-text fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    {/* <span className="sr-only">Loading...</span> */}
                  </div>
                </div>
              )}

              <div className="flex justify-between">
                {!loading && (
                  <button
                    onClick={() => {
                      setAlertOpen(false);
                    }}
                    className="bg-primary-lighter  hover:bg-primary-lighter/75  text-primary font-semibold py-2 px-4 rounded-md w-32"
                  >
                    No
                  </button>
                )}

                {!loading && (
                  <button
                    onClick={() => {
                      handleOrderUpdate();
                    }}
                    className="bg-primary hover:bg-primary/75  text-sub-text font-semibold py-2 px-4 rounded-md w-32"
                  >
                    Yes
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mt-8 mb-8 flex flex-col-reverse p-2 md:flex-row items-center md:items-start w-full">
        <div
          className=" flex flex-col text-sm w-fully 
      mt-4 bg-background-primary md:w-1/4 w-full p-4 md:border-e-2"
        >
          <h1 className="text-header-text text-md font-bold flex text-start ">Order Summary </h1>

          <div className="flex justify-between items-center mt-4">
            <h1 className="text-content-text font-medium">Order Status </h1>
            <p className="text-primary font-bold ">{order.attributes.status === 'User-Cancelled' ? 'Cancelled' : order.attributes.status}</p>
          </div>
          {order.attributes.pointsEarned && order.attributes.pointsEarned > 0 && (
            <div className="flex justify-between items-center mt-4">
              <h1 className={`text-header-text font-bold text-md ${order.attributes.status === 'Cancelled' || order.attributes.status === 'User-Cancelled' ? 'line-through' : ''}`}>Points Earned</h1>
              <p className={`text-sub-text p-2 rounded-full text-md font-extrabold  bg-secondary ${order.attributes.status === 'Cancelled' || order.attributes.status === 'User-Cancelled' ? 'line-through' : ''}`}>+{order.attributes.pointsEarned}</p>
            </div>
          )}
          {order.attributes.pointsToRedeem && order.attributes.pointsToRedeem > 0 && (
            <div className="flex justify-between w-full text-center items-center mt-4">
              <h1 className={`text-header-text w-full text-start font-bold text-sm ${order.attributes.status === 'Cancelled' || order.attributes.status === 'User-Cancelled' ? 'line-through' : ''}`}>Points Redeemed</h1>
              <p className={`text-sub-text text-center rounded-full p-2 text-sm font-extrabold  bg-alert ${order.attributes.status === 'Cancelled' || order.attributes.status === 'User-Cancelled' ? 'line-through' : ''}`}>-{order.attributes.pointsToRedeem}</p>
            </div>
          )}

          <div className="flex justify-between items-center mt-4">
            <h1 className="text-content-text font-medium">Purchase Method </h1>
            <p className="text-header-text font-bold ">{order.attributes.purchaseMethod}</p>
          </div>
          {/* <div className="flex justify-between items-center mt-4">
            <h1 className="text-content-text">Merchant Phone </h1>
            <p className="text-header-text font-bold ">{order.attributes.phoneMerchant}</p>
          </div> */}
          <div className="flex justify-between items-center mt-4">
            <h1 className="text-content-text font-medium">Order Date </h1>
            <p className="text-header-text font-bold ">{moment(order.attributes.date).format('HH:mm DD/MM/YY')}</p>
          </div>

          <div className="flex justify-between  items-center mt-4">
            <h1 className="text-content-text font-medium">Subtotal </h1>
            <p className="text-header-text font-bold ">₹{order.attributes.total + order.attributes.cashRedeem}</p>
          </div>
          {order.attributes.cashRedeem !== null && order.attributes.cashRedeem > 0 && (
            <div className="flex justify-between items-center mt-4">
              <h1 className={`text-header-text font-medium ${order.attributes.status === 'Cancelled' || order.attributes.status === 'User-Cancelled' ? 'line-through' : ''}`}>Cash Redeemed</h1>
              <p className={`text-header-text font-bold ${order.attributes.status === 'Cancelled' || order.attributes.status === 'User-Cancelled' ? 'line-through' : ''}`}>₹{order.attributes.cashRedeem}</p>
            </div>
          )}
          {order.attributes.discount > 0 && (
            <div className="flex justify-between items-center mt-4">
              <h1 className="text-header-text font-medium">Discount</h1>
              <p className="text-header-text font-bold ">₹{order.attributes.discount}</p>
            </div>
          )}
          <div className="flex text-lg justify-between items-center mt-4">
            <h1 className="text-header-text font-medium">Total</h1>
            <p className="text-header-text font-bold "> ₹{order.attributes.total - order.attributes.discount}</p>
          </div>

          {order.attributes.status === 'Pending' && (
            <div className="w-full p-4">
              <span className="text-xs italic">Points are rewarded once the order is Delivered</span>
            </div>
          )}

          <div
            onClick={() => {
              setShowBottom(true);
            }}
            className=" flex items-center gap-1 mt-4 text-primary  font-bold text-sm underline"
          >
            <IoMdCall size={18} className="mr-1" /> Call {order.attributes.merchant.data.attributes.name}
          </div>

          {order.attributes.purchaseMethod === 'Home-Delivery' && order.attributes.rider?.data?.attributes?.phone ? (
            <div>
              <h1 className="text-header-text text-md mt-4 font-bold flex text-start ">Delivery Partner Details </h1>
              <div className="flex justify-between items-center mt-2">
                <h1 className="text-content-text font-medium">Name </h1>
                <p className="text-header-text font-bold ">{order.attributes.rider?.data?.attributes?.name}</p>
              </div>
              <div
                onClick={() => {
                  window.location.href = `tel:${order.attributes.rider?.data?.attributes?.phone}`;
                }}
                className=" flex items-center gap-1 mt-4 text-primary  font-bold text-sm underline"
              >
                <IoMdCall size={18} className="mr-1" /> Call Delivery Partner
              </div>
            </div>
          ) : (
            <div>
              <h1 className="text-header-text text-md mt-4 font-bold flex text-start ">Delivery Partner Status: Not Assigned </h1>
            </div>
          )}
        </div>

        <div
          className="
            relative overflow-x-auto w-full md:w-3/4 sm:rounded-lg"
        >
          {/* <h1 className="text-header-text text-lg font-bold mt-4 flex text-start ">Store Detail</h1> */}
          <div className=" text-yellow-500  mt-8 flex gap-4 ml-2 md:ml-4  justify-start self-start tracking-wide text-sm text-header-text-500 font-semibold">
            {/* <div className="w-28 h-20">
                  <LazyLoadImage src={order.attributes.merchant.data.attributes.image} className="w-full h-full rounded-md object-cover"/>
                  </div> */}
            <div className="text-start w-full">
              <h3 className=" text-md font-bold text-header-text">{order.attributes.merchant.data.attributes.name}</h3>
              <h3 className="text-xs mb-2 font-medium text-content-text">{order.attributes.merchant.data.attributes.geolocation}</h3>
              {/* <h3 className="text-sm font-medium text-content-text">{order.attributes.merchant.data.attributes.phone}</h3> */}

              <MobileOnlyView>
                <div className="flex justify-between gap-2 w-full">
                  <button
                    type="button"
                    className=" w-full shadow-md items-center px-1 py-1  text-xs font-semibold text-accent border  border-accent rounded-md "
                    onClick={() => {
                      navigate('/orders/qr', { state: { id: order.id } });
                    }}
                  >
                    <span className="text-xs">GET QR CODE</span>
                  </button>
                  {order.attributes.status === 'Pending' && <CancelButton handleOrderUpdate={handleShowAlert} />}
                </div>

                {order.attributes.purchaseMethod === 'Home-Delivery' && order.attributes.status !== 'Cancelled' && order.attributes.status !== 'User-Cancelled' && order.attributes.status !== 'Delivered' && order.attributes.status !== 'Pending' && (
                  <button
                    type="button"
                    className={`w-full shadow-md items-center px-1 py-1 my-2 text-xs font-semibold rounded-md ${order.attributes.rider?.data?.attributes?.phone ? 'text-primary-lighter border border-primary-lighter' : 'text-[rgb(160,160,160)] border border-[rgb(160,160,160)]'}`}
                    disabled={order.attributes.rider?.data?.attributes?.phone ? false : true}
                    onClick={() => {
                      navigate(`/orders/track/orders-details?order=${order.id}`);
                    }}

                    // navigate("/orders/track-order", {
                    //   state: { id: order.id },
                    // });
                  >
                    <span className="text-xs">Track Order</span>
                  </button>
                )}
              </MobileOnlyView>
            </div>
          </div>

          <hr className="mt-2" />
          <h1 className="text-header-text text-sm font-bold mt-4 flex text-start mb-2 ml-2">Items </h1>
          <table className="table-auto w-full text-sm text-left">
            <tbody>
              <tr className="bg-background-primary border-b ">
                <th scope="row" className="text-xs md:w-1/2 lg:w-1/4 uppercase px-6 py-4 font-medium text-header-text whitespace-nowrap">
                  Name
                </th>
                <td className="text-xs md:w-1/2 lg:w-1/4 px-4 py-2 ">Qty</td>
                <td className="text-xs md:w-1/2 lg:w-1/4 px-4 py-2">Discount</td>
                <td className="text-xs md:w-1/2 lg:w-1/4 px-4 py-2">Amount</td>
              </tr>
              {order.attributes.products.map((p) => {
                return (
                  <tr className="bg-background-primary border-b">
                    <th scope="row" className=" break-words text-xs md:w-1/2 lg:w-1/4 uppercase px-6 py-4 font-medium text-header-text whitespace-normal">
                      {p.name}
                    </th>
                    <td className="text-xs md:w-1/2 lg:w-1/4 px-4 py-2 ">
                      x{p.quantity}
                      {p.unit === 'unit' ? '' : p.unit}
                    </td>
                    <td className=" text-xs md:w-1/2 lg:w-1/4 px-4 py-2">₹{p.unit === 'g' || p.unit === 'ml' ? p.discount / 1000 : p.discount}</td>
                    <td className="text-xs md:w-1/2 lg:w-1/4 px-4 py-2">₹{p.unit === 'g' || p.unit === 'ml' ? p.price / 1000 : p.price}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <BrowserView>
            <div className="flex mt-4 w-full p-2 bg-background-primary justify-center flex-col gap-2 items-center">
              <button
                type="button"
                className=" w-1/3 shadow-md items-center px-4 py-2.5  text-md font-semibold text-sub-text bg-accent rounded-md "
                onClick={() => {
                  navigate('/orders/qr', { state: { id: order.id } });
                }}
              >
                <span>GET QR CODE</span>
              </button>
              {order.attributes.status === 'Pending' && (
                <button type="button" className=" w-1/3 items-center px-4 py-2  text-md font-semibold text-alert border border-alert rounded-md " onClick={handleShowAlert}>
                  <span>CANCEL ORDER</span>
                </button>
              )}
              {order.attributes.purchaseMethod === 'Home-Delivery' && order.attributes.status !== 'Cancelled' && order.attributes.status !== 'User-Cancelled' && order.attributes.status !== 'Delivered' && order.attributes.status !== 'Pending' && (
                <button
                  type="button"
                  className={`w-1/3 shadow-md items-center px-4 py-2.5 text-md font-semibold rounded-md ${order.attributes.rider?.data?.attributes?.phone ? 'text-primary-lighter border border-primary-lighter' : 'text-[rgb(160,160,160)] border border-[rgb(160,160,160)]'}`}
                  disabled={order.attributes.rider?.data?.attributes?.phone ? false : true}
                  onClick={() => {
                    navigate(`/orders/track/orders-details?order=${order.id}`);
                  }}

                  // navigate("/orders/track-order", {
                  //   state: { id: order.id },
                  // });
                >
                  <span className="text-md">TRACK ORDER</span>
                </button>
              )}
            </div>
          </BrowserView>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
