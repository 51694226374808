import { useNavigate } from "react-router-dom";
import CallButton from "./CallButton";
import DirectionsButton from "./DirectionsButton";
import ShareButton from "./ShareButton";
import { IoMdTrain } from "react-icons/io";
import { IoMdBicycle } from "react-icons/io";
import { HiTruck } from "react-icons/hi2";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaStore } from "react-icons/fa";

export default function MerchantCard({ merchant, user }) {

  const navigate = useNavigate()

  const [businessCategories, setBusinessCategories] = useState("")
  useEffect(() => {
    if (merchant && merchant.businessCategories) {
      setBusinessCategories(merchant.businessCategories.join(" \u2022"))
    }
  }, [merchant])

  return (
    <div
      className="m-0 md:hover:scale-105 justify-center text-6xl cursor-pointer "

    >
      <div className="text-center flex items-center flex-row md:flex-col justify-start">
        <div className="flex flex-row items-center md:flex-col w-full" onClick={() => { navigate('/store-details', { state: { merchant: merchant, user: user } }) }}>


          <div className="md:h-36 h-32 w-28 md:w-full ">
            <img
              // placeholder={<FaStore className="items-center flex justify-center"/>}
              src={merchant.image && merchant.image.replace("/upload/", "/upload/w_200/")}
              className="rounded-lg object-cover h-32 md:h-36 w-28 md:w-full shadow-md"
            />
          </div>
          <div className="text-start p-2 flex-1" >

            <div className="flex gap-1 justify-between items-center">


              <p className="text-lg  font-extrabold text-primary w-full line-clamp-1 capitalize">{merchant.name}</p>

            </div>

            <p className="text-xs w-full font-semibold text-content-text">&bull;{businessCategories}</p>
            <div className="flex w-full justify-start mt-2 items-end">
              {
                !merchant.acceptingOrders && <p className="text-xs text-end bg-alert-lighter py-1 px-2 rounded-xl font-semibold text-alert-darker">
                  Closed
                </p>
              }
              {
                merchant.acceptingOrders && <p className="text-xs text-end bg-success-lighter py-1 px-2 rounded-xl font-semibold text-success-darker">
                  Open
                </p>
              }
            </div>
            <div className="flex gap-2 justify-between items-center mt-2">
              <p className="text-sm font-regular text-content-text line-clamp-1">
                {merchant.geolocation}{" "}
              </p>

            </div>
            <p className="text-sm whitespace-nowrap text-content-text font-normal">
              {(merchant.distance / 1000).toFixed(2)}{" km"}
            </p>

            {/* <div className="flex justify-between items-center mt-2">
          <p className="text-sm font-semibold text-content-text">
            Shop Timing
          </p>
          <p className="text-sm font-semibold text-header-text">
          {merchant.timeOpen.split(":")[0]}:{merchant.timeOpen.split(":")[1]} - {merchant.timeClosed.split(":")[0]}:{merchant.timeClosed.split(":")[1]}
          </p>
         </div> */}
            {/* {
        merchant.deliveryRadius && merchant.deliveryRadius > 0 && 
        <div className="flex items-center  mt-2">
          <HiTruck size={24} className="text-accent-darker"/>
           <p className="text-xs font-semibold text-accent-darker">Delivering upto {merchant.deliveryRadius}km</p>
        </div>
       } */}


          </div>
        </div>


        {/* <div className="flex bottom-0 w-full justify-between gap-1">
          
          <div className=" border-e-2 items-center w-full justify-center flex">
            <CallButton phone={merchant.phone} />
          </div>
          <div className="border-e-2 items-start w-full  justify-center  flex">
            <DirectionsButton lat={merchant.latitude} lng={merchant.longitude}/>
          </div>
        {merchant.shopLink &&   <div className="items-center w-full justify-center flex">
           <ShareButton storeName={merchant.name} url={merchant.shopLink} /> 
          </div>
        }
        </div> */}
      </div>
    </div>
  )
}