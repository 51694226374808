import React, { useState } from "react"
import { Fa500Px, FaAccessibleIcon, FaBan, FaLocationArrow, FaOpencart, FaShopify, FaShoppingCart, FaShopware, FaTimes, FaTruck } from "react-icons/fa"
export default function FilterModal({activeFilter, setActiveFilter, isFilterApplied, setIsFilterApplied,filteredBy, setFilteredBy, handleMerchantRefetch}){
   
    const handleFilter = (filter) => {
        setFilteredBy(filter)
        setIsFilterApplied(false)
        if (filteredBy !== filter) {
            handleMerchantRefetch()
        }
    }
    const clearFilter = () => {
        setFilteredBy("")
        setIsFilterApplied(false)
       if (filteredBy.trim().length > 0) {
        handleMerchantRefetch()
       }
    }
    return (
        <div className="fixed z-50 p-2 top-0 bg-background-secondary bg-opacity-30 w-full h-full flex items-center justify-center">
        <div className="bg-background-primary md:w-1/3 w-full p-6 rounded-lg shadow-lg">
          <div className="text-start">
           <div className="flex w-full justify-between items-center">
           <p className="text-xl font-bold text-header-text">Filter</p>
           <FaTimes onClick={()=>{
            setIsFilterApplied(!isFilterApplied)
           }} size={20} className=" cursor-pointer"/>
           </div>
            {/* <p className="text-header-text mb-4">
            By clicking on 'Yes'  your order will be cancelled. 
            </p> */}
              <hr/>

            <div className="flex w-full justify-between">
             <div className="flex flex-col gap-2">

              <div onClick={()=>{
                setActiveFilter("Deliver")
              }} className="flex gap-2 cursor-pointer items-center">
               <div className={`w-[8px] ${activeFilter === "Deliver" ? "visible translate-y-0" : "invisible translate-y-2"} transition transform h-full rounded-e-md bg-primary`}></div>
             <p className="text-md font-semibold text-header-text p-2">Delivery</p>
              </div>
              <div onClick={()=>{
                setActiveFilter("Available")
              }} className="flex gap-2 cursor-pointer items-center">
            <div className={`w-[8px] ${activeFilter === "Available" ? "visible translate-y-0 " : "invisible -translate-y-2 "} transition transform   h-full rounded-e-md bg-primary`}></div>
             <p className="text-md font-semibold text-header-text p-2">Availability</p>
              </div>

             </div>
             <div className="w-[1px] h-auto bg-background-secondary"></div>
             <div className="flex flex-col gap-2 w-full">
             { activeFilter === "Deliver" &&  <div className="">
              <p className="text-sm font-normal text-content-text p-2">Filter by</p>
             <p onClick={()=>{
                handleFilter("delivery")
             }} className={`text-sm font-semibold cursor-pointer ${filteredBy === "delivery" ? "text-primary" : "text-content-text"} p-2 flex items-center gap-2`}><FaTruck/> Home Delivery</p>
                 <p onClick={()=>{
                handleFilter("nearByDelivery")
             }} className={`text-sm font-semibold cursor-pointer ${filteredBy === "nearByDelivery" ? "text-primary" : "text-content-text"} p-2 flex items-center gap-2`}><FaLocationArrow/> Delivering Near You</p>
             <p onClick={()=>{
                clearFilter()
             }} className="text-sm cursor-pointer font-semibold text-content-text p-2">Clear Filter</p>
              </div> }

              { activeFilter === "Available" &&  <div className="">
              <p className="text-sm font-normal text-content-text p-2">Filter by</p>
             <p onClick={()=>{
               handleFilter("accepting")
             }} className={`text-sm font-semibold ${filteredBy === "accepting" ? "text-primary" : "text-content-text"} cursor-pointer  p-2 flex items-center gap-2`}><FaShoppingCart/> Accepting Orders</p>
             <p onClick={()=>{
               handleFilter("not accepting")
             }} className={`text-sm font-semibold cursor-pointer ${filteredBy === "not accepting" ? "text-primary" : "text-content-text"} p-2 flex items-center gap-2`}><FaBan/> Not Accepting Orders</p>
             <p onClick={()=>{
               clearFilter()
             }} className="text-sm cursor-pointer font-semibold text-content-text p-2">Clear Filter</p>
              </div> }
             </div>
            </div>
          </div>
        </div>
      </div>
    )
}