
import { IoMdArrowForward, IoMdQrScanner } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

const CartBanner = ({isOtherMerchant,otherCartId,merchant,user,cart, dbCart, handleCartApi}) => {
    const navigate = useNavigate()
    let  totalItems = 0
    let totalPrice = 0
    for (let index = 0; index < cart.length; index++) {
      const element = cart[index];
      totalItems += element.originalQuantity
      totalPrice += (element.unit === "g" || element.unit === "ml" ? element.price/1000 - element.discount/1000 : element.price - element.discount) * element.originalQuantity
    }
    return (
        <div className="flex flex-col items-center justify-end ">

       
        <button
        onClick={()=>{
          navigate(`/items/cart`, { state: {isOtherMerchant:isOtherMerchant,otherCartId:otherCartId, dbCart:dbCart,cart:cart,user:user, merchant:merchant}, replace:true})
        
         
        }}
        className="fixed bottom-0 w-full md:w-2/4 md:m-auto px-4 flex items-center  justify-between  gap-2  z-20 py-3 bg-secondary  text-sub-text rounded-t-md shadow-lg"
      >
        <div className="w-full text-start flex flex-col">
        <span className=" text-sm"><span className="font-bold">{totalItems}</span>{totalItems > 1 ? " Items" : " Item"}</span>
        <span className="text-md font-bold">₹{totalPrice.toFixed(2)}</span>
        </div>
        <span className=" text-end font-bold text-sm w-full">View Cart</span>
      </button>
      </div>
    );
  };
  
  export default CartBanner;
  