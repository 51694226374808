import axios from "axios";
import { useEffect, useState } from "react";
import { auth } from "../firebaseConfig";

export default function ErrorComponent({ error, info,resetErrorBoundary }) {
 
    return (
      <div className="w-screen h-screen">
       <div className="p-4 flex w-full gap-4 h-full flex-col items-center justify-center">
       <p>Something went wrong!</p>
       <button
              type="button"
              className="bg-alert text-sub-text p-2 md:w-1/2 w-full"
              onClick={()=>{
                const phoneNumber = '+919940285902'; // Replace with the actual phone number
     
                window.open(`tel:${phoneNumber}`);
              }}
            >
              Contact Support
            </button>
            <button
              type="button"
              className="border text-alert bg-background-primary border-alert p-2 md:w-1/2 w-full"
              onClick={() => {resetErrorBoundary()
            }}
            >
              Try again?
            </button>
       </div>
      </div>
    );
  }
  