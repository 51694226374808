import { initializeApp } from 'firebase/app'
import {getAuth, signInWithPhoneNumber} from 'firebase/auth'
import { getStorage } from "firebase/storage";
import { getMessaging  } from "firebase/messaging/sw";


const firebaseConfig = {
  apiKey: "AIzaSyAOjIVgVcBkcYJ9owZUWJKaFqC5VjUA4wQ",
  authDomain: "zundermart-app.firebaseapp.com",
  projectId: "zundermart-app",
  storageBucket: "zundermart-app.appspot.com",
  messagingSenderId: "334670628319",
  appId: "1:334670628319:web:a3a90250e24a849d8df06a",
  measurementId: "G-7D4DGNEPGL"
}

const fireBaseVapidKey = 'BEj_R5NuZTQBrFGthiE_FRYq6xhaeuJ6CV2lKKN9ZSUZYG2q0e9Ej_HZSgeYjMq45_hezh4KdeVRHyz2JM34OmE';

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const storage = getStorage(app)
const getMessage = () => getMessaging(app)

const signin = (phoneNumber, verifier) => signInWithPhoneNumber(auth, phoneNumber, verifier);
export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  };

export { signin, auth, storage, getMessage, app, fireBaseVapidKey }