import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    // baseUrl: "http://localhost:1337"
  }),
  reducerPath: 'adminApi',
  tagTypes: ['Merchants', 'Products', 'Customers', 'Orders'],
  endpoints: (build) => ({
    sendNotification: build.mutation({
      query: ({ body, token }) => ({
        url: `/api/orders/notify`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: body,
      }),
      providesTags: ['Notification'],
    }),
    createItemSubscription: build.mutation({
      query: ({ subscription, token }) => ({
        url: `/api/customer-item-subscriptions`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { data: subscription },
      }),
      providesTags: ['Subscription'],
    }),
    updateItemSubscription: build.mutation({
      query: ({ subscription, token }) => ({
        url: `/api/customer-item-subscriptions/${subscription.id}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { data: subscription },
      }),
      providesTags: ['Subscription'],
    }),
    createCart: build.mutation({
      query: ({ cart, token }) => ({
        url: `/api/carts`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { data: cart },
      }),
      providesTags: ['Cart'],
    }),
    deleteCart: build.mutation({
      query: ({ cart, token }) => ({
        url: `/api/carts/${cart.id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ['CartDelete'],
    }),
    getSubscriptionItems: build.query({
      query: ({ customer, token }) => ({
        url: `/api/customer-item-subscriptions?filters[$and][0][customer][id][$eq]=${customer}&populate=*`,
        method: 'GET',
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      }),
      providesTags: ['Subscription'],
    }),
    getCart: build.query({
      query: ({ customer, token }) => ({
        url: `/api/carts?filters[$and][0][customer][id][$eq]=${customer}&populate[merchant][populate]=*`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ['CartCreate'],
    }),
    updateCart: build.mutation({
      query: ({ cart, token }) => ({
        url: `/api/carts/${cart.id}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { data: cart },
      }),
      providesTags: ['CartUpdate'],
    }),
    updateOrder: build.mutation({
      query: ({ order, token }) => ({
        url: `/api/orders/${order.id}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { data: order },
      }),
      invalidatesTags: ['Orders'],
    }),
    createOrder: build.mutation({
      query: ({ order, token }) => ({
        url: `/api/orders`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { data: order },
      }),
      invalidatesTags: ['Orders'],
    }),
    updateUserStatus: build.mutation({
      query: ({ userData, token }) => ({
        url: `/api/customers/${userData.id}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { data: userData },
      }),
      providesTags: ['CustomerUpdate'],
    }),
    createUser: build.mutation({
      query: ({ userData, token }) => ({
        url: `/api/customers`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { data: userData },
      }),
      providesTags: ['CustomerAdd'],
    }),
    getUser: build.query({
      query: ({ id, token }) => ({
        url: `/api/customers?filters[phone][$eq]=${id}&populate[customer_points][populate][merchant][populate]=*&populate[customer_points][populate][loyalty][populate]=*`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ['UserGet'],
    }),
    getMerchants: build.query({
      query: ({ page, token, latitude, longitude, category, searchName, acceptingOrders, deliveryRadius, nearByDelivery }) => ({
        url: `${process.env.REACT_APP_IS_WHITELISTED === 'true' ? `/api/merchants?filters[phone][$eq]=${process.env.REACT_APP_STORE_PHONE}&pagination[page]=${page}&populate=*` : `/api/merchants?${acceptingOrders !== null ? `filters[acceptingOrders][$eq]=${acceptingOrders}` : ''}&${deliveryRadius !== null ? `filters[deliveryRadius][$gt]=${0}` : ''}&filters[businessCategories][$containsi]=${category}&filters[name][$containsi]=${searchName}&pagination[page]=${page}&latitude=${latitude}&longitude=${longitude}&${nearByDelivery !== null ? `nearByDelivery=${nearByDelivery}` : ''}&populate=*`}`,
        method: 'GET',

        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      }),
      providesTags: ['Merchants'],
    }),
    getOrders: build.query({
      query: ({ page, customerId, token }) => ({
        url: `${process.env.REACT_APP_IS_WHITELISTED === 'true' ? `/api/orders?sort[0]=date:desc&pagination[page]=${page}&filters[$and][0][merchant][id][$eq]=${process.env.REACT_APP_MERCHANT_ID}&filters[$and][0][customer][id][$eq]=${customerId}&populate=*` : `/api/orders?sort[0]=date:desc&pagination[page]=${page}&filters[$and][0][customer][id][$eq]=${customerId}&populate=*`}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ['Orders'],
    }),
    getMerchant: build.query({
      query: ({ merchantId, token }) => ({
        url: `/api/merchants/${merchantId}`,
        method: 'GET',
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      }),
      providesTags: ['Merchant'],
    }),
    getMerchantItems: build.query({
      query: ({ search, filter, page, merchantId, token }) => ({
        url: `/api/merchant-products?pagination[page]=${page}&filters[merchant][$eq]=${merchantId}&${search}&${filter}&populate=*`,
        method: 'GET',
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      }),
      providesTags: ['Item'],
    }),
    getMerchantCategories: build.query({
      query: ({ token }) => ({
        url: `/api/business-categories/`,
        method: 'GET',
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      }),
      providesTags: ['Categories'],
    }),

    // getUserProfile: build.query({
    //     query:({phoneClient, token}) =>({
    //         url:`/api/orders?filters[phoneClient][$eq]=${phoneClient}`,
    //         method:'GET',
    //         headers: {
    //           Authorization: `Bearer ${token}`
    //         }
    //     }),
    //     providesTags:["User"]
    // }),

    getOrderDetials: build.query({
      query: ({ orderId, token }) => ({
        url: `/api/orders/${orderId}?populate=*`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ['Orders'],
    }),
  }),
});

export const { useGetSubscriptionItemsQuery, useUpdateItemSubscriptionMutation, useCreateItemSubscriptionMutation, useSendNotificationMutation, useDeleteCartMutation, useUpdateCartMutation, useGetCartQuery, useCreateCartMutation, useGetMerchantCategoriesQuery, useGetMerchantsQuery, useCreateUserMutation, useUpdateUserStatusMutation, useGetOrdersQuery, useGetMerchantItemsQuery, useGetMerchantQuery, useUpdateOrderMutation, useCreateOrderMutation, useGetUserQuery, getOrderDetials } = api;
