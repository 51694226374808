import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io';

const LostComponent = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="bg-background-primary z-10 fixed top-0 text-header-text w-full py-3 px-4 flex justify-center shadow-sm items-center ">
        <div className="flex w-full justify-between items-center">
          <div className="w-full font-bold  text-lg items-center text-center flex justify-center">Looking for something?</div>
        </div>
      </div>
      <div className="bg-background-secondary flex justify-center items-center h-screen">
        <div className="flex flex-col items-center">
          <div className="mt-8 mb-8 p-2 md:flex-row md:items-start w-full max-w-md">
            <div className="flex flex-col text-sm w-full bg-background-primary p-4 md:border-e-2 rounded-lg shadow-lg">
              <h1 className="text-header-text text-md font-bold text-center">Hey! Looks like you're lost let's take you back</h1>
              <button
                className="bg-primary text-sub-text px-4 py-2 mt-4 rounded-md flex items-center justify-center hover:bg-primary/75 mx-auto w-2/3"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <IoMdArrowBack className="h-5 w-5 mr-2" /> Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LostComponent;
