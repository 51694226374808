import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { IoMdArrowBack } from "react-icons/io";
import { useZxing } from "react-zxing";

export default function Scan() {
  const [searchedBarcode, setSearchedBarcode] = useState('')
  const location = useLocation()
  const navigate = useNavigate()

  const user = location?.state?.user;
  const merchant = location?.state?.merchant;
  const cart = location?.state?.cart;
  const isOtherMerchant = location?.state?.isOtherMerchant;
  const otherCartId = location?.state?.otherCartId;
  const dbCart = location?.state?.dbCart;
  const token = localStorage.getItem("token")
  const [scan, setScan] = useState(null);
  const { deviceId } = useParams();

  const { ref } = useZxing({
    onDecodeResult(result) {
      // console.log(result.getText())
      // alert(result)
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/merchant-products?filters[barcode][$eq]=${result.getText()}&filters[merchant][$eq]=${merchant.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } })
        .then((r) => {
          if (r.data.data.length > 0) {
            navigate('/item/detail', { state: { item: r.data.data[0], isOtherMerchant: isOtherMerchant, otherCartId: otherCartId, dbCart: dbCart, merchant: merchant, user: user, cart: cart } })
          } else {
            toast.error("Product Not Found")
          }
        }).catch((e) => {
          alert(e.message)
        })
    },
    onDecodeError(error) {
      // console.log(error)
      // alert(error.message)
    },
    onError(error) {
      alert(error.message)
      setScanning(false)
    },
    timeBetweenDecodingAttempts: 3000,
    deviceId
  });
  if (!merchant) {
    window.location.replace("/")
  }
  const handleScan = (data) => {
    console.log(data)
  }
  const handleError = (err) => {
    console.error(err)
  }
  const handleSearchProduct = () => {
    if (searchedBarcode.trim().length > 0) {
      const headers = { Authorization: token ? `Bearer ${token}` : '' }
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/merchant-products?filters[barcode][$eq]=${searchedBarcode}&filters[merchant][$eq]=${merchant.id}`, { headers: headers })
        .then((r) => {

          if (r.data.data.length > 0) {
            navigate('/item/detail', { state: { item: r.data.data[0], isOtherMerchant: isOtherMerchant, otherCartId: otherCartId, dbCart: dbCart, merchant: merchant, user: user, cart: cart } })
          } else {
            toast.error("Product Not Found")
          }
        }).catch((e) => {
          alert(e.message)
        })


    } else {
      alert("Enter valid barcode")
    }
  }

  const [scanDirection, setScanDirection] = useState(true)
  const [scanning, setScanning] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setScanDirection(!scanDirection)
    }, 1000)
  }, [scanDirection]);

  return (
    <div className=" mt-16 md:mt-4">
      {/* <ToastContainer position="top-center" autoClose={3000} hideProgressBar={true} containerId="toast" /> */}
      <div>


        <div className="bg-background-primary z-10 fixed top-0 text-header-text w-full py-3 px-4 flex justify-start items-center gap-8 shadow-sm">
          {/* {showBackNavigation === true && !showBackToHome && <IoMdArrowBack onClick={()=>{navigate(-1)}} size={24}/>} */}
          <IoMdArrowBack onClick={() => { navigate(-1) }} size={24} />
          {/* <img src="/zmlogo.png" className="w-10"/> */}
          <span className="text-lg font-extrabold">Scan Barcode</span>

        </div>
      </div>
      <div className="mt-16">

        {/* {
        <BarcodeScannerComponent
      width="100%"
      height={200}
      
    delay={1000}
    //   stopStream={stopStream}
      onError={(err)=>{
        // toast.error(err.message)
      }}
      onUpdate={(err, result) => {
        if (result) {
          
          axios.get(`${process.env.REACT_APP_BASE_URL}/api/merchant-products?filters[barcode][$eq]=${result.text}&filters[merchant][$eq]=${merchant.id}`, {headers:{Authorization:`Bearer ${localStorage.getItem("token")}`}})
          .then((r)=>{
            if(r.data.data.length > 0){
              navigate('/item/detail', {state: {item: r.data.data[0], isOtherMerchant:isOtherMerchant, otherCartId:otherCartId, dbCart:dbCart, merchant:merchant, user:user, cart:cart}})
            } else {
              toast.error("Product Not Found")
            }
          }).catch((e)=>{
           alert(e.message)
          })
          
        } else {
          // setData("Not Found");
          // setStopStream(true)
       
        }
      }}
    />
     } */}
        {/* <video width="300" ref={ref} /> */}

       <div className='flex justify-center'>
       <div className={`h-fit w-fit relative overflow-hidden border-accent rounded-md p-2 ${!scanning && 'invisible'}`} >
          {scanning &&
            <div className={`
              w-full h-full absolute top-0  border-t-4 border-accent rounded-md
              transition-all duration-1000 ease-linear  
              ${scanDirection ? 'translate-y-0' : ' translate-y-full'} 
            `} />}

          <div className='rounded-md overflow-hidden '><video width="800" ref={ref} /></div>
        </div>
       </div>
        <div className="mt-8 text-center ml-4 mr-4 text-header-text flex flex-col">
          <span className="text-lg">OR</span>
          <input onChange={(e) => { setSearchedBarcode(e.target.value) }} className="rounded-lg border border-sub-text bg-background-primary mt-2 p-2" placeholder="Enter barcode" />
          <button onClick={handleSearchProduct} className="rounded-md p-2 mt-2 text-sub-text bg-primary">Search</button>
        </div>
      </div>
    </div>
  )
}