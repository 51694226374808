import { FaTrashAlt, FaTimes } from 'react-icons/fa';
const PermissionModal = ({ isOpen, closeModal, children }) => {
  if (!isOpen) return null;

  return (
    <div className=" z-50 select-none fixed inset-0  flex items-center justify-center  bg-black bg-opacity-50">
      <div className="relative mx-2 w-fit max-w-md md:max-w-2xl p-4  bg-background-primary rounded-lg shadow-lg">
        <button className="absolute top-2 right-2 text-content-text hover:text-header-text  rounded-md font-semibold p-1 " onClick={()=>{closeModal()}}>
          <FaTimes className="text-content-text cursor-pointer"/>
        </button>
        {children}
      </div>
    </div>
  );
};

export default PermissionModal;
