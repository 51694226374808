import React, { useState } from "react";

 
const containerStyle = {
  width: "100%",
  height: "400px"
};

const center = {
  lat: 37.7749,
  lng: -122.4194
};

const options = {
  disableDefaultUI: true
};

function DirectionsButton({lat, lng}) {
  const [selectedLocation, setSelectedLocation] = useState("");

  const selectLocation = () => {
    const googleMapsUrl = "https://maps.google.com?q="+lat+","+lng;
    const params = "parameters for location selection";

    window.open(`${googleMapsUrl}`, "_blank");
  };

  const handleLocationSelection = (location) => {
    setSelectedLocation(location);
  };

  return (
    <div className="flex p-4 shadow-md items-center w-full justify-center text-xs font-medium text-header-text bg-background-primary rounded-lg">
      <button type="button" className="text-primary inline-flex text-xs font-bold  bg-background-primary "
        onClick={selectLocation}
         >

        <svg
          className="h-4 w-8 text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8
8 0 1111.314 0z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
        Directions
      </button>
    </div>
  );
}

export default DirectionsButton;
