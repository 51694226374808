import {ImBlocked} from 'react-icons/im'

export default function BlockedComponent() {
 
    return (
      <div className="w-screen h-screen relative">
       <div className="p-4 flex w-full gap-4 h-full flex-col items-center justify-center -translate-y-10">
       <ImBlocked className='w-32 h-32 opacity-50 '/>
       <p className=" font-semibold text-lg">Acces denied! Account was blocked by admin</p>
       
       <button
              type="button"
              className="bg-alert text-sub-text p-2 md:w-1/2 w-full"
              onClick={()=>{
                const phone = '+919940285902'; 
     
                window.location.href = `tel:${phone}`;
              }}
            >
              Contact Support
            </button>
       </div>
      </div>
    );
  }
  