import { useEffect, useState } from "react";
import HeaderMobie from "../../components/HeaderMobile";
import BottomBar from "../../components/home/Bottom";
import Home from "./home";
import Orders from "./orders";
import Profile from "./profile";
import Settings from "./settings";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ProfilePage from "./profile";
import { App as CapacitorApp } from "@capacitor/app";
import { MobileOnlyView } from "react-device-detect";
import ons from "onsenui";

import axios from "axios";
export default function HomeIndex({
  user,
  activeMenu,
  setActiveMenu,
  showMap,
  setShowMap,
  scrollDown,
}) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const invitedBy = params.get("invitedBy");
  const navigate = useNavigate();
  const [merchantInvited, setMerchantInvited] = useState();
  useEffect(() => {
    if (invitedBy) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/merchants/${invitedBy}?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((d) => {
   
          setMerchantInvited({ id: d.data.data.id, ...d.data.data.attributes });
        })
        .catch((e) => {});
    }
  }, [invitedBy]);
  useEffect(() => {
    window.addEventListener("MyPushEvent", function (msg) {
      if (msg.merchantId) {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/api/merchants/${msg.merchantId}?populate=*`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((d) => {
      
            setMerchantInvited({
              id: d.data.data.id,
              ...d.data.data.attributes,
            });
          })
          .catch((e) => {});
      }
    });

    // // Cleanup the event listener on component unmount
    // return () => {
    //     window.removeEventListener('dynamicLinkData', dynamicLinkListener);
    // };
  }, []);
  useEffect(() => {
    ons.ready(() => {
      ons.enableDeviceBackButtonHandler();
      ons.setDefaultDeviceBackButtonListener((ev) => {
        if (activeMenu === "home") {
          CapacitorApp.exitApp();
        } else {
          setActiveMenu("home");
        }
      });
    });
  }, [activeMenu]);

    return (
        <div className="bg-background-primary  scroll-smooth transition-all">
            {/* <HeaderMobie/> */}
           <Outlet context={[{merchant:merchantInvited}]} />
           <MobileOnlyView>
           {!showMap && <BottomBar activeMenu={activeMenu} setActiveMenu={setActiveMenu} user={user} scrollDown={scrollDown}/>}
           </MobileOnlyView>
        </div>
    )
}
