import React, { useEffect, useState } from 'react';
import { FaUser, FaInfoCircle, FaShieldAlt, FaCommentAlt, FaPhone, FaSignOutAlt, FaEnvelope, FaTimes, FaPaperclip, FaStore, FaRupeeSign } from 'react-icons/fa';
import { auth } from '../../firebaseConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import Policies from '../../components/home/Policies';
import ProfilePage from './profile';
import { RWebShare } from 'react-web-share';
import { HiHeart } from 'react-icons/hi2';
import { IoMdArrowDown, IoMdRemove } from 'react-icons/io';
import { Share } from '@capacitor/share';
import HeaderBrowser from '../../components/HeaderBrowser';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import ons from 'onsenui';
import { Capacitor } from '@capacitor/core';
import moment from 'moment';
import { MdPrivacyTip, MdPolicy } from 'react-icons/md';
import { BsShareFill } from 'react-icons/bs';
import { IoCall } from 'react-icons/io5';
import { AiFillInfoCircle } from 'react-icons/ai';
import axios from 'axios';
import { Browser } from '@capacitor/browser';
import 'react-quill/dist/quill.snow.css';

function ZunderFooter() {
  const location = useLocation();

  return (
    <>
      <h1 className="text-xl md:text-3xl font-bold text-primary text-center mt-5 md:mt-10">{process.env.REACT_APP_IS_WHITELISTED === 'true' && 'Powered by '}ZUNDERMART</h1>
      <div className="flex w-full font-bold text-content-text mt-2  items-center gap-2 justify-center">
        Handcrafted with <HiHeart color="orange" size={24} /> in Goa, India
      </div>
    </>
  );
}

function About({ showMap, setShowMap, statusVisible, setStatusVisible, content }) {
  const toggleStatus = () => {
    setStatusVisible(!statusVisible);
  };

  return (
    <div className=" bg-background-primary overflow-y-auto bottom-0 z-30 md:t-0 mt-0  md:w-1/2 m-auto rounded-md shadow-2xl inset-0  md:inset-20 fixed p-4">
      <div className=" relative">
        <div className=" bg-background-primary sticky w-fit top-3  border rounded-md p-2">
          <FaTimes
            className=" text-content-text"
            onClick={() => {
              setShowMap(false);
              setStatusVisible(!statusVisible);
            }}
          />
        </div>

        {statusVisible && (
          <div>
            {process.env.REACT_APP_IS_WHITELISTED === 'true' && content ? (
              <div className="  text-header-text border p-4 mt-2 rounded quil w-full">
                <div className=" mt-12 p-4 w-full ql-container ql-snow " style={{ border: '0px' }}>
                  <div className=" ql-editor" dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              </div>
            ) : (
              <div className="bg-background-primary text-header-text p-4 border mt-4 rounded">
                <h1 className="text-2xl font-bold text-header-text mb-5">About Us!</h1>
                <div className="p-5 bg-background-primary text-left">
                  <p className="mb-4">We are here to provide a level playing field for retail players. Built by underdogs for the understated and ignored retailer. We hope to work with retailers increasing their efficiency so that they can compete just as well with their bigger tech-supplemented online players.</p>
                  <p className="mb-4">The aim is to bring down operational costs, increase efficiency, and push sales while still maintaining the human touch that can only be experienced in the offline world. There is far too much emphasis on taking everything online.</p>
                  <p className="mb-4">We have forgotten our roots, our local bazaars, the friendships shared with the local shopkeeper, and the fun feeling of touching, feeling, and looking at the physical product before purchasing it.</p>
                  <p className="mb-4">Here is an app handcrafted in Goa, India with love just for you. - ZunderDog</p>
                  <p>Note: We understand COVID and the precautions required to keep the virus from spreading and will hence also work at building a product that helps its users decrease their risk of catching it.</p>
                </div>
              </div>
            )}
          </div>
        )}
        <ZunderFooter />
      </div>
    </div>
  );
}

function PrivacyPolicy({ showMap, setShowMap, statusVisible, setStatusVisible, content }) {
  const toggleStatus = () => {
    setStatusVisible(!statusVisible);
  };

  // const [temp, setContent] = useState(content);

  return (
    <div className="select-none bg-background-primary overflow-y-auto  z-30 md:t-0 md:w-1/2 m-auto rounded-md shadow-2xl inset-0  md:inset-20 fixed p-4 mt-32">
      <div className=" relative">
        <div className=" bg-background-primary sticky w-fit top-3  border rounded-md p-2 cursor-pointer">
          <FaTimes
            className=" text-content-text"
            onClick={() => {
              setShowMap(false);
              setStatusVisible(!statusVisible);
            }}
          />
        </div>

        {statusVisible && (
          <div>
            {process.env.REACT_APP_IS_WHITELISTED === 'true' && content ? (
              <div className="  text-header-text border p-4 mt-2 rounded quil w-full">
                <div className=" mt-12 p-4 w-full ql-container ql-snow " style={{ border: '0px' }}>
                  <div className=" ql-editor" dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              </div>
            ) : (
              <div className=" text-header-text border p-4 mt-2 rounded">
                <h1 className="text-2xl font-bold text-header">Privacy Policy!</h1>
                <div className="flex items-start">
                  <div className=" ">
                    <div className="p-5  text-left">
                      {' '}
                      <div className="mb-5">
                        {/* <div className="text-sm font-bold">1. Introduction</div> */}
                        <div>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</div>
                      </div>
                      <div className="mb-5">
                        <div className=" text-2xl font-bold mb-5">Interpretation and Definitions</div>
                        <div className="text-lg font-bold">Interpretation</div>
                        <div>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</div>
                      </div>
                      <div className="mb-5">
                        <div className="text-lg font-bold mb-5">Definitions</div>
                        <div>For the purposes of this Privacy Policy:</div>
                        <ul className="list-disc ml-5 mt-2">
                          <li>
                            <span className="font-bold">Account</span> means a unique account created for You to access our Service or parts of our Service.
                          </li>
                          <li>
                            <span className="font-bold">Affiliate</span> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
                          </li>
                          <li>
                            <span className="font-bold">Application</span> means the software program provided by the Company downloaded by You on any electronic device, named “zundermart” or “zundermart partner”
                          </li>
                          <li>
                            <span className="font-bold">Company</span> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Zunderdog LLP, House No. 120/A, Velsao,.
                          </li>
                          <li>
                            <span className="font-bold">Country</span> refers to: Goa, India
                          </li>
                          <li>
                            <span className="font-bold">Device</span> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
                          </li>
                          <li>
                            <span className="font-bold">Personal Data</span> is any information that relates to an identified or identifiable individual.
                          </li>
                          <li>
                            <span className="font-bold">Service </span> refers to the Application.
                          </li>
                          <li>
                            <span className="font-bold">Service Provider </span> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
                          </li>
                          <li>
                            <span className="font-bold">Usage Data </span> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                          </li>
                          <li>
                            <span className="font-bold">You </span> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                          </li>
                        </ul>
                      </div>
                      <div className="text-2xl font-bold mb-5">Collecting and Using Your Personal Data</div>
                      <div className="text-xl font-bold mb-5">Types of Data Collected</div>
                      <div className="text-lg font-bold">Personal Data</div>
                      <div>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</div>
                      <ul className="list-disc ml-5 mt-2">
                        <li>First name and last name</li>
                        <li>Phone number</li>
                        <li>Address, State, Province, ZIP/Postal code, City</li>
                        <li>Usage Data</li>
                      </ul>
                      <div className="text-lg font-bold mt-5">Usage Data</div>
                      <div>Usage Data is collected automatically when using the Service.</div> <br /> <div>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</div>
                      <br /> <div>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</div> <br /> <div>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</div>
                      <div className="text-lg font-bold mt-5">Information Collected while Using the Application</div>
                      <div>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:</div>
                      <ul className="list-disc ml-5 mt-2">
                        <li>Information regarding your location</li>
                        <li>Pictures and other information from your Device's camera and photo library</li>
                      </ul>
                      <div>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.</div> <br /> <div>You can enable or disable access to this information at any time, through Your Device settings.</div>
                      <div className="text-2xl font-bold mb-5 mt-5">Use of Your Personal Data</div>
                      <div>The Company may use Personal Data for the following purposes:</div>
                      <ul className="list-disc ml-5 mt-2">
                        <li>
                          <span className="font-bold">To provide and maintain our Service:</span> including to monitor the usage of our Service.
                        </li>
                        <li>
                          <span className="font-bold">To manage Your Account:</span> to manage Your registration as a user of the Service.{' '}
                        </li>

                        <li>
                          <span className="font-bold">For the performance of a contract: </span>
                          the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
                        </li>

                        <li>
                          <span className="font-bold">To contact You:</span> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
                        </li>
                        <li>
                          <span className="font-bold">To provide You: </span> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about.{' '}
                        </li>

                        <li>
                          <span className="font-bold">To manage Your requests:</span> To attend and manage Your requests to Us.
                        </li>
                        <li>
                          <span className="font-bold">For business transfers:</span> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
                        </li>
                        <li>
                          <span className="font-bold">For other purposes: </span> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.
                        </li>
                      </ul>
                      <div>We may share Your personal information in the following situations:</div>
                      <ul className="list-disc ml-5 mt-2">
                        <li>
                          <span className="font-bold">With Service Providers: </span> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.
                        </li>
                        <li>
                          <span className="font-bold">For business transfers:</span> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
                        </li>

                        <li>
                          <span className="font-bold">With Affiliates: </span>We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
                        </li>

                        <li>
                          <span className="font-bold">With business partners:</span> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.
                        </li>
                        <li>
                          <span className="font-bold">With other users:</span> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about.{' '}
                        </li>

                        <li>
                          <span className="font-bold">With Your consent:</span> We may disclose Your personal information for any other purpose with Your consent.
                        </li>
                      </ul>
                      <div className="text-2xl font-bold mb-5 mt-5">Retention of Your Personal Data</div>
                      <div>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</div> <br /> <div>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</div>
                      <div className="text-2xl font-bold mb-5 mt-5">Transfer of Your Personal Data</div>
                      <div>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</div> <br /> <div>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</div> <br /> <div>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</div>
                      <div className="text-2xl font-bold mb-5 mt-5">Disclosure of Your Personal Data</div>
                      <div className="text-xl font-bold mb-5  mt-5">Business Transactions</div>
                      <div>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</div>
                      <div className="text-xl font-bold mb-5  mt-5">Law enforcement</div>
                      <div>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</div>
                      <div className="text-xl font-bold mb-5  mt-5">Other legal requirements</div>
                      <div>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</div>
                      <ul className="list-disc ml-5 mt-2">
                        <li>Comply with a legal obligation</li>
                        <li>Protect and defend the rights or property of the Company</li>
                        <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                        <li>Protect the personal safety of Users of the Service or the public</li>
                        <li>Protect against legal liability</li>
                      </ul>
                      <div className="text-xl font-bold mb-5  mt-5">Security of Your Personal Data</div>
                      <div>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</div>
                      <div className="text-xl font-bold mb-5  mt-5">Children's Privacy</div>
                      <div>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data</div>
                      <br /> <div>please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</div>
                      <br /> <div>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</div>
                      <div className="text-xl font-bold mb-5  mt-5">Links to Other Websites</div>
                      <div>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</div>
                      <br /> <div>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</div>
                      <div className="text-xl font-bold mb-5  mt-5">Changes to this Privacy Policy</div>
                      <div>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</div>
                      <br /> <div>We will let You know via email/app push notification and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</div>
                      <br /> <div>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</div>
                      <div className="text-2xl font-bold mb-5  mt-5">Contact Us</div>
                      <div>If you have any questions about this Privacy Policy, You can contact us:</div>
                      <ul className="list-disc ml-5 mt-2">
                        <li>By email: legal@zundermart.com</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <ZunderFooter />
      </div>
    </div>
  );
}

function TermsCondition({ showMap, setShowMap, statusVisible, setStatusVisible, content }) {
  const toggleStatus = () => {
    setStatusVisible(!statusVisible);
  };
  return (
    <div className=" bg-background-primary overflow-y-auto z-30 md:t-0 md:w-1/2 m-auto rounded-md shadow-2xl inset-0  md:inset-20 fixed p-4">
      <div className=" relative">
        <div className=" bg-background-primary sticky w-fit top-3  border rounded-md p-2">
          <FaTimes
            className=" text-content-text"
            onClick={() => {
              setShowMap(false);
              setStatusVisible(!statusVisible);
            }}
          />
        </div>

        {statusVisible && (
          <div>
            {process.env.REACT_APP_IS_WHITELISTED === 'true' && content ? (
              <div className="  text-header-text border p-4 mt-2 rounded quil w-full">
                <div className=" mt-12 p-4 w-full ql-container ql-snow " style={{ border: '0px' }}>
                  <div className=" ql-editor" dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              </div>
            ) : (
              <div className="bg-background-primary text-header-text border p-4 mt-2 rounded">
                <h1 className="text-2xl font-bold text-header-text mb-5">Terms and Conditions!</h1>
                <div className="flex items-start">
                  <div className=" ">
                    <p className="text-sm text-start">
                      <div className="text-lg font-bold">Background</div>
                      <br />
                      A) We provide a platform to connect retailers with their end users.
                      <br />
                      B) At no time will a contract come into existence as between You and Us. Any contract formed will be exclusively between customers requesting products/services from businesses and businesses providing those products or services.
                      <br />
                      C) You must be over the age of 18 to use this App.
                      <br />
                      D) We do not take the responsibility of verifying your age as we do not collect this data of a user either.
                      <br />
                      E) We do not take the responsibility for the quality of the businesses service or products. The transaction is entirely between the buyer[user] and seller[retailer].
                      <br />
                      <br />
                      By downloading or using the app, these terms will automatically apply to you - you should make sure therefore that you read them carefully before using the app. You're not allowed to copy, or modify the app, any part of the app, or our trademarks in any way. You're not allowed to attempt to extract the source code of the app, and you also shouldn't try to translate the app into other languages, or make derivative versions. The app itself, and all the trade marks, copyright, database rights and other intellectual property rights related to it, still belong to Zunderdog LLP. Zunderdog LLP is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without making it very clear to you exactly what you're paying for. The ZunderMart app stores and processes personal data that you have provided to us, in order to provide our Service. It's your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone's security features and it could mean that the ZunderMart app won't work properly or at all. The app does use third party services that declare their own Terms and Conditions.
                      <br /> <br />
                      Link to Terms and Conditions of third party service providers used by the app
                      <ul className="list-disc ml-5 mt-2">
                        <li>FGoogle Play Services</li>
                        <li>Google Analytics for Firebase</li>
                        <li>Firebase Crashlytics</li>
                      </ul>
                      <div className=" bg-background-primary text-left mt-5">
                        <p className="mb-4">You should be aware that there are certain things that Zunderdog LLP will not take responsibility for. Certain functions of the app will require the app to have an active internet connection.</p>
                        <p className="mb-4">The connection can be Wi-Fi, or provided by your mobile network provider, but Zunderdog LLP cannot take responsibility for the app not working at full functionality if you don't have access to Wi-Fi, and you don't have any of your data allowance left.</p>
                        <p className="mb-4">If you're using the app outside of an area with Wi-Fi, you should remember that your terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third party charges.</p>
                        <p className="mb-4">In using the app, you're accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming.</p>
                        <p className="mb-4">If you are not the bill payer for the device on which you're using the app, please be aware that we assume that you have received permission from the bill payer for using the app.</p>
                        <p className="mb-4">Along the same lines, Zunderdog LLP cannot always take responsibility for the way you use the app i.e. You need to make sure that your device stays charged - if it runs out of battery and you can't turn it on to avail the Service, Zunderdog LLP cannot accept responsibility.</p>
                        <p>With respect to Zunderdog LLP's responsibility for your use of the app, when you're using the app, it's important to bear in mind that although we endeavor to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. Zunderdog LLP accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.</p>
                      </div>
                      <br />
                      <br />
                      At some point, we may wish to update the app. The app is currently available on Android - the requirements for system(and for any additional systems we decide to extend the availability of the app to) may change, and you'll need to download the updates if you want to keep using the app. Zunderdog LLP does not promise that it will always update the app so that it is relevant to you and/or works with the Android version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, <br />
                      <br />
                      We may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination,
                      <ul className="list-disc ml-5 mt-2">
                        <li>(a) the rights and licenses granted to you in these terms will end;</li>
                        <li> (b) you must stop using the app, and (if needed) delete it from your device.</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <ZunderFooter />
        {/* <button onClick={()=>{setStatusVisible(false)}} className='mt-2 bg-accent px-4 rounded-md py-1 text-sub-text'>Close</button> */}
      </div>
    </div>
  );
}

const SettingsPage = ({ showMap, setShowMap, activeMenu, setActiveMenu, user, setDbUser, dbUser, merchantLogo, whiteListedMerchant }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const share = async () => {
    try {
      await Share.share({
        title: 'ZunderMart',
        text: `Find exclusive offers on ZunderMart. Shop Now!`,
        url: `${process.env.REACT_APP_APPLINK}`,
        dialogTitle: 'Share with friends',
      });
    } catch (error) {
      const shareData = {
        title: 'ZunderMart',
        text: `Find exclusive offers on ZunderMart. Shop Now!`,
        url: `${process.env.REACT_APP_APPLINK}`,
      };
      try {
        await navigator.share(shareData);
      } catch (error) {}
    }
  };
  const router = useNavigate();
  const handleSignOut = () => {
    // Handle signout logic here

    auth
      .signOut()
      .then(() => {
        router('/', { replace: true });
      })
      .catch((e) => {
        alert(e.message);
      });
  };

  const handleShareClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Download ZunderMart Now!',
          url: window.location.href,
        })
        .then(() => {
          // console.log('Successfully shared.');
        })
        .catch((error) => {
          // console.error('Error sharing:', error);
        });
    } else {
      // console.log('Web Share API not supported.');
    }
  };
  const [showAbout, setShowAbout] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [edit, setEdit] = useState(false);

  const HandleLogout = () => {
    const logout = async () => {
      if (user) {
        await axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/api/auth/unsubscribe-from-topic`,
            {
              topic: `${user.attributes.phone.replace('+', '')}`,
              token: localStorage.getItem('fcmToken'),
            },
            { headers: { Authorization: '' } },
          )
          .then((resp) => {
            console.log('unregistered from previous topic');
          })
          .catch((error) => {
            console.log('error ', error.message);
          });
      }

      auth
        .signOut()
        .then(() => {
          localStorage.removeItem('token');
          window.location.replace('/login');
          setDbUser();
        })
        .catch((e) => {
          alert(e.message);
        });
    };
    return (
      <div className=" bg-gradient-to-b from-background-primary via-background-primary to-secondary-lighter h-full overflow-y-auto scrollbar-hide z-30 top-0 m-auto w-full  fixed">
        <div className="h-full w-full  p-4 flex flex-col justify-start">
          <div className="flex w-full gap-4 items-center">
            <FaTimes
              className=" text-content-text"
              size={18}
              onClick={() => {
                setShowMap(false);
                setShowLogoutConfirmation(false);
              }}
            />
            {/* <h1 className='text-lg text-alert font-bold'>Logout</h1> */}
          </div>
          
          <div className="p-4 h-full flex items-center flex-col justify-center gap-4">
          {/* <div className=' gap-5 p-10 shadow-md rounded-md' >
          <button
        type="button"
        onClick={() => {
          setShowMap(false);
          setShowLogoutConfirmation(false);
        }}
        className="text-sub-text hover:text-alert translate-x-36"
    >
          <FaTimes
              className=" text-content-text"
              size={18}
            
            />
    </button>
    <h1 className="text-lg text-content-text py-5">Are You Sure You Want To Logout?</h1>
    
    
    <button
        type="button"
        onClick={() => {
            logout();
        }}
        className="mt-2 px-4 py-2 bg-alert text-sub-text rounded-md shadow-md"
    >
        Yes Log Me Out!
    </button>
          </div> */}

   

          <div className="relative z-50 w-fit max-w-sm p-4 mx-auto bg-background-primary rounded-lg shadow-lg">
        <button className="absolute top-2 right-2 text-content-text hover:text-header-text  rounded-md font-semibold px-1 ">
          <FaTimes className="text-content-text cursor-pointer"   onClick={() => {
          setShowMap(false);
          setShowLogoutConfirmation(false);
        }} />
        </button>
        <h1 className="text-lg text-content-text p-5">Are You Sure You Want To Logout?</h1>
    
    
    <button
        type="button"
        onClick={() => {
            logout();
        }}
        className="mt-2 px-4 py-2 bg-alert text-sub-text rounded-md shadow-md"
    >
        Yes Log Me Out!
    </button>
      </div>

    <div className="fixed bottom-4">
        <ZunderFooter />
    </div>
</div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    ons.ready(() => {
      ons.enableDeviceBackButtonHandler();

      ons.setDefaultDeviceBackButtonListener((ev) => {
        if (showAbout === true || showPrivacy || showTerms || showLogoutConfirmation) {
          setShowMap(false);
          setShowPrivacy(false);
          setShowAbout(false);
          setShowTerms(false);
          setShowLogoutConfirmation(false);
        } else {
          window.location.replace('/home');
        }
      });
    });
  }, [showAbout, showPrivacy, showTerms, showLogoutConfirmation]);

  const openZunderdogSite = async () => {
    await Browser.open({ url: 'https://www.zunderdog.com/' });
  };

  return (
    <div className="bg-background-primary mb-16 md:mb-0 flex flex-col-reverse md:flex-row w-full gap-2">
      <HeaderBrowser activeMenu={activeMenu} setActiveMenu={setActiveMenu} user={user} merchantLogo={merchantLogo} />
      {showAbout && <About showMap={showMap} setShowMap={setShowMap} setStatusVisible={setShowAbout} statusVisible={showAbout} content={whiteListedMerchant?.attributes?.aboutUs} />}
      {showPrivacy && <PrivacyPolicy showMap={showMap} setShowMap={setShowMap} setStatusVisible={setShowPrivacy} statusVisible={showPrivacy} content={whiteListedMerchant?.attributes?.privacyPolicy} />}
      {showTerms && <TermsCondition showMap={showMap} setShowMap={setShowMap} setStatusVisible={setShowTerms} statusVisible={showTerms} content={whiteListedMerchant?.attributes?.termsAndConditions} />}
      {showLogoutConfirmation && <HandleLogout />}
      {/* <MobileOnlyView>
        <div className="bg-background-primary z-10 fixed top-0 text-header-text w-full py-3 px-8 flex justify-start items-center gap-8 shadow-sm">

<div className="flex items-center gap-4">
  
  <span className="text-lg font-extrabold"><span className="text-header-text">Zunder</span><span className="text-header-text">Mart</span></span>
</div>
 
</div>

        </MobileOnlyView> */}
      <div className="md:p-6 flex flex-col-reverse md:flex-row w-full gap-2 ">
        <div className="md:w-1/4 md:mt-16 w-full md:h-screen text-start bg-background-primary md:border-r p-4">
          {/* User Account Information */}
          {/* <div className="flex items-center mb-6 mt-6">
         <FaUser className="text-content-text text-xl mr-4" />
         <div>
           <h2 className="text-2xl font-bold">{user.attributes.name}</h2>
           <p className="text-content-text">{user.attributes.phone}</p>
         </div>
       </div> */}
          {/* <p className="text-lg font-semibold text-primary">More</p> */}
          {/* Settings Options */}
          <div className=" mt-6">
            {/* <div onClick={()=>{
           setShowMap(true)
          setShowAbout(true)
          setShowPrivacy(false)
          setShowTerms(false)
          setEdit(false)
          }} className="flex  items-center cursor-pointer ">
           <FaInfoCircle className="text-content-text text-md mr-4" />
           <span className="text-md font-medium">About</span>
         </div> */}

            <div className="mt-2 bg-primary-lighter rounded-lg p-4 mb-4">
              <div className="flex items-center justify-between mb-1">
                <h2 className="text-lg font-bold text-header-text">About</h2>
                <button
                  onClick={() => {
                    setShowMap(true);
                    setShowAbout(true);
                    setShowPrivacy(false);
                    setShowTerms(false);
                    setEdit(false);
                  }}
                  className="flex items-center bg-primary hover:bg-primary/75 text-sub-text font-semibold py-2 px-4 rounded-full"
                >
                  <AiFillInfoCircle className="text-md mr-2" /> Read
                </button>
              </div>
            </div>

            {/* <div onClick={()=>{
           setShowMap(true)
          setShowPrivacy(true)
          setShowAbout(false)
          setShowTerms(false)
          setEdit(false)}} className="flex  items-center mt-8 cursor-pointer ">
           <FaShieldAlt className="text-content-text text-md mr-4" />
           <span className="text-md font-medium">Privacy Policy</span>
         </div> */}

            <div className="mt-2 bg-primary-lighter rounded-lg p-4 mb-4">
              <div className="flex items-center justify-between mb-1">
                <h2 className="text-lg font-bold text-header-text">Privacy Policy</h2>
                <button
                  onClick={() => {
                    setShowMap(true);
                    setShowPrivacy(true);
                    setShowAbout(false);
                    setShowTerms(false);
                    setEdit(false);
                  }}
                  className="flex items-center bg-primary hover:bg-primary/75 text-sub-text font-semibold py-2 px-4 rounded-full"
                >
                  <MdPrivacyTip className="text-md mr-2" /> Read
                </button>
              </div>
            </div>

            {/* Terms & Conditions */}

            <div className="mt-2 bg-primary-lighter rounded-lg p-4 mb-4">
              <div className="flex items-center justify-between mb-1">
                <h2 className="text-lg font-bold text-header-text">Terms & Conditions</h2>
                <button
                  onClick={() => {
                    setShowMap(true);
                    setShowTerms(true);
                    setShowAbout(false);
                    setShowPrivacy(false);
                    setEdit(false);
                  }}
                  className="flex items-center bg-primary hover:bg-primary/75 text-sub-text font-semibold py-2 px-4 rounded-full"
                >
                  <MdPolicy className="text-md mr-2" /> Read
                </button>
              </div>
            </div>
            {/* Terms & Conditions old */}
            {/* <div onClick={()=>{
          setShowMap(true)
          setShowTerms(true)
          setShowAbout(false)
          setShowPrivacy(false)
          setEdit(false)}} className="flex items-center mt-8 cursor-pointer ">
           <FaPaperclip className="text-content-text text-md mr-4" />
           <span className="text-md font-medium">Terms & Conditions</span>
         </div> */}

            {/* <div className="flex p-2 items-center mt-6 cursor-pointer hover:bg-background-secondary">
           <FaCommentAlt className="text-content-text text-lg mr-4" />
           <span className="text-lg font-medium">Feedback</span>
         </div> */}

            {/* My COde Customer support */}

            <div className="mt-2 bg-primary-lighter rounded-lg p-4 mb-4">
              <div className="flex items-center justify-between mb-1">
                <h2 className="text-lg font-bold text-header-text">Customer Support</h2>
                <button
                  onClick={() => {
                    window.location.href = `tel:${user.attributes.phone}`;
                  }}
                  className="flex items-center bg-primary hover:bg-primary/75 text-sub-text font-semibold py-2 px-4 rounded-full"
                >
                  <IoCall className="text-md mr-2" /> Call
                </button>
              </div>
              <p className="pt-2 text-header-text">For any Queries or Help</p>
              <div className="flex flex-wrap items-center py-2">
                <p className="text-header-text">Mail us at:</p>
                <a href="mailto:sales@zunderdog.com" className={`text-accent ml-1 hover:underline ${process.env.REACT_APP_IS_WHITELISTED === 'true' ? 'text-sub-text' : `text-accent`}`}>
                  {process.env.REACT_APP_IS_WHITELISTED === 'true' && whiteListedMerchant?.attributes?.merchant?.data?.attributes?.email ? whiteListedMerchant?.attributes?.merchant?.data?.attributes?.email : 'support@zundermart.com'}
                </a>
              </div>
            </div>

            {/* <div onClick={()=>{
           const phoneNumber = '+919940285902'; // Replace with the actual phone number

           window.open(`tel:${phoneNumber}`);
         }} className="flex  items-center mt-6 cursor-pointer ">
           <FaPhone className="text-content-text text-md mr-4" />
           <span className="text-md font-medium">Customer Support</span>
         </div> */}
            <div className="mt-2 bg-primary-lighter rounded-lg p-4 mb-4">
              <div className="flex items-center justify-between mb-1">
                <h2 className="text-lg font-bold text-header-text">Invite</h2>
                <button
                  onClick={() => {
                    share();
                  }}
                  className="flex items-center bg-primary hover:bg-primary/75 text-sub-text font-semibold py-2 px-4 rounded-full"
                >
                  <BsShareFill className="text-md mr-2" /> Share
                </button>
              </div>
            </div>

            {/* invite */}
            {/* <div onClick={()=>{share()}} className="flex  items-center mt-6 cursor-pointer ">
           <FaEnvelope className="text-content-text text-md mr-4" />
           <span className="text-md font-medium">Invite</span>
         </div> */}
          </div>

          {/* Signout Button */}
          {/* <button
  onClick={() => {
    setShowMap(true);
    setShowLogoutConfirmation(true);
  }}
  className="text-primary text-md flex items-center py-2 rounded-md mt-6 hover:text-primary w-32 bg-primary-lighter hover:bg-primary/75-lighter"
>
  <FaSignOutAlt className="text-md mr-2" />
  Log Out
</button> */}

          {user && (
            <div
              onClick={() => {
                setShowMap(true);
                setShowLogoutConfirmation(true);
              }}
              className="mt-2 bg-primary rounded-lg p-2 mb-4 flex flex-col justify-center text-center"
            >
              <h2 className="text-lg font-bold text-sub-text">Log Out</h2>
            </div>
          )}

          <div>
            <div>
              <p className="text-sm text-content-text text-center md:text-left">Copyright © 2024 ZunderMart. All rights reserved.</p>
              <div
                className="flex justify-center items-center cursor-pointer py-3"
                onClick={() => {
                  openZunderdogSite();
                }}
              >
                <span className="font-bold text-header-text text-sm">{`</> `}</span>
                <span className="font-bold text-primary text-sm">ZunderDog</span>
              </div>
            </div>
          </div>
        </div>

        {user && (
          <div className="md:w-3/4 md:mt-16 w-full border-b-0 border-sub-text  md:border-none">
            <ProfilePage edit={edit} setEdit={setEdit} showMap={showMap} setShowMap={setShowMap} user={user} />
            {user.attributes.customer_points &&
              user.attributes.customer_points.data &&
              user.attributes.customer_points.data.length > 0 &&
              user.attributes.customer_points.data.filter((cp) => {
                if (process.env.REACT_APP_IS_WHITELISTED === 'true') {
                  return cp.attributes.points > 0 && cp.attributes.merchant?.data?.id === whiteListedMerchant?.attributes?.merchant?.data?.id;
                } else {
                  return cp.attributes.points > 0;
                }
              }).length > 0 && (
                <div className="md:w-3/4 md:mt-16 w-full border-b-2 border-sub-text p-1  md:border-none">
                  <div className="p-2  flex items-start flex-col w-full">
                    <div className="flex justify-between items-center w-full">
                      <span className="text-sm font-semibold text-header-text">{process.env.REACT_APP_IS_WHITELISTED === 'true' ? 'Loyalty Points' : 'Points Across Stores'}</span>
                      <span
                        onClick={() => {
                          navigate('/loyalty-points', {
                            state: { user: user },
                          });
                        }}
                        className="text-sm font-semibold text-primary underline cursor-pointer"
                      >
                        View All
                      </span>
                    </div>
                    <div className="mt-2 grid grid-cols-2  gap-2 w-full">
                      {user.attributes.customer_points.data
                        .slice(0, 2)
                        .filter((cp) => {
                          if (process.env.REACT_APP_IS_WHITELISTED === 'true') {
                            return cp.attributes.points > 0 && cp.attributes.merchant?.data?.id === whiteListedMerchant?.attributes?.merchant?.data?.id;
                          } else {
                            return cp.attributes.points > 0;
                          }
                        })
                        .map((cp, idx) => {
                          return (
                            <div
                              onClick={() => {
                                navigate('/store-details', {
                                  state: {
                                    merchant: {
                                      id: cp.attributes.merchant.data.id,
                                      ...cp.attributes.merchant.data.attributes,
                                    },
                                    user: user,
                                  },
                                });
                              }}
                              className={`${idx === user.attributes.customer_points.data.length - 1 && idx % 2 === 0 ? 'col-span-2' : 'col-span-1'} bg-gradient-to-b from-background-primary via-background-primary to-secondary-lighter border-dashed border-secondary rounded-md p-2 flex flex-col justify-center items-center shadow-md border`}
                              key={cp.id}
                            >
                              <div className="flex w-full items-center justify-start gap-2">
                                <FaStore size={20} className="text-primary" />

                                <h3 className="text-sm text-start line-clamp-2 font-semibold text-header-text">{cp.attributes.merchant.data.attributes.name}</h3>
                              </div>
                              <div className="text-start mt-2  text-xs w-full font-normal text-content-text  flex items-center">
                                1 point is worth of&nbsp; <FaRupeeSign /> <span className="font-bold text-xs">{cp.attributes.loyalty.data.attributes.pointWorth}</span>{' '}
                              </div>
                              <h3 className="text-start  mt-2 text-xl w-full font-bold text-primary">{cp.attributes.points}</h3>
                              <div className="text-start mt-2  text-xs w-full font-normal text-content-text  flex items-center">
                                You can redeem max&nbsp; <FaRupeeSign /> <span className="font-bold text-xs">{cp.attributes.points * cp.attributes.loyalty.data.attributes.pointWorth}</span>{' '}
                              </div>
                              <h3 className="text-start  mt-2 text-xs w-full font-semibold text-content-text underline">Redeem</h3>
                              {/* {cp.attributes.loyalty.data.attributes.expiry && <div className='mt-2 border-t-2 border-dashed border-secondary  w-full'>
            <span className='text-xs p-2 font-normal text-content-text'>
            Expires on { moment(cp.attributes.loyalty.data.attributes.expiry).format("DD MMM YYYY") }
            </span>
            </div>} */}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsPage;
