import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import HeaderMobie from '../../components/HeaderMobile';
import HeaderBrowser from '../../components/HeaderBrowser';
import MerchantCard from '../../components/home/MerchantCard';
import { useGetMerchantCategoriesQuery, useGetMerchantsQuery, useDeleteCartMutation } from '../../state/api';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Map from '../../components/home/Map';
import { Preferences } from '@capacitor/preferences';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import { FaFilter, FaSearch } from 'react-icons/fa';
import axios from 'axios';
import ons from 'onsenui';
import { HiMapPin } from 'react-icons/hi2';
import { App as CapApp } from '@capacitor/app';
import FilterModal from '../../components/home/FilterModal';
import ShimmerMerchantCards from '../../components/home/ShimmerMerchantCards';

import { FaTrashAlt, FaTimes } from 'react-icons/fa';
import WhitelistedMerchant from '../../components/home/WhitelistedMerchant';

export default function Home({ showMap, setShowMap, isMerchantInvited, user, activeMenu, setActiveMenu, scrollDown, merchantLogo, merchantBanner, whiteListedMerchant }) {
  const navigate = useNavigate();
  const [searchDisabled, setSearchDisabled] = useState(true);
  const [search, setSearch] = useState('');
  const [shouldSkip, setShouldSkip] = useState(false);
  const [userLocation, setUserLocation] = useState();
  const [currentCat, setCurrentCat] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [userContext] = useOutletContext();
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [activeFilter, setActiveFilter] = useState('Deliver');
  const [filteredBy, setFilteredBy] = useState('');
  // latitude: userLocation && userLocation.latitude, longitude: userLocation && userLocation.longitude
  const { data, error, refetch, isLoading } = useGetMerchantsQuery(
    {
      page: currentPage,
      token: localStorage.getItem('token'),
      latitude: userLocation && userLocation.latitude,
      longitude: userLocation && userLocation.longitude,
      category: currentCat === 'All' ? '' : currentCat,
      searchName: searchQuery.trim(),
      acceptingOrders: filteredBy === 'accepting' ? true : filteredBy === 'not accepting' ? false : null,
      deliveryRadius: filteredBy === 'delivery' ? 1 : null,
      nearByDelivery: filteredBy === 'nearByDelivery' ? true : null,
    },
    { skip: !shouldSkip },
  );
  const [merchants, setMerchants] = useState([]);
  const [userAddress, setUserAddress] = useState('');

  const [cartCard, setShowCartCard] = useState(false);
  const [cart, setCart] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [tempSearchQuery, setTempSearchQuery] = useState('');
  const [mapError, setMapError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [deleteCart] = useDeleteCartMutation();

  // code for the delete cart Modal
  const [isAlertOpen, setAlertOpen] = useState(false);
  const handleShowAlert = () => {
    setAlertOpen(true);
  };

  //search bar
  useEffect(() => {
    if (activeMenu === 'home' && tempSearchQuery) {
      if (tempSearchQuery.trim().length === 0 && searchQuery.length > 0) {
        setSearchQuery('');
        setTempSearchQuery('');
        handleMerchantRefetch();
        setSearch('');
        setSearchDisabled(true);
      }
      if (search === tempSearchQuery.trim() && searchDisabled === false) {
        setSearchDisabled(true);
      }
      if (tempSearchQuery.trim().length > 0 && search.trim() !== tempSearchQuery.trim() && searchDisabled === true) {
        setSearchDisabled(false);
      }
    }
  }, [tempSearchQuery]);

  useEffect(() => {
    Preferences.get({ key: 'userLocation' })
      .then((u) => {
        var loc = JSON.parse(u.value);

        setUserLocation({ latitude: loc.latitude, longitude: loc.longitude });
      })
      .catch((e) => {
        setUserLocation({
          latitude: Number.parseFloat(process.env.REACT_APP_DEFAULT_LAT),
          longitude: Number.parseFloat(process.env.REACT_APP_DEFAULT_LNG),
        });
        setMapError(true);
        setShowMap(true);
      });
  }, []);

  const handleSearch = (searchString) => {
    const query = searchString;
    setSearchQuery(query);
  };

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
  Geocode.setLanguage('en');
  useEffect(() => {
    if (userLocation) {
      Geocode.fromLatLng(userLocation.latitude, userLocation.longitude)
        .then((response) => {
          const address = response.results[0].formatted_address;
          setUserAddress(address);
        })
        .catch((error) => {
          // console.log(error)
        });
    }
  }, [userLocation]);

  useEffect(() => {
    if (data) {
      // let tempMerchant = [...merchants, ...data.data]
      // tempMerchant.sort((a, b) => a.distance - b.distance)
      setMerchants((merchant) => [...merchant, ...data.data]);
      setHasMore(false);
      setTotalPageCount(data.meta.pagination.pageCount);
      if (currentPage <= data.meta.pagination.pageCount) {
        refetchMerchant();
      }
    }
  }, [data]);
  const refetchMerchant = () => {
    setHasMore(true);
    setCurrentPage(currentPage + 1);
  };
  const handleScroll = () => {
    if (Math.ceil(window.innerHeight + document.documentElement.scrollTop) < document.documentElement.offsetHeight || hasMore) {
      // console.log(window.innerHeight+document.documentElement.scrollTop)
      // console.log(document.documentElement.offsetHeight)
      return;
    }
    if (currentPage <= totalPageCount) {
      refetchMerchant();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore]);

  useEffect(() => {
    if (userLocation !== null) {
      setShouldSkip(true);
    }
  }, [userLocation]);

  const [totalItems, setTotalItems] = useState(0);
  const categories = useGetMerchantCategoriesQuery({
    token: localStorage.getItem('token'),
  });

  const handleSetLocation = () => {
    Preferences.set({
      key: 'userLocation',
      value: JSON.stringify({
        latitude: userLocation.latitude,
        longitude: userLocation.longitude,
      }),
    }).then(() => {
      setUserLocation({
        latitude: userLocation.latitude,
        longitude: userLocation.longitude,
      });

      if (process.env.REACT_APP_IS_WHITELISTED !== 'true') {
        setCurrentPage(1);
        geocodeAddress(userLocation.latitude, userLocation.longitude);
        refetch().then((d) => {
          setMerchants(d.data.data);

          setTotalPageCount(d.data.meta.pagination.pageCount);
        });
      }
    });
  };

  const geocodeAddress = (latitude, longitude) => {
    Geocode.fromLatLng(latitude, longitude)
      .then((response) => {
        const address = response.results[0].formatted_address;
        setUserAddress(address);
      })
      .catch((error) => {
        // console.log(error)
      });
  };
  useEffect(() => {
    if (user) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/carts?filters[$and][0][customer][id][$eq]=${user.id}&populate[merchant][populate]=*`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((cd) => {
          setShowCartCard(true);
          setCart(cd.data.data[0]);
          let tempTotal = 0;
          for (let index = 0; index < cd.data.data[0].attributes.products.length; index++) {
            const element = cd.data.data[0].attributes.products[index];
            tempTotal += element.originalQuantity;
          }
          setTotalItems(tempTotal);
        })
        .catch((e) => {
          setCart();
        });
    }
  }, [user]);

  const generateKey = () => {
    const min = 1;
    const max = 100;

    // Calculate the random number within the range
    const randomInt = Math.floor(Math.random() * (max - min + 1)) + min;

    return randomInt;
  };
  const onScroll = (event) => {
    var element = event.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      //
    }
  };
  useEffect(() => {
    ons.ready(() => {
      ons.enableDeviceBackButtonHandler();
      ons.setDefaultDeviceBackButtonListener((ev) => {
        if (showMap === true) {
          setShowMap(false);
        } else {
          CapApp.exitApp();
        }
      });
    });
  }, [showMap]);
  const handleMerchantRefetch = () => {
    setCurrentPage(1);
    setHasMore(true);
    setMerchants([]);
  };

  useEffect(() => {
    if (userContext.merchant) {
      // console.log(userContext.merchant)
      if (userContext.merchant.accepted === true && !userContext.merchant.shopDisabled) {
        navigate('/store-details', {
          state: { merchant: userContext.merchant, user: user },
        });
      }
    }
  }, [userContext.merchant]);

  const handleClearCart = () => {
    setAlertOpen(false);
    deleteCart({ cart: cart, token: localStorage.getItem('token') })
      .unwrap()
      .then((c) => {
        setShowCartCard(false);
        setCart();
      })
      .catch((e) => {
        alert('Something went wrong', 'Please try again');
      });
  };

  return (
    <div onScroll={onScroll} className="mb-16 h-full ">
      {isFilterApplied && !showMap && <FilterModal activeFilter={activeFilter} setActiveFilter={setActiveFilter} isFilterApplied={isFilterApplied} setIsFilterApplied={setIsFilterApplied} filteredBy={filteredBy} setFilteredBy={setFilteredBy} handleMerchantRefetch={handleMerchantRefetch} />}
      {!showMap && <HeaderBrowser handleMerchantRefetch={handleMerchantRefetch} setTempSearchQuery={setTempSearchQuery} tempSearchQuery={tempSearchQuery} setActiveMenu={setActiveMenu} activeMenu={activeMenu} user={user} showMap={showMap} setShowMap={setShowMap} handleSearch={handleSearch} address={userAddress} searchQuery={searchQuery} setSearchQuery={setSearchQuery} merchantLogo={merchantLogo} />}
      {/* <Topbar showMap={showMap} setShowMap={setShowMap} user={user}  handleSearch={handleSearch} address={userAddress}/> */}

      {process.env.REACT_APP_IS_WHITELISTED !== 'true' && (
        <div className="flex flex-col justify-center  mb-24 md:mt-8 md:mr-28 md:ml-28">
          {showMap && <Map mapError={mapError} uniqueKey={generateKey()} handleSetLocation={handleSetLocation} showMap={showMap} setShowMap={setShowMap} userLocation={userLocation} setUserLocation={setUserLocation} address={userAddress} />}
          {/*Product diplay bottom*/}
          {data && data.data && merchants.length > 0 && cartCard && cart && !showMap && (
            <div className={`flex z-40 justify-between m-auto right-0 left-0 fixed md:bottom-0 bottom-14 rounded-t-xl md:w-2/4 p-2 bg-background-secondary w-ful cursor-pointer scroll-smooth transition-all ${scrollDown ? 'translate-y-60' : 'translate-y-0'}`}>
              <div className="flex w-full gap-2 items-center p-1">
                <div className="h-[50px] w-[100px] flex items-center rounded-md">
                  <img className="h-[50px] w-[100px] rounded-md object-cover" src={cart.attributes.merchant.data.attributes.image && cart.attributes.merchant.data.attributes.image.replace('/upload/', '/upload/w_100/')} />
                </div>
                <div className="flex flex-col text-start items-start">
                  <span className="text-sm font-bold line-clamp-1 text-header-text">{cart.attributes.merchant.data.attributes.name}</span>
                  <span className="text-xs font-regular text-content-text">{totalItems} Items</span>
                </div>
              </div>
              <div
                className="bg-secondary m-2 w-1/2 p-1  rounded-md text-sub-text"
                onClick={() => {
                  navigate('/store-details', {
                    state: {
                      merchant: {
                        id: cart.attributes.merchant.data.id,
                        ...cart.attributes.merchant.data.attributes,
                      },
                      user: user,
                    },
                  });
                }}
              >
                <span className="text-sm font-normal text-sub-text">₹{cart.attributes.total.toFixed(2)}</span>
                <p className="text-xs font-bold">View Detail</p>
              </div>
              {/*the delete cart on home page*/}
              <div className="bg-primary-lighter m-2 w-2/2  rounded-lg text-sub-text text-center flex items-center justify-center border" onClick={handleShowAlert}>
                <FaTrashAlt className="m-2 text-2xl text-primary   " />
              </div>
            </div>
          )}
          {/*Modal*/}
          {isAlertOpen && (
            <div className="fixed z-50 p-2 bg-background-secondary bg-opacity-30 w-full h-full inset-0 flex items-center justify-center">
              <div className="bg-background-primary max-w-md p-6 rounded-2xl shadow-lg relative">
                <div className="flex justify-between">
                  <h2 className="text-xl font-semibold text-primary"></h2>
                  <FaTimes className="text-content-text cursor-pointer" onClick={() => setAlertOpen(false)} />
                </div>
                <div className="text-center">
                  <h2 className="text-xl font-semibold mb-2  text-primary"> Delete cart?</h2>
                  {/* <p className="text-header-text mb-4">
            By clicking on 'Yes'  your order will be cancelled. 
            </p> */}

                  <span className="block sm:inline mb-"> Are you sure you want to delete this cart?</span>
                  <br />
                  <div className="flex justify-between ">
                    {!loading && (
                      <button
                        onClick={() => {
                          setAlertOpen(false);
                        }}
                        className="bg-primary-lighter hover:bg-primary-lighter/75  text-primary font-semibold py-2 px-4 rounded-md w-32"
                      >
                        No
                      </button>
                    )}
                    {loading && (
                      <div className="p-4 flex flex-col items-center justify-center mt-1 gap-4">
                        {' '}
                        <div role="status" className="flex items-center justify-center h-8 w-8">
                          <svg className="w-24 h-24 mr-2 text-sub-text animate-spin dark:text-content-text fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                          </svg>
                          {/* <span className="sr-only">Loading...</span> */}
                        </div>
                      </div>
                    )}
                    <button
                      onClick={() => {
                        handleClearCart();
                      }}
                      className="bg-primary hover:bg-primary/75 text-sub-text font-semibold py-2 px-4 rounded-md w-32"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <BrowserView className="text-start md:mt-16">
            <span className="text-xl p-3 text-start w-full font-semibold text-header-text">Discover ZunderMart Partners Around Your Vicinity</span>
          </BrowserView>

          <MobileOnlyView className="bg-background-primary">
            <div className="w-full p-1 z-30 bg-background-primary fixed top-0" />
            {!showMap && (
              <div className="flex items-start md:p-4 z-30 w-full top-0 sticky shadow-sm p-3 bg-background-primary">
                <MobileOnlyView className="w-full">
                  {userAddress.trim().length > 0 && (
                    <div
                      onClick={() => {
                        setShowMap(true);
                      }}
                      className="flex top-0 w-full items-top "
                    >
                      <HiMapPin size={32} className="text-xl fill-primary" />
                      <div className="w-full justify-center flex-col flex items-start">
                        <h3 className="ml-1 font-bold items-start text-left text-header-text text-md">
                          {userAddress.split(' ')[0]}
                          <br />
                          <h3 className=" line-clamp-1 font-normal text-content-text text-md">
                            {userAddress}
                            {''}
                          </h3>
                        </h3>
                        {/* <h3 className=" line-clamp-1 font-normal text-content-text text-md">
         {address}{""}
         </h3> */}
                      </div>
                    </div>
                  )}
                </MobileOnlyView>
              </div>
            )}
            {/* <div className="flex text-2xl text-primary gap-2 p-3 items-center">
              <FaHandshake/>
              <span className="text-sm  text-start w-full font-semibold text-header-text">
              Discover Partners Around You
              </span>
            </div> */}
          </MobileOnlyView>

          {!showMap && (
            <div className="md:relative sticky z-30 top-1 bg-background-primary ">
              <MobileOnlyView className="top-0 bg-background-primary">
                <div className="z-30 top-0 bg-background-primary p-2 w-full flex flex-col items-center justify-center">
                  <div className="w-full gap-2 bg-background-primary shadow-sm px-4 py-2 rounded-lg border flex items-center">
                    <FaSearch />
                    <input
                      type="text"
                      placeholder="Search stores near you"
                      className="focus:outline-none w-3/4 bg-background-primary"
                      value={tempSearchQuery}
                      onChange={(e) => {
                        setTempSearchQuery(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !searchDisabled) {
                          handleSearch(tempSearchQuery);
                          handleMerchantRefetch();
                          setSearch(tempSearchQuery);
                          setSearchDisabled(true);
                        }
                      }}
                    />
                    <button
                      className={` underline font-semibold ${searchDisabled ? ' text-primary-lighter' : 'text-primary cursor-pointer'}`}
                      disabled={searchDisabled}
                      onClick={() => {
                        if (!searchDisabled) {
                          handleSearch(tempSearchQuery);
                          handleMerchantRefetch();
                          setSearch(tempSearchQuery);
                          setSearchDisabled(true);
                        }
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </MobileOnlyView>
              <div className="flex gap-2  p-2 items-center">
                <div
                  onClick={() => {
                    setIsFilterApplied(!isFilterApplied);
                  }}
                  className={`whitespace-nowrap py-2 inline-block text-center cursor-pointer text-sm shadow-md  ${isFilterApplied || filteredBy.trim().length > 0 ? 'text-sub-text bg-primary border-primary' : 'text-content-text border-sub-text'} font-semibold px-6 rounded-full border`}
                >
                  <p className="flex items-center gap-2">
                    Filter <FaFilter />
                  </p>
                </div>

                <div className="overflow-x-auto mr-2 scrollbar-hide">
                  <div className="flex items-center p-2 w-full gap-2 md:justify-start">
                    <div
                      onClick={() => {
                        if (currentCat !== 'All') {
                          setCurrentCat('All');
                          handleMerchantRefetch();
                        }
                      }}
                      className={`whitespace-nowrap py-1.5 border  inline-block text-center cursor-pointer shadow-md  text-sm ${currentCat === 'All' ? 'text-sub-text bg-primary border-primary' : 'text-content-text border-sub-text'} font-semibold px-6 rounded-full`}
                    >
                      <p>All</p>
                    </div>
                    {categories.data &&
                      categories.data.data.map((c, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => {
                              if (currentCat !== c.attributes.name) {
                                setCurrentCat(c.attributes.name);
                                handleMerchantRefetch();
                              }
                            }}
                            className={`whitespace-nowrap py-1.5 inline-block text-center cursor-pointer text-sm shadow-md capitalize ${currentCat === c.attributes.name ? 'text-sub-text bg-primary border-primary' : 'text-content-text border-sub-text'} font-semibold px-6 rounded-full border`}
                          >
                            <p key={c.attributes.name}>{c.attributes.name}</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
          {data && (
            <div className="">
              {!hasMore && !isLoading && data && data.data.length <= 0 && merchants.length <= 0 && (
                <div className="p-4 flex flex-col w-full items-center justify-center mt-16 gap-4">
                  <img src="/not-found.png"></img>
                  <span>Oops. No Store Found!</span>
                </div>
              )}
              <div className={`mb-24 p-2 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 items-center justify-center w-full gap-6  `}>
                {/* {
               data && searchQuery.trim().length > 0 &&  merchants.filter((m)=>m.name.toLowerCase().includes(searchQuery.toLowerCase())).filter((m)=> currentCat === "All" ? true : m.categories.includes(currentCat)).map((m,i)=>{
                return <MerchantCard user={user} merchant={m} key={i}/>
            })
            } */}
                {data &&
                  merchants.map((m, i) => {
                    return <MerchantCard user={user} merchant={m} key={i} />;
                  })}
              </div>
            </div>
          )}
          {(isLoading || hasMore || !data) && <ShimmerMerchantCards />}
        </div>
      )}
      {process.env.REACT_APP_IS_WHITELISTED === 'true' && (
        <div className="flex flex-col justify-center  mb-24 md:mt-20 md:mr-28 md:ml-28">
          {showMap && <Map mapError={mapError} uniqueKey={generateKey()} handleSetLocation={handleSetLocation} showMap={showMap} setShowMap={setShowMap} userLocation={userLocation} setUserLocation={setUserLocation} address={userAddress} />}
          <span className="text-xl p-3 text-start w-full font-semibold text-header-text">
            {/* Welcome to {process.env.REACT_APP_WHITELISTED_NAME} */}
            <div className={`mb-24 w-full `}>
              {/* {
 data && searchQuery.trim().length > 0 &&  merchants.filter((m)=>m.name.toLowerCase().includes(searchQuery.toLowerCase())).filter((m)=> currentCat === "All" ? true : m.categories.includes(currentCat)).map((m,i)=>{
  return <MerchantCard user={user} merchant={m} key={i}/>
})
} */}
              {data &&
                merchants.map((m, i) => {
                  return <WhitelistedMerchant user={user} merchant={m} key={i} showMap={showMap} setShowMap={setShowMap} address={userAddress} merchantLogo={merchantLogo} merchantBanner={merchantBanner} />;
                })}
            </div>
          </span>
        </div>
      )}
    </div>
  );
}
