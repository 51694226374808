import { useLocation, useNavigate } from 'react-router-dom';
import ItemCard from '../../components/items/ItemCard';
import HeaderMobie from '../../components/HeaderMobile';
import { BrowserView } from 'react-device-detect';
import HomeFloatingButton from '../../components/items/HomeFloatingButton';
import { use, useEffect, useState } from 'react';
import { HiMapPin, HiTruck } from 'react-icons/hi2';
import { IoMdArrowBack, IoMdCall, IoMdClock, IoMdLocate, IoMdPin, IoMdShare, IoMdTime } from 'react-icons/io';
import ons from 'onsenui';
import { useCreateCartMutation, useDeleteCartMutation, useGetCartQuery, useGetMerchantItemsQuery, useGetSubscriptionItemsQuery, useUpdateCartMutation } from '../../state/api';

import CartBanner from '../../components/items/CartBanner';
export default function ItemDetail({ dbUser }) {
  const location = useLocation();
  const navigate = useNavigate();
  const order = JSON.parse(localStorage.getItem('order'));

  const item = location?.state?.item;
  const itemCart = location?.state?.cart;
  const merchant = location?.state?.merchant;
  const user = location?.state?.user;
  const isOtherMerchant = location?.state?.isOtherMerchant;
  const otherCartId = location?.state?.otherCartId;
  const dbCart = location?.state?.dbCart;
  const [cart, setCart] = useState(itemCart);
  const [cartDeleted, setCartDeleted] = useState(false);
  const [userSubs, setUserSubs] = useState([]);
  if (!merchant) {
    window.location.replace('/');
  }
  const [userSubMerchant, setUserSubMerchant] = useState();
  const [customerItemSub, setCustomerItemSub] = useState();
  const [createCart] = useCreateCartMutation();
  const [updateCart] = useUpdateCartMutation();
  const [deleteCart] = useDeleteCartMutation();
  const [reloadData, setReloadData] = useState(false);
  useEffect(() => {
    if (order && order.products && order.products.length > 0 && order.phoneMerchant !== merchant?.phone) {
    } else {
      let order = {
        merchant: merchant?.id,
        merchantName: merchant?.name,
        merchantImage: merchant?.image,
        phoneMerchant: merchant?.phone,
        phoneClient: user?.attributes.phone,
        products: cart,
        name: user?.attributes.name,
        status: 'Pending',
      };
      localStorage.setItem('order', JSON.stringify(order));
    }
    //show dialog if already items in cart
  }, [cart]);

  useEffect(() => {
    ons.ready(() => {
      ons.enableDeviceBackButtonHandler();
      ons.setDefaultDeviceBackButtonListener((ev) => {
        handleCartApi(true);
      });
    });
    // BackH.addListener('backButton', ({canGoBack}) => {
    //   if(!canGoBack){
    //     alert("Cannt got")
    //     window.location.reload()
    //     window.history.back();
    //   } else {
    //     window.history.back();
    //   }
    // });
  }, [cart]);

  const handleSetCart = async (item, quantity, unit) => {
    if (!dbUser) {
      navigate('/login', {
        state: { backNav: '/store-details', merchant: merchant },
      });
    }

    let itemTemp = item.attributes;
    itemTemp = {
      ...itemTemp,
      quantity: quantity,
      originalQuantity: quantity,
      id: item.id,
      unit: unit,
    };

    const index = cart.findIndex((i) => i.id == item.id);
    if (quantity === 0) {
      if (index !== -1) {
        setCart((prevItems) => prevItems.filter((cart) => cart.id != item.id));
        //   if (cart.length === 1) {

        //    if (dbCart) {
        //     deleteCart({cart:  dbCart,token:localStorage.getItem("token")})
        //     .unwrap().then((c)=>{

        //      setCartDeleted(true)

        //     }).catch((e)=>{
        //       setCartDeleted(true)
        //     })

        //    }
        // }
      }
    } else {
      if (index === -1) {
        // Item does not exist in the array, so add it
        // setCartDeleted(false)
        setCart((prevItems) => prevItems.concat(itemTemp));
      } else {
        // Item exists in the array, so update it
        setCart((prevItems) =>
          prevItems.map((i) => {
            if (i.id == item.id) {
              return {
                ...i,
                quantity: quantity,
                originalQuantity: quantity,
                unit: unit,
              };
            }
            return i;
          }),
        );
      }
    }
  };
  const handleCartApi = (isBackNavigation) => {
    if (!dbUser) {
      return navigate(-1);
    }
    if (cartDeleted) {
      window.location.replace('/');
    } else {
      if (dbCart && cart.length > 0) {
        let total = 0;
        let discount = 0;
        let subtotal = 0;

        for (let index = 0; index < cart.length; index++) {
          const element = cart[index];
          subtotal += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 : element.price) * element.originalQuantity;
          total += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 - element.discount / 1000 : element.price - element.discount) * element.originalQuantity;
          discount += (element.unit === 'g' || element.unit === 'ml' ? element.discount / 1000 : element.discount) * element.originalQuantity;
        }
        const cartData = {
          id: dbCart.id,
          merchant: merchant?.id,
          customer: user?.id,
          products: cart,
          total: total,
          discount: discount,
          subtotal: subtotal,
        };
        updateCart({ cart: cartData, token: localStorage.getItem('token') })
          .unwrap()
          .then((d) => {
            window.history.back();
          })
          .catch((e) => {
            window.history.back();
          });
      } else if (dbCart && cart.length === 0) {
        deleteCart({ cart: dbCart, token: localStorage.getItem('token') })
          .unwrap()
          .then((c) => {
            window.history.back();
          })
          .catch((e) => {
            window.history.back();
          });
      } else if (!dbCart && cart?.length > 0) {
        let total = 0;
        let discount = 0;
        let subtotal = 0;

        for (let index = 0; index < cart.length; index++) {
          const element = cart[index];

          subtotal += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 : element.price) * element.originalQuantity;
          total += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 - element.discount / 1000 : element.price - element.discount) * element.originalQuantity;
          discount += (element.unit === 'g' || element.unit === 'ml' ? element.discount / 1000 : element.discount) * element.originalQuantity;
        }

        if (otherCartId && isOtherMerchant) {
          const cartData = {
            id: otherCartId,
            merchant: merchant?.id,
            customer: user?.id,
            products: cart,
            total: total,
            discount: discount,
            subtotal: subtotal,
          };
          updateCart({ cart: cartData, token: localStorage.getItem('token') })
            .unwrap()
            .then((d) => {
              window.history.back();
            })
            .catch((e) => {
              window.history.back();
            });
        } else {
          const order = {
            merchant: merchant?.id,
            customer: user?.id,
            products: cart,
            total: total,
            discount: discount,
            subtotal: subtotal,
          };
          createCart({ cart: order, token: localStorage.getItem('token') })
            .unwrap()
            .then((d) => {
              window.history.back();
            })
            .catch((e) => {
              window.history.back();
            });
        }
      } else {
        window.history.back();
      }
    }
  };

  return (
    <div>
      <div className="bg-background-primary z-10 fixed top-0 text-header-text w-full py-3 px-2 flex justify-start items-center gap-8 shadow-sm">
        {/* {showBackNavigation === true && !showBackToHome && <IoMdArrowBack onClick={()=>{navigate(-1)}} size={24}/>} */}
        <IoMdArrowBack
          onClick={() => {
            handleCartApi(true);
          }}
          size={24}
        />
        {/* <img src="/zmlogo.png" className="w-10"/> */}
        <div className="text-md font-extrabold w-full items-start flex">
          <span className="text-header-text text-start w-full">{merchant.name}</span>
        </div>
      </div>
      <div className="mt-12 p-4 md:mr-28 md:ml-28">
        <div className="flex justify-between w-full">
          <div className="text-start">
            <p className="text-sm font semibold">{merchant?.geolocation} </p>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {merchant?.categories.map((c, i) => {
                return (
                  <h3 key={c} className="text-sm whitespace-nowrap font-semibold text-content-text">
                    &bull;{c}&nbsp;
                  </h3>
                );
              })}
            </div>
            {merchant?.deliveryRadius && merchant?.deliveryRadius > 0 && (
              <div className="flex items-center  mt-2">
                <HiTruck size={24} className="text-accent-darker" />
                <p className="text-xs font-semibold text-accent-darker">Delivering upto {merchant?.deliveryRadius}km</p>
              </div>
            )}
          </div>
          <div className="text-end">
            {!merchant?.acceptingOrders && <p className="text-lg rounded-xl font-semibold text-alert-darker">Closed</p>}
            {merchant?.acceptingOrders && <p className="text-lg rounded-xl font-semibold text-success-darker">Open{''}</p>}

            <p className="text-sm font-bold">
              {(merchant?.distance / 1000).toFixed(2)}
              {' km'}
            </p>
            <div className="flex  items-center">
              <IoMdTime size={16} />
              <p className="text-sm whitespace-nowrap font-semibold text-header-text">
                {merchant?.timeOpen.split(':')[0]}:{merchant?.timeOpen.split(':')[1]} - {merchant?.timeClosed.split(':')[0]}:{merchant?.timeClosed.split(':')[1]}
              </p>
            </div>
          </div>
        </div>
        <hr className=" border-dashed border-sub-text mt-2" />
        {cart && <ItemCard setReloadData={setReloadData} reloadData={reloadData} customerItemSub={customerItemSub} user={dbUser} userSubMerchant={userSubMerchant} userSubs={userSubs} merchant={merchant} cart={cart.filter((c) => c.id == item.id).length > 0 ? cart.filter((c) => c.id == item.id)[0] : 0} handleAddProduct={handleSetCart} key={merchant.id} product={item} />}
      </div>

      {cart && cart.length > 0 && order && order.phoneMerchant === merchant?.phone && <CartBanner isOtherMerchant={isOtherMerchant} otherCartId={otherCartId} dbCart={dbCart} merchant={merchant} user={dbUser} cart={cart} handleCartApi={handleCartApi} />}
    </div>
  );
}
