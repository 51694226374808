import React from 'react';
import QRCode from 'qrcode.react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io';
 
export default function QR(){
    const navigate = useNavigate()
    const location = useLocation()
    const id = location.state.id
    return (
        <div className="w-full h-full flex items-center justify-center">
            <div className="bg-background-primary z-10 fixed top-0 text-header-text w-full py-3 px-4 flex justify-center shadow-sm items-center ">
      <div className="flex w-full justify-between items-center">

        <div className="flex items-center gap-4">
          <IoMdArrowBack onClick={() => {
            navigate(-1)
         }} size={24} />
         
        </div>
        <div className="w-full font-bold items-center text-center flex justify-center">
          {/* Order Details */}
         </div>
      </div>
      </div>
        <div className='mt-16 w-full h-screen flex items-center flex-col'>
        <h1 className="text-header-text text-lg font-bold mt-4 flex  text-center">Use this QR at shop counter to verify your order</h1>
          <QRCode
            title="Order QR"
            className="mt-12"
            value={`${id}`}
            bgColor={getComputedStyle(document.documentElement).getPropertyValue('--background-primary')}
            fgColor={getComputedStyle(document.documentElement).getPropertyValue('--header-text')}
            size={256}
          />
        </div>
        </div>
    )
}