import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Home from './pages/home/home';
import Main from './main';
import HomeIndex from './pages/home';
import Items from './pages/store/items';
import Scan from './pages/store/scan';
import Cart from './pages/store/cart';
import Login from './pages/auth/login';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebaseConfig';
import ItemDetail from './pages/store/details';
import OrderDetail from './pages/order/detail';
import { useEffect, useState } from 'react';
import { useGetUserQuery } from './state/api';
import { App as CapApp } from '@capacitor/app';
import { Capacitor, Plugins } from '@capacitor/core';
import ons from 'onsenui';
import { FCM } from '@capacitor-community/fcm';
import { PushNotifications } from '@capacitor/push-notifications';
import { Preferences } from '@capacitor/preferences';
import { StatusBar, Style } from '@capacitor/status-bar';
import Geocode from 'react-geocode';
import QR from './pages/order/qr';
import NotFound from './pages/notfound';
import Points from './pages/home/points';
import LoyaltyPoints from './pages/home/points';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorComponent from './components/ErrorComponent';
import BlockedComponent from './components/BlockedComponent';
import axios from 'axios';
import Orders from './pages/home/orders';
import SettingsPage from './pages/home/settings';
import TrackOrder from './pages/order/track-order-android.jsx';
import TrackOrders from './pages/order/track/track-order';
import Modal from './components/items/Modal';
import { toast, ToastContainer, Slide, Zoom } from 'react-toastify';
import { app, fireBaseVapidKey } from './firebaseConfig';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import PermissionModal from './components/items/PermissionModal';
import { paletten } from 'paletten';

function App() {
  const [user, loading] = useAuthState(auth);
  const [userLocation, setUserLocation] = useState(null);
  const [activeMenu, setActiveMenu] = useState('home');
  const [dbUser, setDbUser] = useState();
  const [address, setAddress] = useState();
  const [showMap, setShowMap] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  const token = localStorage.getItem('token');

  const [isLocation, setIsLocation] = useState(localStorage.getItem('isLocation'));
  const [shouldSkip, setShouldSkip] = useState(false);
  const { data, error } = useGetUserQuery(
    {
      id: user ? user.phoneNumber.replace('+', '%2b') : '',
      token: token ? token : '',
    },
    { skip: loading ? true : !shouldSkip },
  );

  //Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
  Geocode.setLanguage('en');

  //modal
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
    setPermissionDenied(false);
  };
  const closeModalWithTimeout = () => {
    // Close the modal after 5000 milliseconds (5 seconds)
    setTimeout(() => {
      closeModal();
    }, 5000);
  };

  useEffect(() => {
    window.addEventListener('ack', function (msg) {
      if (msg.ack === 'zm202') {
        setModalOpen(true);
        closeModalWithTimeout();
      }

      if (msg.ack === 'zm301') {
        toast.success('Order out for Delivery!');
      }
    });

    // window.addEventListener("MyPushEvent", function (msg) {
    //   if (msg.title == "Order Update!") {
    //     setRefreshData(true);
    //     toast.info("Order status updated !")
    //   }
    // });
  }, []);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleLocationSuccess, handleLocationError);
    } else {
      handleLocationError();
    }
  };

  const handleLocationSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    Preferences.set({
      key: 'userLocation',
      value: JSON.stringify({ latitude, longitude }),
    }).then(() => {
      localStorage.setItem('isLocation', 'true');
      setUserLocation({ latitude, longitude });
      geocodeAddress(latitude, longitude);
    });
  };

  const handleLocationError = (error) => {
    // console.log('Error getting user location:', error);

    Preferences.get({ key: 'userLocation' })
      .then((u) => {
        var loc = JSON.parse(u.value);
        setUserLocation({
          latitude: parseFloat(loc.latitude),
          longitude: parseFloat(loc.longitude),
        });
        geocodeAddress(loc.latitude, loc.longitude);
        localStorage.setItem('isLocation', 'true');
      })
      .catch(() => {});
  };

  const geocodeAddress = (latitude, longitude) => {
    Geocode.fromLatLng(latitude, longitude)
      .then((response) => {
        const address = response.results[0].formatted_address;
        setAddress(address);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  useEffect(() => {
    if (isLocation !== 'true') {
      getUserLocation();
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && error) {
      auth
        .signOut()
        .then(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          window.location.replace('/login');
        })
        .catch((e) => {
          alert(e.message);
        });
    }
  }, [error]);

  useEffect(() => {
    ons.ready(() => {
      ons.enableDeviceBackButtonHandler();

      ons.setDefaultDeviceBackButtonListener((ev) => {
        if (window.location.pathname == '/home' || window.location.pathname == '/login') {
          CapApp.exitApp();
          localStorage.removeItem('isLocation');
        } else {
          window.history.back();
        }
      });
    });

    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

    if (isPushNotificationsAvailable) {
      PushNotifications.requestPermissions().then((v) => {
        if (v.receive === 'granted') {
          PushNotifications.register().then((v) => {
            FCM.subscribeTo({ topic: `${user.phoneNumber.replace('+', '')}` })
              .then((r) => {})
              .catch((err) => {});
          });

          PushNotifications.register().then((v) => {
            FCM.subscribeTo({ topic: `track-orders` })
              .then((r) => {})
              .catch((err) => {});
          });
        } else {
          alert('Allow Notification Permission To Receive Order Updates!');
        }
      });

      PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {});
    }
  }, [user]);
  useEffect(() => {
    if (data && data.data[0]) {
      setDbUser(data.data[0]);
    }
  }, [data]);

  //below code is to hide navbar on scroll
  const [scrollDown, setScrollDown] = useState(false);
  useEffect(() => {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
      let currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        setScrollDown(false);
      } else {
        setScrollDown(true);
      }
      prevScrollpos = currentScrollPos;
    };
  }, []);

  const logout = async () => {
    if (user) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/auth/unsubscribe-from-topic`,
          {
            topic: `${user.phoneNumber.replace('+', '')}`,
            token: localStorage.getItem('fcmToken'),
          },
          { headers: { Authorization: '' } },
        )
        .then((resp) => {
          //do no delete this line
          const user = resp;
        })
        .catch((error) => {
          return toast.error('Error!!wont be able to recieve notification');
        });
    }

    auth
      .signOut()
      .then(() => {
        localStorage.removeItem('token');
        setDbUser();
      })
      .catch((e) => {});
  };

  useEffect(() => {
    if (user && !localStorage.getItem('token')) {
      setDbUser();
      logout();
    }
  }, [localStorage.getItem('token')]);
  const [permissionRequired, setPermissionRequired] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);

  useEffect(() => {
    const getFCMToken = async () => {
      if (Notification.permission === 'default') {
        setPermissionRequired(true);
      }
      Notification.requestPermission().then(async (permission) => {
        if (permission !== 'granted') {
          setPermissionDenied(true);
          return;
        }
        setPermissionRequired(false);
      });

      const messaging = await getMessaging(app);

      getToken(messaging, { vapidKey: fireBaseVapidKey })
        .then(async (currentToken) => {
          if (!currentToken) {
            return toast.error('Unable to notification token');
          } else {
            const fcmToken = localStorage.getItem('fcmToken');

            if (currentToken != fcmToken) {
              const headers = { Authorization: '' };

              let data = {
                topic: `${user.phoneNumber.replace('+', '')}`,
                token: fcmToken,
              };

              if (localStorage.getItem('fcmToken')) {
                await axios
                  .post(`${process.env.REACT_APP_BASE_URL}/api/auth/unsubscribe-from-topic`, data, { headers: headers })
                  .then((resp) => {
                    //do not delete this line it
                    const message = resp;
                  })
                  .catch((error) => {
                    return toast.error('Error!!', ' wont be able to recieve notification');
                  });
              }

              localStorage.setItem('fcmToken', currentToken);
              data.token = currentToken;
              await axios
                .post(`${process.env.REACT_APP_BASE_URL}/api/auth/subscribe-to-topic`, data, { headers: headers })
                .then((resp) => {
                  //do not delete this line it
                  const topic = resp;
                })
                .catch((error) => {
                  // return toast.error('Error!! wont be able to recieve notification');
                  // window.location.reload();
                });
            }
          }
        })
        .catch((err) => {
          setPermissionRequired(false);
        });
    };

    if (Capacitor.getPlatform() === 'web' && user) getFCMToken();
  }, [user, Capacitor.getPlatform()]);

  const [merchantLogo, setMerchantLogo] = useState(null);
  const [merchantBanner, setMerchantBanner] = useState(null);
  const [whiteListedMerchant, setWhiteListedMerchant] = useState(null);
  useEffect(() => {
    const root = document?.documentElement;
    if (root && process.env.REACT_APP_IS_WHITELISTED === 'true') {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/merchant-whitelisteds?filters[merchant][id][$eq]=${process.env.REACT_APP_MERCHANT_ID}&populate=*`, { headers: { Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '' } })
        .then((res) => {
          setWhiteListedMerchant(res.data.data[0]);
          const colors = res.data.data[0].attributes;
          setMerchantLogo(res.data.data[0].attributes.logo);
          setMerchantBanner(res.data.data[0].attributes.banners);
          root.style.setProperty('--primary', colors.primary);
          root.style.setProperty('--primary-lighter', colors.primaryLighter);
          root.style.setProperty('--primary-darker', colors.primartDarker);
          root.style.setProperty('--secondary', colors.secondary);
          root.style.setProperty('--secondary-lighter', colors.secondaryLighter);
          root.style.setProperty('--secondary-darker', colors.secondaryDarker);
          root.style.setProperty('--background-primary', colors.backgroundPrimary);
          root.style.setProperty('--background-secondary', colors.backgroundSecondary);
          root.style.setProperty('--header-text', colors.headerText);
          root.style.setProperty('--content-text', colors.contentText);
          root.style.setProperty('--sub-text', colors.subText);
          // root.style.setProperty('--accent', colors.accentColor);
          // root.style.setProperty('--accent-lighter', colors.accentColorLighter);
          // root.style.setProperty('--accent-darker', colors.accentColorDarker);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    //registers the fcm service for firebase messaging
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('resgisters service');
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    }
  }, []);

  if (loading) {
    return (
      <div className="app_loader">
        <div className={`loader ${user ? 'bg-primary' : 'bg-background-primary'} h-screen w-screen flex items-center justify-center`}>
          {user ? (
            <span className="text-3xl text-sub-text font-bold uppercase">ZUNDERMART</span>
          ) : (
            <div role="status" className="h-screen w-screen items-center flex justify-center">
              <svg aria-hidden="true" className=" w-20 h-20 mr-2 text-sub-text animate-spin dark:text-content-text fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </div>
      </div>
    );
  }
  if (user) {
    // window.location.replace('/')
    // setShouldSkip(true)
    if (!shouldSkip) {
      setShouldSkip(true);
    }
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => {
        window.location.replace('/');
      }}
      onError={(error, errorInfo) => {
        const token = localStorage.getItem('token');
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/api/error-logs`,
            {
              data: {
                Error: { error, errorInfo },
                firebaseUser: auth.currentUser,
                appVersion: process.env.REACT_APP_VERSION,
                app: 'ZunderMart',
              },
            },
            { headers: { Authorization: token ? `Bearer ${token}` : '' } },
          )
          .then(() => {})
          .catch(() => {});
      }}
    >
      <div className="App">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          rtl={false}
          closeOnClick={true}
          draggable={true}
          pauseOnFocusLoss
          pauseOnHover
          // theme="colored"
          transition={Zoom}
        />
        <Modal isOpen={isModalOpen} closeModal={closeModal}>
          <div className="text-center py-2 max-w-md">
            <h2 className="text-xl font-semibold  text-primary ">Delivery Partner Assigned!</h2>

            <img src="/accept.gif" alt="accepted" width={500} height={500} className=" mix-blend-multiply" />

            <div className="flex justify-between ">
              <button onClick={() => closeModal()} className={`${'bg-secondary hover:bg-secondary active:bg-secondary-darker'} text-sub-text font-semibold py-2 px-4 rounded-md w-full`}>
                OK
              </button>
            </div>
          </div>
        </Modal>

        <PermissionModal isOpen={permissionDenied} closeModal={closeModal}>
          <div className="text-center py-2 ">
            <img className=" object-contain mix-blend-multiply" src="/notification_denied.png" alt="accepted" />
          </div>
        </PermissionModal>

        <PermissionModal isOpen={permissionRequired} closeModal={closeModal}>
          <div className="text-center py-2 ">
            <img className=" object-contain mix-blend-multiply" src="/permission_allow.png" alt="accepted" />
          </div>
        </PermissionModal>

        {!loading ? (
          <div>
            {isBlocked ? (
              <BlockedComponent />
            ) : (
              <BrowserRouter>
                <Routes>
                  <Route element={<HomeIndex setActiveMenu={setActiveMenu} activeMenu={activeMenu} setShowMap={setShowMap} showMap={showMap} dbUser={dbUser} setDbUser={setDbUser} user={dbUser && dbUser} scrollDown={scrollDown} />}>
                    <Route path="/" element={<Navigate to="/home" state={{ user: dbUser && dbUser }} replace />} />
                    <Route path="/home" element={<Home setActiveMenu={setActiveMenu} activeMenu={activeMenu} setShowMap={setShowMap} showMap={showMap} dbUser={dbUser} setDbUser={setDbUser} user={dbUser && dbUser} scrollDown={scrollDown} merchantLogo={merchantLogo} merchantBanner={merchantBanner} whiteListedMerchant={whiteListedMerchant} />} />
                    <Route path="/orders" element={<Orders activeMenu={activeMenu} setActiveMenu={setActiveMenu} dbUser={dbUser} setDbUser={setDbUser} user={dbUser && dbUser} merchantLogo={merchantLogo} />} />
                    <Route path="/profile" element={<SettingsPage showMap={showMap} setShowMap={setShowMap} activeMenu={activeMenu} setActiveMenu={setActiveMenu} dbUser={dbUser} setDbUser={setDbUser} user={dbUser && dbUser} merchantLogo={merchantLogo} whiteListedMerchant={whiteListedMerchant} />} />
                  </Route>

                  <Route path="/store-details" element={<Items dbUser={dbUser && dbUser} />} />
                  <Route path="/items/scan" element={<Scan />} />
                  <Route path="/item/detail" element={<ItemDetail dbUser={dbUser && dbUser} />} />

                  <Route path="/orders/track/:id" element={<TrackOrders dbUser={dbUser && dbUser} />} />

                  {dbUser && (
                    <>
                      <Route path="/items/cart" element={<Cart />} />
                      <Route path="/orders/detail" element={<OrderDetail />} />
                      <Route path="/orders/qr" element={<QR />} />
                      <Route path="/loyalty-points" element={<LoyaltyPoints whiteListedMerchant={whiteListedMerchant} />} />
                    </>
                  )}

                  <Route path="/login" element={<Login setActiveMenu={setActiveMenu} activeMenu={activeMenu} dbUser={dbUser && dbUser} setDbUser={setDbUser} setIsBlocked={setIsBlocked} />} />

                  <Route path="*" element={<Navigate to="/" />} />

                  {/* <Route path="about" element={ <About/> } />
                  <Route path="contact" element={ <Contact/> } /> */}
                </Routes>
              </BrowserRouter>
            )}
          </div>
        ) : (
          <div className="app_loader">
            <div className={`loader ${user ? 'bg-primary' : 'bg-background-primary'} h-screen w-screen flex items-center justify-center`}>
              {user ? (
                <span className="text-3xl text-sub-text font-bold uppercase">ZUNDERMART</span>
              ) : (
                <div role="status" className="h-screen w-screen items-center flex justify-center">
                  <svg aria-hidden="true" className=" w-20 h-20 mr-2 text-sub-text animate-spin dark:text-content-text fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}

export default App;
