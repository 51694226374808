import { FaTrashAlt, FaTimes } from 'react-icons/fa';
const Modal = ({ isOpen, closeModal, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center mx-2">
      <div className="fixed inset-0 opacity-60"></div>
      <div className="relative z-50 w-fit max-w-sm p-4 mx-auto bg-background-primary rounded-lg shadow-lg">
        <button className="absolute top-2 right-2 text-content-text hover:text-header-text  rounded-md font-semibold px-1 " onClick={closeModal}>
          <FaTimes className="text-content-text cursor-pointer" onClick={closeModal} />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
