import { BrowserView, MobileOnlyView } from 'react-device-detect';
import OrderCard from '../../components/home/OrderCard';
import TrackOrderCard from '../../components/home/TrackOrderCard';
import { useGetOrdersQuery } from '../../state/api';
import { auth } from '../../firebaseConfig';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import HeaderBrowser from '../../components/HeaderBrowser';
import axios from 'axios';
import { IoSearchOutline } from 'react-icons/io5';

export default function Orders({ activeMenu, setActiveMenu, user, setDbUser, dbUser, merchantLogo }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchStatus, setSearchStatus] = useState('');
  const [searchId, setSearchId] = useState('');
  const [shouldSkip, setShouldSkip] = useState(false);
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const { data, isLoading, isFetching } = useGetOrdersQuery(
    {
      page: currentPage,
      customerId: user?.id,
      token: localStorage.getItem('token'),
    },
    { skip: shouldSkip },
  );

  // useEffect(() => {
  //   refetch()
  //     .then((data) => {
  //       console.log(data);
  //       if (data.data.data.length > 0) {
  //         console.log('if here');

  //         setOrders((prev) => {
  //           // Create a new Map. The keys of the Map will be the order ids.
  //           const orderMap = new Map([...prev, ...data.data.data].map((order) => [order.id, order]));

  //           // Convert the Map back to an array.
  //           const temp = Array.from(orderMap.values());
  //           console.log('temp ', temp);

  //           return temp;
  //         });
  //       } else {
  //         console.log('else here');
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //     });
  // }, []);

  useEffect(() => {
    console.log('orders : ', orders);
  }, [orders]);

  useEffect(() => {
    console.log(data);
    if (data) {
      setOrders((order) => Array.from(new Set([...order, ...data.data])));
      setHasMore(false);
      setShouldSkip(true);
      setTotalPageCount(data.meta.pagination.pageCount);
    } else {
      setShouldSkip(false);
    }
  }, [data]);

  const refetchOrders = () => {
    setHasMore(true);
    setShouldSkip(false);
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    if (currentPage > totalPageCount) {
      setHasMore(false);
    }
  }, [currentPage]);

  const handleScroll = () => {
    if (Math.ceil(window.innerHeight + document.documentElement.scrollTop) !== document.documentElement.offsetHeight || hasMore) {
      // console.log(window.innerHeight + document.documentElement.scrollTop);
      // console.log(document.documentElement.offsetHeight);
      return;
    }

    if (currentPage <= totalPageCount) {
      refetchOrders();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore]);
  const handleStatusChange = (e) => {
    if (e.target.value === 'All') {
      setSearchStatus('');
    } else {
      setSearchStatus(e.target.value);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const statuses = ['All', 'Pending', 'Accepted', 'Packaging', 'Ready', 'Cancelled', 'Out-for-delivery', 'Delivered'];

  useEffect(() => {
    // this use effect is used only to put error log if a merchant is deleted for null merchant in order
    if (orders && user) {
      const ordersWithNullMerchants = orders.filter((item) => !item.attributes?.merchant?.data);

      if (ordersWithNullMerchants.length > 0) {
        const token = localStorage.getItem('token');
        const headers = { Authorization: token ? `Bearer ${token}` : '' };
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/api/error-logs`,
            {
              data: {
                Error: {
                  message: `Merchant with ${ordersWithNullMerchants[0].attributes.phoneMerchant} has been deleted`,
                  dbUser: user,
                  ordersWithNullMerchants: ordersWithNullMerchants,
                },
                firebaseUser: user,
                appVersion: process.env.REACT_APP_VERSION,
                app: 'ZunderMart',
              },
            },
            { headers: headers },
          )
          .then((res) => {})
          .catch((error) => {});
      }
    }
  }, [orders]);

  return (
    <div>
      <HeaderBrowser setActiveMenu={setActiveMenu} activeMenu={activeMenu} user={user} merchantLogo={merchantLogo} />

      {data ? (
        <div className="bg-background-primary mb-16">
          <MobileOnlyView>
            <div className="bg-background-primary z-10 fixed top-0 text-header-text w-full p-3 flex justify-start items-center gap-8 shadow-sm">
              <div className="flex items-center gap-4">
                <div className="flex items-center justify-between bg-background-primary rounded-lg  w-full">
                  {/* Search Logo */}

                  {/* Search Input */}
                  {process.env.REACT_APP_IS_WHITELISTED !== 'true' && (
                    <>
                      <div className="">
                        <IoSearchOutline className=" fill-header-text w-5 h-5 " />
                      </div>
                      <input
                        type="text"
                        placeholder="Search by store"
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                        className="flex-grow px-4 w-full py-1 rounded-lg focus:outline-none bg-background-primary"
                      />
                    </>
                  )}
                  <select
                    onChange={(e) => {
                      handleStatusChange(e);
                    }}
                    className="ml-2 text-primary font-bold underline rounded-lg p-0 bg-background-primary focus:outline-none "
                  >
                    <option value="All">All</option>
                    <option value="Pending">Pending</option>
                    <option value="Accepted">Accepted</option>
                    <option value="Packaging">Packaging</option>
                    <option value="Ready">Ready</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Out-for-delivery">Out for delivery</option>
                    <option value="Cancelled">Cancelled</option>
                    {/* <option value="User-Cancelled">User Cancelled</option> */}
                  </select>
                </div>
              </div>
            </div>
          </MobileOnlyView>
          <div className="flex md:p-8  mt-4 md:mt-0 md:mr-28 md:ml-28">
            <BrowserView className="w-1/4 p-4 bg-background-primary border-e shadow-md mt-14 items-start flex flex-col">
              <span className="text-lg text-header-text font-bold text-start w-full">Filter By Status</span>
              <div className="w-full">
                {statuses.map((s, i) => {
                  return <TrackOrderCard key={i} selectedStatus={searchStatus} setStatus={setSearchStatus} orderID={'Order: '} status={s} />;
                })}
              </div>
            </BrowserView>

            <div className="md:mt-14 md:w-3/4 md:bg-background-primary md:p-4">
              <BrowserView>
                <div className="flex w-full items-center gap-1 mt-2">
                  <hr className="w-full border-sub-text" />
                  <span className="text-md text-header-text font-bold text-center w-full">Showing Recent Orders First</span>
                  {/* <MobileOnlyView> <span className="text-md text-header-text font-bold text-center w-full">Order History</span></MobileOnlyView> */}
                  <hr className="w-full border-sub-text" />
                </div>
              </BrowserView>
              <MobileOnlyView>
                <div className="flex mt-12  w-full items-start text-start gap-1 p-2">
                  <span className="text-lg  text-header-text font-bold w-full text-start">Orders</span>
                  {/* <MobileOnlyView> <span className="text-md text-header-text font-bold text-center w-full">Order History</span></MobileOnlyView> */}
                </div>
              </MobileOnlyView>
              <BrowserView>
                <div className={`flex items-center justify-between bg-background-primary border border-sub-text rounded-lg  p-1 mt-2 mr-4 ml-4 ${process.env.REACT_APP_IS_WHITELISTED !== 'true' && 'border'}`}>
                  {/* Search Logo */}
                  {/* <div className="">
                    <IoSearchOutline className=" fill-header-text w-5 h-5 ml-1" />
                  </div>

                
                  <input
                    type="text"
                    placeholder="Search by store"
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                    className="flex-grow px-4 w-full py-1 rounded-lg focus:outline-none bg-background-primary"
                  /> */}
                  {process.env.REACT_APP_IS_WHITELISTED !== 'true' && (
                    <>
                      <div className="">
                        <IoSearchOutline className=" fill-header-text w-5 h-5 " />
                      </div>
                      <input
                        type="text"
                        placeholder="Search by store"
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                        className="flex-grow px-4 w-full py-1 rounded-lg focus:outline-none bg-background-primary"
                      />
                    </>
                  )}

                  {/* Filter Menu */}
                  <MobileOnlyView>
                    <select
                      onChange={(e) => {
                        handleStatusChange(e);
                      }}
                      className="ml-2 rounded-lg p-0 bg-background-primary  focus:outline-none "
                    >
                      <option value="All">All</option>
                      <option value="Pending">Pending</option>
                      <option value="Accepted">Accepted</option>
                      <option value="Packaging">Packaging</option>
                      <option value="Ready">Ready</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="Out-for-delivery">Out for delivery</option>
                      <option value="Delivered">Delivered</option>
                      {/* <option value="User-Cancelled">User Cancelled</option> */}
                    </select>
                  </MobileOnlyView>
                </div>
              </BrowserView>
              {/* <span className="text-sm ml-4 text-header-text font-bold text-start w-full">Showing Recent Orders First</span> */}

              <div>
                {/* below code is changed and wont display orders where merchant were deleted */}
                {orders && orders.filter((i) => i?.attributes?.merchant?.data?.attributes?.name.trim().toLowerCase().includes(searchQuery.trim().toLowerCase())).filter((i) => i?.attributes?.status.trim().toLowerCase().includes(searchStatus.trim().toLowerCase())).length > 0 ? (
                  <div className="grid mb-24 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 m-2">
                    {orders
                      .filter((i) => i?.attributes?.merchant?.data?.attributes?.name.trim().toLowerCase().includes(searchQuery.trim().toLowerCase()))
                      .filter((i) => i?.attributes?.status.trim().toLowerCase().includes(searchStatus.trim().toLowerCase()))
                      .map((o, i) => {
                        return <OrderCard order={o} key={i} />;
                      })}
                  </div>
                ) : (
                 <div className=' w-full'>
                   {!hasMore && orders.length === 0 && !isLoading && (
                    <div className="p-6 md:mt-4 flex items-center w-full flex-col justify-center h-full">
                      <img src="/no-order.jpg" className=" bg-blend-multiply aspect-square"></img>
                      <span className="font-bold mt-4">Not Found!</span>
                    </div>
                  )}
                 </div>
                )}

             
                {(isLoading || hasMore) && (
                  <div className="p-4 flex flex-col w-full items-center justify-center mt-1 gap-4">
                    {' '}
                    <div role="status" className="flex items-center justify-center h-8 w-8">
                      <svg className="w-24 h-24 mr-2 text-sub-text animate-spin dark:text-content-text fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                      </svg>
                      {/* <span className="sr-only">Loading...</span> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : isLoading || isFetching ? (
        <div role="status" className="flex items-center justify-center h-screen w-screen">
          <svg className="w-24 h-24 mr-2 text-sub-text animate-spin dark:text-content-text fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>
          {/* <span className="sr-only">Loading...</span> */}
        </div>
      ) : (
        <div className=" p-6 mt-12 md:mt-4 flex items-center w-full flex-col justify-center h-full">
          <img className="md:w-1/4 md:h-1/4 pt-4" src="/no-order.jpg"></img>
          <span className="font-bold mt-12">Looks like you don’t have any order.</span>
        </div>
      )}
    </div>
  );
}
