import moment from 'moment';
import { FaRupeeSign, FaStore } from 'react-icons/fa';
import { IoMdArrowBack } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';

const LoyaltyPoints = ({ whiteListedMerchant }) => {
  const location = useLocation();
  const user = location.state.user;
  const navigate = useNavigate();
  return (
    <div>
      {user.attributes.customer_points.data && user.attributes.customer_points.data.length > 0 && (
        <div className="md:w-3/4 md:mt-16 w-full border-sub-text   md:border-none">
          <div className=" flex items-start flex-col w-full">
            <div className="flex justify-start items-center w-full gap-4 mb-4 fixed top-0 shadow-sm bg-background-primary p-3">
              <div className="flex justify-start items-center w-full gap-4 mb-4 fixed top-0 shadow-sm bg-background-primary p-3">
                <IoMdArrowBack
                  size={20}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <span className="text-sm font-semibold text-header-text">Points Earned</span>
                {/* <span  className='text-sm font-semibold text-primary underline cursor-pointer'>View All</span> */}
                <span className="text-sm font-semibold text-header-text">Points Earned</span>
                {/* <span  className='text-sm font-semibold text-primary underline cursor-pointer'>View All</span> */}
              </div>
              <div className="p-2 grid grid-cols-2  gap-3 w-full mt-16">
                {user.attributes.customer_points.data
                  .filter((cp) => {
                    if (process.env.REACT_APP_IS_WHITELISTED === 'true') {
                      return cp.attributes.points > 0 && cp.attributes.merchant?.data?.id === whiteListedMerchant?.attributes?.merchant?.data?.id;
                    } else {
                      return cp.attributes.points > 0;
                    }
                  })
                  .map((cp, idx) => {
                    return (
                      <div
                        onClick={() => {
                          navigate('/store-details', { state: { merchant: { id: cp.attributes.merchant.data.id, ...cp.attributes.merchant.data.attributes }, user: user } });
                        }}
                        className={`${idx === user.attributes.customer_points.data.length - 1 && idx % 2 === 0 ? 'col-span-2' : 'col-span-1'} bg-gradient-to-b border-dashed border-secondary rounded-md p-2 flex from-background-primary via-background-primary to-secondary-lighter flex-col justify-center items-center shadow-md border`}
                        key={cp.id}
                      >
                        <div className="flex w-full items-center justify-start gap-2">
                          <FaStore size={20} />
                          <h3 className="text-sm text-start line-clamp-2 font-semibold text-header-text">{cp.attributes.merchant.data.attributes.name}</h3>
                        </div>
                        <div className="text-start mt-2  text-xs w-full justify-start font-normal text-content-text   flex items-center">
                          1 point is worth of <FaRupeeSign /> <strong className="font-bold text-xs">{cp.attributes.loyalty.data.attributes.pointWorth}</strong>{' '}
                        </div>
                        <h3 className="text-start  mt-2 text-xl w-full font-bold text-primary">{cp.attributes.points}</h3>
                        <p className="text-start flex items-center mt-2  text-xs w-full font-normal text-content-text  ">
                          You can redeem max <FaRupeeSign /> <strong>{cp.attributes.points * cp.attributes.loyalty.data.attributes.pointWorth}</strong>{' '}
                        </p>
                        <h3 className="text-start  mt-2 text-xs w-full font-semibold text-content-text underline">Redeem</h3>
                        {cp.attributes.loyalty.data.attributes.expiry && (
                          <div className="mt-2 border-t-2 border-dashed border-secondary  w-full">
                            <span className="text-xs p-2 font-normal text-content-text">Expires on {moment(cp.attributes.loyalty.data.attributes.expiry).format('DD MMM YYYY')}</span>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoyaltyPoints;
