import { HiMapPin, HiTruck } from 'react-icons/hi2';
import MerchantCard from '../../components/home/MerchantCard';
import CallButton from '../../components/home/CallButton';
import DirectionsButton from '../../components/home/DirectionsButton';
import ShareButton from '../../components/home/ShareButton';
import { useLocation, useNavigate } from 'react-router-dom';
import ItemCard from '../../components/items/ItemCard';
import HeaderMobie from '../../components/HeaderMobile';
import FloatingButton from '../../components/items/FloatingButton';
import HomeFloatingButton from '../../components/items/HomeFloatingButton';
import { BrowserView } from 'react-device-detect';
import CartBanner from '../../components/items/CartBanner';
import moment from 'moment';
import { useCreateCartMutation, useDeleteCartMutation, useGetCartQuery, useGetMerchantItemsQuery, useGetSubscriptionItemsQuery, useUpdateCartMutation } from '../../state/api';
import { useEffect, useState } from 'react';
import { IoMdArrowBack, IoMdCall, IoMdClock, IoMdLocate, IoMdPin, IoMdShare, IoMdTime } from 'react-icons/io';
import Geocode from 'react-geocode';
import { RWebShare } from 'react-web-share';
import { useDispatch } from 'react-redux';
import { Share } from '@capacitor/share';
import { App as CapApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import ons from 'onsenui';
import { FaPercent, FaRupeeSign, FaSearch } from 'react-icons/fa';
import { Browser } from '@capacitor/browser';
import axios from 'axios';
import ContactBottomSheet from '../../components/ContactBottomSheet';
import { toast } from 'react-toastify';

export default function Items({ dbUser }) {
  const location = useLocation();

  const navigate = useNavigate();
  const user = location?.state?.user;
  const [skip, setSkip] = useState(false);
  // if (!user) {
  //   window.location.replace("/")
  // }
  const dispatch = useDispatch();
  const [shouldSkip, setShouldSkip] = useState(false);

  // ...

  // Function to refresh the cart data

  const merchant = location?.state?.merchant;
  if (!merchant) {
    window.location.replace('/');
  }
  // condition to check if closed day is today
  const [showClosedDayTime, setShowClosedDayTime] = useState(null);
  useEffect(() => {
    if (merchant?.closedDays?.length > 0) {
      const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const tempDay = merchant?.closedDays?.find((tempDay) => tempDay.name === daysOfWeek[currentDay]);
      if (tempDay) {
        setShowClosedDayTime(tempDay);
      }
    }
  }, [merchant?.closedDays]);

  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const defaultItemCategories = [
    {
      id: 0,
      attributes: {
        name: 'All',
        image: merchant?.image || 'https://res.cloudinary.com/ddu9hdf3d/image/upload/v1697121411/download_we4uz6.jpg',
        businessCategory: '',
        ondcMapping: '',
      },
    },
    {
      id: 0,
      attributes: {
        name: 'MISC',
        image: 'https://res.cloudinary.com/ddu9hdf3d/image/upload/v1697121411/download_we4uz6.jpg',
        businessCategory: '',
        ondcMapping: '',
      },
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState(defaultItemCategories[0]?.attributes.name);

  const { data, refetch, isLoading } = useGetMerchantItemsQuery(
    {
      search: searchQuery.trim().length > 0 && `filters[name][$containsi]=${searchQuery}`,
      filter: selectedCategory === defaultItemCategories[0].attributes.name ? `false` : selectedCategory === defaultItemCategories[1].attributes.name ? `filters[merchant_item_categories][name][$null]=true` : `filters[merchant_item_categories][name][$eq]=${selectedCategory}`,
      page: currentPage,
      merchantId: merchant?.id.toString(),
      token: localStorage.getItem('token'),
    },
    { skip: !shouldSkip },
  );
  // const order = useGetCartQuery({customer: user.id, token:localStorage.getItem("token")})
  const [dbCart, setDbCart] = useState();
  const [cartDeleted, setCartDeleted] = useState(false);
  useEffect(() => {
    if (location?.state?.refreshCart) {
      setCart(location.state.cart);
      setDbCart(location.state.dbCart);

      setOtherCartId(location.state.otherCartId);
      setIsOtherMerchant(location.state.isOtherMerchant);
    }
  }, [user, dbUser]);

  const [createCart] = useCreateCartMutation();
  const [updateCart] = useUpdateCartMutation();
  const [cart, setCart] = useState();
  const [items, setItems] = useState([]);
  const [showRemove, setShowRemove] = useState(false);

  const [deleteCart] = useDeleteCartMutation();
  const [userSubs, setUserSubs] = useState([]);
  const getUserSubscriptions = useGetSubscriptionItemsQuery({
    customer: user?.id,
    token: localStorage.getItem('token'),
  });
  const [userSubMerchant, setUserSubMerchant] = useState();
  const [customerItemSub, setCustomerItemSub] = useState();
  const [reloadData, setReloadData] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [searchTemp, setSearchTemp] = useState('');
  const [hasItems, setHasItems] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showBottom, setShowBottom] = useState(false);
  const [isOtherMerchant, setIsOtherMerchant] = useState(false);
  const [otherCartId, setOtherCartId] = useState();

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!location?.state?.cart && dbUser) {
      const headers = { Authorization: token ? `Bearer ${token}` : '' };
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/carts?filters[$and][0][customer][id][$eq]=${dbUser.id}&populate[merchant][populate]=*`, { headers: headers })
        .then((cd) => {
          if (cd.data.data[0].attributes.merchant?.data.id === merchant?.id) {
            setDbCart(cd.data.data[0]);
            setCart(cd.data.data[0].attributes.products);
          } else {
            setCart([]);
            setOtherCartId(cd.data.data[0].id);
            setIsOtherMerchant(true);
          }
        })
        .catch((e) => {
          setCart([]);
        });
    }
    //show dialog if already items in cart
  }, [dbUser]);
  useEffect(() => {
    if (!token) {
      setCart([]);
    }
  }, [token]);

  useEffect(() => {
    if (data) {
      setItems((item) => [...item, ...data.data]);
      setHasMore(false);
      setTotalPageCount(data.meta.pagination.pageCount);
      if (getUserSubscriptions.data) {
        if (getUserSubscriptions.data.data.length > 0) {
          if (getUserSubscriptions.data.data.filter((item) => item.attributes.merchant?.data.id === merchant?.id).length > 0) {
            setCustomerItemSub(getUserSubscriptions.data.data.filter((item) => item.attributes.merchant?.data.id === merchant?.id)[0]);
            setUserSubs(getUserSubscriptions.data.data.filter((item) => item.attributes.merchant?.data.id === merchant?.id)[0].attributes.itemIds);
            setUserSubMerchant(getUserSubscriptions.data.data.filter((item) => item.attributes.merchant?.data.id === merchant?.id)[0].attributes.merchant?.data.id);
          }
        }
      }
    } else {
      setShouldSkip(true);
    }
  }, [data, getUserSubscriptions.data]);
  const refetchMerchant = () => {
    setHasMore(true);
    setCurrentPage(currentPage + 1);
  };
  const handleScroll = () => {
    if (Math.ceil(window.innerHeight + document.documentElement.scrollTop) !== document.documentElement.offsetHeight || hasMore) {
      // console.log(window.innerHeight+document.documentElement.scrollTop)
      // console.log(document.documentElement.offsetHeight)
      return;
    }
    if (currentPage <= totalPageCount) {
      refetchMerchant();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore]);
  useEffect(() => {
    if (reloadData === true) {
      refetch()
        .then((v) => {
          getUserSubscriptions
            .refetch()
            .then((d) => {
              if (d.data.data.length > 0) {
                if (d.data.data.filter((item) => item.attributes.merchant?.data.id === merchant?.id).length > 0) {
                  setCustomerItemSub(d.data.data.filter((item) => item.attributes.merchant?.data.id == merchant?.id)[0]);
                  setUserSubs(d.data.data.filter((item) => item.attributes.merchant?.data.id == merchant?.id)[0].attributes.itemIds);
                  setUserSubMerchant(d.data.data.filter((item) => item.attributes.merchant?.data.id == merchant?.id)[0].attributes.merchant?.data.id);
                }
              }
              setItems(v.data.data);
              setRefetchLoading(false);
              setReloadData(false);
            })
            .catch((e) => {
              setItems(v.data.data);
              setRefetchLoading(false);
              setReloadData(false);
            });
        })
        .catch((e) => {});
    }
  }, [reloadData, dbUser]);

  const handleCartApi = (isBackNavigation) => {
    if (!dbUser) {
      return navigate(-1);
    }
    if (cartDeleted) {
      window.location.replace('/');
    } else {
      if (dbCart && cart.length > 0) {
        let total = 0;
        let discount = 0;
        let subtotal = 0;

        for (let index = 0; index < cart.length; index++) {
          const element = cart[index];
          subtotal += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 : element.price) * element.originalQuantity;
          total += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 - element.discount / 1000 : element.price - element.discount) * element.originalQuantity;
          discount += (element.unit === 'g' || element.unit === 'ml' ? element.discount / 1000 : element.discount) * element.originalQuantity;
        }
        const cartData = {
          id: dbCart.id,
          merchant: merchant?.id,
          customer: user?.id,
          products: cart,
          total: total,
          discount: discount,
          subtotal: subtotal,
        };
        updateCart({ cart: cartData, token: localStorage.getItem('token') })
          .unwrap()
          .then((d) => {
            window.history.back();
          })
          .catch((e) => {
            window.history.back();
          });
      } else if (dbCart && cart.length === 0) {
        deleteCart({ cart: dbCart, token: localStorage.getItem('token') })
          .unwrap()
          .then((c) => {
            window.history.back();
          })
          .catch((e) => {
            window.history.back();
          });
      } else if (!dbCart && cart?.length > 0) {
        let total = 0;
        let discount = 0;
        let subtotal = 0;

        for (let index = 0; index < cart.length; index++) {
          const element = cart[index];

          subtotal += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 : element.price) * element.originalQuantity;
          total += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 - element.discount / 1000 : element.price - element.discount) * element.originalQuantity;
          discount += (element.unit === 'g' || element.unit === 'ml' ? element.discount / 1000 : element.discount) * element.originalQuantity;
        }

        if (otherCartId && isOtherMerchant) {
          const cartData = {
            id: otherCartId,
            merchant: merchant?.id,
            customer: user?.id,
            products: cart,
            total: total,
            discount: discount,
            subtotal: subtotal,
          };
          updateCart({ cart: cartData, token: localStorage.getItem('token') })
            .unwrap()
            .then((d) => {
              window.history.back();
            })
            .catch((e) => {
              window.history.back();
            });
        } else {
          const order = {
            merchant: merchant?.id,
            customer: user?.id,
            products: cart,
            total: total,
            discount: discount,
            subtotal: subtotal,
          };
          createCart({ cart: order, token: localStorage.getItem('token') })
            .unwrap()
            .then((d) => {
              window.history.back();
            })
            .catch((e) => {
              window.history.back();
            });
        }
      } else {
        window.history.back();
      }
    }
  };
  const handleUpdateCart = () => {
    const cartData = {
      id: dbCart.id,
      merchant: merchant?.id,
      customer: user?.id ? user.id : null,
      products: [],
      total: 0,
      subtotal: 0,
      discount: 0,
    };
    updateCart({ cart: cartData, token: localStorage.getItem('token') })
      .unwrap()
      .then((d) => {
        setShowRemove(false);
      })
      .catch((e) => {
        alert('Something went wrong!');
      });
  };
  const handleSearch = (e, isButton) => {
    // if (e.target.value.length === 0) {
    //   setItems([])
    //   setSearchQuery(e.target.value)
    //   setCurrentPage(1)
    // }

    if (e.key === 'Enter') {
      if (e.target.value == searchQuery) {
        return;
      } else if (e.target.value.trim().length > 0) {
        setSearchQuery(e.target.value);
        setItems([]);
        setCurrentPage(1);
        setHasMore(true);
      } else if (searchQuery.trim().length > 0) {
        setSearchQuery('');
        setItems([]);
        setCurrentPage(1);
        setHasMore(true);
      }
    } else if (isButton) {
      if (e == searchQuery) {
        return;
      }
      if (e.trim().length > 0) {
        setSearchQuery(e);
        setItems([]);
        setCurrentPage(1);
        setHasMore(true);
      } else if (searchQuery.trim().length > 0) {
        setSearchQuery('');
        setItems([]);
        setCurrentPage(1);
        setHasMore(true);
      }
    }
  };

  const handleSetCart = async (item, quantity, unit) => {
    if (!dbUser) {
      navigate('/login', {
        state: { backNav: '/store-details', merchant: merchant },
      });
    }

    if (cartDeleted == true) {
      setCartDeleted(false);
    }

    let itemTemp = item.attributes;
    itemTemp = {
      ...itemTemp,
      quantity: quantity,
      originalQuantity: quantity,
      id: item.id,
      unit: unit,
    };

    const index = cart.findIndex((i) => i.id == item.id);

    if (quantity === 0) {
      if (index !== -1) {
        setCart((prevItems) => prevItems.filter((cart) => cart.id != item.id));
        //   if (cart.length === 1) {

        //    if (dbCart) {
        //     deleteCart({cart:  dbCart,token:localStorage.getItem("token")})
        //     .unwrap().then((c)=>{

        //      setCartDeleted(true)

        //     }).catch((e)=>{
        //       setCartDeleted(true)
        //     })

        //    }
        // }
      }
    } else {
      if (index === -1) {
        // Item does not exist in the array, so add it
        // setCartDeleted(false)
        setCart((prevItems) => prevItems.concat(itemTemp));
      } else {
        // Item exists in the array, so update it
        setCart((prevItems) =>
          prevItems.map((i) => {
            if (i.id == item.id) {
              return {
                ...i,
                quantity: quantity,
                originalQuantity: quantity,
                unit: unit,
              };
            }
            return i;
          }),
        );
      }
    }
  };
  const handleCall = () => {
    window.location.href = `tel:${merchant?.phone}`;
  };
  const handleDirection = () => {
    const googleMapsUrl = 'https://maps.google.com?q=' + merchant?.latitude + ',' + merchant?.longitude;
    const params = 'parameters for location selection';

    const openCapacitorSite = async () => {
      await Browser.open({ url: googleMapsUrl });
    };
    openCapacitorSite();
  };
  const handleShare = async ({ storeName, url }) => {
    const data = {
      title: `Share ${storeName}`,

      text: '',
      url: url,
    };

    if (navigator.canShare && navigator.canShare(data)) {
      try {
        await navigator.share(data);
        // console.log("Shared successfully");
      } catch (error) {
        alert('Share API not supported or unable to share');
      }
    } else {
      alert('Share API not supported or unable to share');
    }
  };
  const share = async () => {
    try {
      await Share.share({
        text: `${merchant?.name} is now available on ZunderMart!`,
        url: merchant?.shopLink,
        title: 'ZunderMart',
        dialogTitle: 'Share with friends',
      });
    } catch (error) {
      const shareData = {
        text: `${merchant?.name} is now available on ZunderMart!`,
        url: merchant?.shopLink,
        title: 'ZunderMart',
      };
      try {
        await navigator.share(shareData);
      } catch (error) {
        // alert("Share not available")
      }
    }
  };
  useEffect(() => {
    ons.ready(() => {
      ons.enableDeviceBackButtonHandler();
      ons.setDefaultDeviceBackButtonListener((ev) => {
        if (showBottom === true) {
          setShowBottom(false);
        } else {
          handleCartApi(true);
        }
      });
    });
    // BackH.addListener('backButton', ({canGoBack}) => {
    //   if(!canGoBack){
    //     alert("Cannt got")
    //     window.location.reload()
    //     window.history.back();
    //   } else {
    //     window.history.back();
    //   }
    // });
  }, [cart, showBottom]);

  const [itemCategories, setItemCategories] = useState([]);
  const [showMoreItemCategories, setShowMoreItemCategories] = useState(false);
  const [visibleCategoriesNo, setVisibleCategoriesNo] = useState(Math.ceil(window.innerWidth / 80) - 2);
  const [refetchLoading, setRefetchLoading] = useState(false);

  useEffect(()=>{
    setVisibleCategoriesNo(Math.ceil(window.innerWidth / 80) - 2)
    
      },[])
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/merchant-item-categories?filters[$and][0][merchant][id][$eq]=${merchant.id}&populate=*`, {
        headers: {
          Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
        },
      })
      .then((res) => {
        setItemCategories([defaultItemCategories[0], ...res?.data?.data, defaultItemCategories[1]]);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    const updateScreenWidth = () => {
      setVisibleCategoriesNo(Math.ceil(window.innerWidth / 80) - 2);
    };

    window.addEventListener('resize', updateScreenWidth);

    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    setItems([]);
    setCurrentPage(1);
    setReloadData(true);
  }, [selectedCategory]);

  const handleItemCategoryChange = (name) => {
    if (selectedCategory != name) {
      setSelectedCategory(name);
      setRefetchLoading(true);
    }
  };

  // for testing if found plz remove
  // useEffect(() => {
  //   console.log("!isLoading : " , isLoading)
  // } ,[isLoading])
  // useEffect(() => {
  //   console.log("refetchLoading : " , refetchLoading)
  // } ,[refetchLoading])
  // useEffect(() => {
  //   console.log("cart : " , cart)
  // } ,[cart])
  // useEffect(() => {
  //   console.log("items : " , items)
  // } ,[items])
  return (
    <div>
      <ContactBottomSheet setShowBottom={setShowBottom} showBottom={showBottom} setShowMap={setShowMap} showMap={showMap} contacts={merchant?.contacts && merchant?.contacts.length > 0 ? merchant?.contacts : [merchant?.phone]} />

      <div>
        <div className="bg-background-primary z-10  fixed  top-0 text-header-text w-full py-3 px-4 flex justify-start shadow-sm items-center gap-8 ">
          <div className="flex w-full justify-between items-center">
            <div className="flex items-center gap-4">
              <IoMdArrowBack
                onClick={() => {
                  handleCartApi(true);
                }}
                size={24}
              />
            </div>
            <div className="flex items-center gap-2 md:gap-12">
              <IoMdCall
                onClick={() => {
                  setShowBottom(true);
                }}
                style={{ cursor: 'pointer' }}
                size={24}
              />
              <IoMdPin
                onClick={() => {
                  handleDirection();
                }}
                style={{ cursor: 'pointer' }}
                size={24}
              />

              {merchant?.shopLink && (
                <IoMdShare
                  onClick={() => {
                    share();
                  }}
                  style={{ cursor: 'pointer' }}
                  size={24}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-14 md:mr-28 md:ml-28 bg-background-primary">
        <div className="flex justify-center text-6xl mt-6 rounded-xl p-6cursor-pointer">
          {showRemove && (
            <div className="fixed z-10 m-4 bg-background-secondary">
              <div className="bg-background-primary p-6 rounded-lg shadow-xl border-2">
                <div className="text-center">
                  <h2 className="text-lg font-semibold mb-2">Items In Cart</h2>
                  <p className="text-header-text mb-4 text-sm">You have items in your cart, do you want to continue with the order?</p>
                  <div className="flex justify-between">
                    <button
                      onClick={() => {
                        navigate(`/items/cart`, { state: { user: user } });
                      }}
                      className="bg-accent text-sm hover:bg-accent/75 text-sub-text font-semibold py-2 px-4 rounded"
                    >
                      YES
                    </button>
                    <button
                      onClick={() => {
                        handleUpdateCart();
                      }}
                      className="text-sm bg-alert hover:bg-alert/75 text-sub-text font-semibold py-2 px-4 rounded"
                    >
                      NO
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-between w-full mt-4 mr-4 ml-4">
            {/* <div className="p-4 h-80 w-full flex justify-center items-center">
         <img
            src={merchant?.image}
            className="rounded-lg h-full w-full"
          />
         </div> */}
            <div className="text-start ">
              <p className="text-lg font-bold text-header-text">{merchant?.name}</p>
              {merchant?.businessCategories.map((c, i) => {
                return (
                  <p key={c} className="text-xs mt-1 whitespace-nowrap font-semibold text-content-text capitalize">
                    &bull;{c}&nbsp;
                  </p>
                );
              })}
              <p className="text-xs mt-1 font-normal text-content-text line-clamp-2">{merchant?.geolocation} </p>
              <p className="text-xs font-normal text-content-text mt-1">{merchant?.distance && (merchant?.distance / 1000).toFixed(2) + 'km'}</p>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-2"></div>
            </div>
            <div className="text-end">
              {!merchant?.acceptingOrders && <p className="text-lg bg-background-primary py-4 px-2 border shadow-md  rounded-xl font-semibold text-alert-darker">CLOSED</p>}
              {merchant?.acceptingOrders && <p className="text-lg bg-background-primary py-4 px-2 border shadow-md rounded-xl font-semibold text-success">OPEN{''}</p>}
            </div>
            {/* <div className="flex items-stretch gap-1" role="group">
            <div className="container">
              <CallButton phone={merchant?.phone} />
            </div>
            <div className="container">
            <DirectionsButton lat={merchant?.place.geometry.location.lat} lng={merchant?.place.geometry.location.lng}/>
            </div>
            {merchant?.shopLink && <div className="container ">
              <ShareButton storeName={merchant?.name} url={merchant?.shopLink} />
            </div>}
          </div> */}
          </div>
        </div>

        <hr className="mt-4 border-dashed border-content-text" />

        <div className="flex items-center justify-between  w-full p-2 gap-2">
          {merchant?.deliveryRadius && merchant?.deliveryRadius > 0 && (
            <div className="flex items-center ">
              <HiTruck size={24} className="text-accent-darker" />
              <p className="text-xs font-semibold text-accent-darker">Delivering upto {merchant?.deliveryRadius}km</p>
            </div>
          )}

          {showClosedDayTime ? (
            <div className="flex items-center gap-1 text-content-text">
              <IoMdTime size={16} />
              <p className="text-xs sm:text-sm whitespace-nowrap font-semibold text-content-text">
                {merchant?.timeOpen.split(':')[0]}:{merchant?.timeOpen.split(':')[1]} - {showClosedDayTime?.closeTime.split(':')[0]}:{showClosedDayTime?.closeTime.split(':')[1]}
                <br />
                {showClosedDayTime?.openTime.split(':')[0]}:{showClosedDayTime?.openTime.split(':')[1]} - {merchant?.timeClosed.split(':')[0]}:{merchant?.timeClosed.split(':')[1]}
              </p>
            </div>
          ) : (
            <div className="flex items-center gap-1 text-content-text">
              <IoMdTime size={16} />
              <p className="text-sm whitespace-nowrap font-semibold text-content-text">
                {merchant?.timeOpen.split(':')[0]}:{merchant?.timeOpen.split(':')[1]} - {merchant?.timeClosed.split(':')[0]}:{merchant?.timeClosed.split(':')[1]}
              </p>
            </div>
          )}
        </div>

        <hr className="border-dashed border-content-text" />

        <div className="items-center w-full justify-center flex flex-col p-2">
          <div className="w-full items-center shadow-sm border-content-text  md:w-2/4 gap-2 bg-background-primary border px-4 py-2 rounded-lg mt-4  flex justify-between">
            <div className="w-full items-center gap-2 bg-background-primary flex justify-start">
              <FaSearch />
              <input
                onChange={(e) => {
                  if (e.target.value.trim().length > 0) {
                    setSearchTemp(e.target.value);
                  } else if (searchTemp.trim().length === 1 && searchQuery.length > 0) {
                    setSearchQuery('');
                    setSearchTemp('');
                    setItems([]);
                    setCurrentPage(1);
                    setHasMore(true);
                  }
                }}
                onKeyDown={(e) => {
                  handleSearch(e, false);
                }}
                type="text"
                placeholder="Search items"
                className="focus:outline-none w-full bg-background-primary"
              />
            </div>
            <button
              className="text-primary font-bold underline cursor-pointer"
              onClick={() => {
                handleSearch(searchTemp, true);
              }}
            >
              Search
            </button>
          </div>

          {itemCategories.length > 2 && (
            <div className={`relative w-full px-1 pt-4`}>
              <div className={`transition duration-700 ease-in-out delay-150 flex gap-1 py-1 overflow-hidden ${showMoreItemCategories && 'flex flex-wrap'}`}>
                {itemCategories.map((category, idx) => (
                  <div
                    className={`min-w-[80px] max-w-[80px] flex flex-col gap-1 sm:gap-1.5 items-center rounded-md p-1 cursor-pointer  ${selectedCategory === category?.attributes.name && 'scale-110'}`}
                    onClick={() => {
                      handleItemCategoryChange(category?.attributes.name);
                    }}
                    key={idx + 1}
                  >
                    <div className={`aspect-video max-h-[3rem] overflow-hidden rounded-md  ${selectedCategory === category?.attributes.name && 'shadow-md'}`}>
                      <img src={category?.attributes?.image || 'https://res.cloudinary.com/ddu9hdf3d/image/upload/v1697121411/download_we4uz6.jpg'} alt={category?.attributes.name} className="object-cover w-full h-full rounded-md" />
                    </div>
                    <h4 className={`w-full capitalize text-xs font-semibold text-center line-clamp-2 break-normal ${selectedCategory === category?.attributes.name && 'text-accent'}`}>{category?.attributes.name}</h4>
                  </div>
                ))}
              </div>

              <div className="flex justify-between absolute w-full top-0 pr-4">
                <h4 className="text-sm font-bold">Categories</h4>
                {itemCategories.length > visibleCategoriesNo && (
                  <button
                    className="text-xs text-primary font-semibold "
                    onClick={() => {
                      setShowMoreItemCategories(!showMoreItemCategories);
                    }}
                  >
                    {showMoreItemCategories ? 'Show less' : 'Show more'}
                  </button>
                )}
              </div>
            </div>
          )}

          <div className="flex justify-between absolute w-full top-0 pr-4">
            <h4 className="text-sm font-bold">Categories</h4>
            {itemCategories.length > visibleCategoriesNo && (
              <button
                className="text-xs text-accent-darker font-semibold "
                onClick={() => {
                  setShowMoreItemCategories(!showMoreItemCategories);
                }}
              >
                {showMoreItemCategories ? 'Show less' : 'Show more'}
              </button>
            )}
          </div>
        </div>

        <div className=" mb-20 w-full m-2 flex flex-col gap-4">
          {merchant?.merchant_offers && merchant?.merchant_offers.length > 0 && (
            <div className="flex flex-col items-start font-bold">
              <p className="p-1 text-sm">Available Offers</p>

              <div className="flex p-1 items-center gap-4 overflow-x-auto scrollbar-hide w-full">
                {merchant?.merchant_offers &&
                  merchant?.merchant_offers.length > 0 &&
                  merchant?.merchant_offers.map((mo) => {
                    return (
                      <div className={`whitespace-nowrap gap-2 items-center flex py-1.5 border text-center text-sm text-header-text px-4 rounded-md shadow-md`}>
                        {mo.isPercent === true && (
                          <div className="text-primary flex items-center">
                            <p key={mo.id} className=" text-sm font-semibold">
                              {mo.percent}
                            </p>
                            <FaPercent />
                          </div>
                        )}
                        {mo.isPercent === false && (
                          <div className="flex text-primary items-center">
                            <FaRupeeSign />
                            <p key={mo.id} className="text-sm font-semibold">
                              {mo.amount}
                            </p>
                          </div>
                        )}
                        <div className="flex flex-col items-start text-header-text">
                          <p key={mo.id} className="text-header-text flex items-center text-sm font-semibold">
                            OFF UPTO&nbsp;
                            <FaRupeeSign />
                            {mo.maxAmount}
                          </p>
                          <div className="flex gap-1 items-center">
                            <p key={mo} className="text-header-text text-xs font-normal">
                              {mo.title}
                            </p>
                            <div className="w-1 border-l-2 h-3" />
                            <p key={mo} className="text-header-text text-xs flex items-center font-normal">
                              Above&nbsp;
                              <FaRupeeSign />
                              {mo.minimum}
                            </p>
                          </div>
                          <hr />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {merchant?.merchant_offers && merchant?.merchant_offers.data && merchant?.merchant_offers.data.length > 0 && (
            <div className="flex flex-col items-start font-bold">
              <p className="p-1 text-sm">Available Offers</p>

              <div className="flex p-1 items-center gap-4 overflow-x-auto scrollbar-hide w-full">
                {merchant?.merchant_offers &&
                  merchant?.merchant_offers.data &&
                  merchant?.merchant_offers.data.length > 0 &&
                  merchant?.merchant_offers.data.map((mo) => {
                    return (
                      <div key={mo.id} className={`whitespace-nowrap gap-2 items-center flex py-1.5 border text-center text-sm text-header-text px-4 rounded-md shadow-md`}>
                        {mo.attributes.isPercent === true && (
                          <div className="text-primary flex items-center">
                            <p className=" text-sm font-semibold">{mo.attributes.percent}</p>
                            <FaPercent />
                          </div>
                        )}
                        {mo.attributes.isPercent === false && (
                          <div className="flex text-primary items-center">
                            <FaRupeeSign />
                            <p key={mo.attributes.id} className="text-sm font-semibold">
                              {mo.attributes.amount}
                            </p>
                          </div>
                        )}
                        <div className="flex flex-col items-start text-header-text">
                          <p key={mo.id} className="text-header-text flex items-center text-sm font-semibold">
                            OFF UPTO&nbsp;
                            <FaRupeeSign />
                            {mo.attributes.maxAmount}
                          </p>
                          <div className="flex gap-1 items-center">
                            <p key={mo} className="text-header-text text-xs font-normal">
                              {mo.attributes.title}
                            </p>
                            <div className="w-1 border-l-2 h-3" />
                            <p key={mo.id} className="text-header-text text-xs flex items-center font-normal">
                              Above&nbsp;
                              <FaRupeeSign />
                              {mo.attributes.minimum}
                            </p>
                          </div>
                          <hr />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {!hasMore && !isLoading && !refetchLoading && items && items.length === 0 && (
            <div className="p-4">
              No Item Found. Please contact
              <br />
              <span className="font-bold text-sm">{merchant?.name}</span> for further details!
            </div>
          )}
          {/* {
             !isLoading && cart.length === 0 && items && items.filter((i)=>i.attributes.name && i.attributes.name.trim().toLowerCase().includes(searchQuery.trim().toLowerCase())).map((m, i) => {
                return <ItemCard setReloadData={setReloadData} reloadData={reloadData} customerItemSub={customerItemSub} user={user} userSubMerchant={userSubMerchant} userSubs={userSubs} merchant={merchant} cart={cart.filter((c) => c.id == m.id).length > 0 ? cart.filter((c) => c.id == m.id)[0] : 0} handleAddProduct={handleSetCart} key={m.id} product={m} />
              })
            }  */}
          {!isLoading &&
            !refetchLoading &&
            cart &&
            items &&
            items
              .filter((i) => i.attributes.name && i.attributes.name.trim().toLowerCase().includes(searchQuery.trim().toLowerCase()))
              .map((m, i) => {
                return <ItemCard setReloadData={setReloadData} reloadData={reloadData} customerItemSub={customerItemSub} user={dbUser} userSubMerchant={userSubMerchant} userSubs={userSubs} merchant={merchant} cart={cart.filter((c) => c.id == m.id).length > 0 ? cart.filter((c) => c.id == m.id)[0] : 0} handleAddProduct={handleSetCart} key={m.id} product={m} />;
              })}

          {(isLoading || hasMore || refetchLoading || !cart) && (
            <div className="p-4 flex flex-col w-full items-center justify-center mt-1 gap-4">
              {' '}
              <div role="status" className="flex items-center justify-center h-8 w-8">
                <svg className="w-24 h-24 mr-2 text-sub-text animate-spin dark:text-content-text fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between">
        <BrowserView>{/* <HomeFloatingButton /> */}</BrowserView>

        {cart && <FloatingButton merchant={merchant} cart={cart} isOtherMerchant={isOtherMerchant} otherCartId={otherCartId} dbCart={dbCart} user={dbUser} handleCartApi={handleCartApi} />}
      </div>

      {cartDeleted === false && cart && cart.length > 0 && <CartBanner isOtherMerchant={isOtherMerchant} otherCartId={otherCartId} dbCart={dbCart} merchant={merchant} user={dbUser} handleCartApi={handleCartApi} cart={cart} />}
    </div>
  );
}
