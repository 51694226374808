import React, { useEffect, useState } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import 'firebase/auth';

import 'react-phone-number-input/style.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import PhoneInput from 'react-phone-number-input';
import OTPInput from 'react-otp-input';
import { useCreateUserMutation, useUpdateUserStatusMutation } from '../../state/api';
import { FaUserEdit, FaEdit } from 'react-icons/fa';
import { IoMdArrowBack } from 'react-icons/io';

const Login = ({ activeMenu, setActiveMenu, dbUser, setDbUser, setIsBlocked }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [loading, setLoading] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [verifyCaptcha, setVerifyCaptcha] = useState(true);
  const [resendOtp, setResendOtp] = useState(false);
  const [otp, setOtp] = useState();
  const [otpResult, setOtpResult] = useState();

  const [seconds, setSeconds] = useState(60);
  const [updateUser] = useUpdateUserStatusMutation();
  const [createUser] = useCreateUserMutation();
  const [tempUser, setTempUser] = useState();

  const router = useNavigate();
  const location = useLocation();
  const navigate = useNavigate();

  const user = auth.currentUser;
  // useEffect(()=>{
  //  if (user) {
  //   if (user.attributes.accepted === true) {
  //     router.push('/')
  // } else if (user.attributes) {
  //   router.push('/request-pending')
  // }
  //  }
  // },[user])

  React.useEffect(() => {
    if (window) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response) => {},
          'expired-callback': () => {
            setVerifyCaptcha(true);
          },
        },
        auth,
      );
    }
  }, []);

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (phoneNumber.trim().length === 0) {
      toast.error('Please enter the phone number!');
    } else {
      setLoading(true);
      const appVerifier = window.recaptchaVerifier;
      if (seconds <= 0) {
        setSeconds(60);
        setResendOtp(false);
      }
      try {
        const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber.trim(), appVerifier);
        setVerificationId(confirmationResult.verificationId);

        setShowOtpInput(true);
        setLoading(false);
        setOtpResult(confirmationResult);
      } catch (error) {
        setLoading(false);
        toast.error('unable to send verification code.');
      }
    }
  };

  React.useEffect(() => {
    showOtpInput && seconds > 0 && setTimeout(() => setSeconds(seconds - 1), 1000);
    if (seconds - 1 <= 0) {
      setVerifyCaptcha(true);
      setResendOtp(true);
    }
  }, [seconds, showOtpInput]);

  const logout = async () => {
    if (user) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/auth/unsubscribe-from-topic`,
          {
            topic: `${user.phoneNumber.replace('+', '')}`,
            token: localStorage.getItem('fcmToken'),
          },
          { headers: { Authorization: '' } },
        )
        .then((resp) => {
          console.log('unregistered from previous topic');
        })
        .catch((error) => {
          console.log('error ', error.message);
        });
    }

    auth
      .signOut()
      .then(() => {
        localStorage.removeItem('token');
        setDbUser();
      })
      .catch((e) => {});
  };

  const handleVerifyCode = async () => {
    try {
      setLoading(true);
      otpResult
        .confirm(otp)
        .then(async (d) => {
          const body = {
            phone: d.user.phoneNumber,
            code: otp,
            role: Number.parseInt(process.env.REACT_APP_DEFAULT_ROLE),
          };
          const verifyOtp = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/phone-verify`, body);
          if (verifyOtp.data.token) {
            const user = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/customers?filters[phone][$eq]=${phoneNumber.replace('+', '%2b').trim()}`, { headers: { Authorization: `Bearer ${verifyOtp.data.token}` } });
            if (user.data.data.length > 0) {
              if (user.data.data[0].attributes.active) {
                localStorage.setItem('token', verifyOtp.data.token);
                // localStorage.setItem("user", JSON.stringify(user.data.data[0]))
                // toast.success('Login Success!')
                setDbUser(user.data.data[0]);
                if (location?.state?.backNav === '/store-details' || location?.state?.backNav === '/item/detail') {
                  navigate(-1, { state: { merchant: location?.state?.merchant, user: user.data.data[0] }, replace: true });
                } else if (location?.state?.backNav) {
                  setActiveMenu(location?.state?.backNav);
                  navigate(`/${location?.state?.backNav}`, { state: { user: user.data.data[0] }, replace: true });
                } else {
                  navigate('/home', { state: { user: user.data.data[0] }, replace: true });
                  window.location.reload();
                }
              } else {
                // router.replace('/request-pending')
                //update user status and navigate home

                let data = { active: true, id: user.data.data[0].id };

                updateUser({ userData: data, token: verifyOtp.data.token })
                  .unwrap()
                  .then((d) => {
                    localStorage.setItem('user', JSON.stringify(d.data));
                    localStorage.setItem('token', verifyOtp.data.token);
                    setDbUser(user.data.data[0]);
                    if (location?.state?.backNav === '/store-details' || location?.state?.backNav === '/item/detail') {
                      navigate(-1, { state: { merchant: location?.state?.merchant, user: user.data.data[0] }, replace: true });
                    } else if (location?.state?.backNav) {
                      setActiveMenu(location?.state?.backNav);
                      navigate(`/${location?.state?.backNav}`, { state: { user: user.data.data[0] }, replace: true });
                    } else {
                      navigate('/home', { state: { user: user.data.data[0] }, replace: true });
                      window.location.reload();
                    }
                    // toast.success('Login Success!')
                    // window.location.replace('/')
                  })
                  .catch((e) => {
                    setLoading(false);
                    toast.error(e.message);
                  });
              }
            } else {
              //   router.replace('/auth/registration')
              //Add user to db and replace with
              let userCreate = { name: '', address: '', phone: phoneNumber, active: true, savings: 0 };
              createUser({ userData: userCreate, token: verifyOtp.data.token })
                .unwrap()
                .then((d) => {
                  localStorage.setItem('token', verifyOtp.data.token);
                  localStorage.setItem('user', JSON.stringify(d.data));
                  setDbUser(d.data);
                  if (location?.state?.backNav === '/store-details' || location?.state?.backNav === '/item/detail') {
                    navigate(-1, { state: { merchant: location?.state?.merchant, user: d.data }, replace: true });
                  } else if (location?.state?.backNav) {
                    setActiveMenu(location?.state?.backNav);
                    navigate(`/${location?.state?.backNav}`, { state: { user: d.data }, replace: true });
                  } else {
                    navigate('/home', { state: { user: d.data }, replace: true });
                    window.location.reload();
                  }
                })
                .catch((e) => {
                  setLoading(false);
                  toast.error(e.message);
                  logout();
                });
            }
          } else {
            setLoading(false);
            logout();
            toast.error('Failed to verify OTP!');
          }
        })
        .catch((e) => {
          setLoading(false);
          logout();
          if (e.response?.status === 401) {
            setIsBlocked(true);
            return toast.error('Your account has been blocked!');
          }
          toast.error('Failed to verify OTP!');
          console.log(e);
        });
    } catch (error) {
      setLoading(false);
      logout();
      toast.error('Failed to verify OTP!');
      console.log(error);
    }
  };
  useEffect(() => {
    if (user && !localStorage.getItem('token')) {
      setDbUser();
      logout();
    } else if (user) {
      navigate('/home', { replace: true });
    }
  }, []);

  useEffect(() => {
    if (tempUser) {
      setDbUser(tempUser);
    }
  }, [tempUser]);

  return (
    <div className="flex flex-col items-center justify-top p-4 min-h-screen bg-background-primary">
      {/* <ToastContainer position="top-center" autoClose={3000} hideProgressBar={true} containerId="toast" /> */}
      <div className="bg-background-primary text-sub-text py-3 px-4 z-50 fixed top-0 w-full flex justify-between items-center shadow-sm">
        <div className="flex items-center gap-2 ">
          <IoMdArrowBack
            onClick={() => {
              navigate(-1);
            }}
            size={24}
            className="fill-header-text"
          />
          <span className="text-xl font-extrabold">
            <span className="text-header-text">{process.env.REACT_APP_WHITELISTED_NAME}</span>
          </span>
        </div>
      </div>
      <h1 className="text-md font-bold mt-14 text-start md:text-center w-full">{!showOtpInput ? 'Phone Verification' : 'OTP Verification'}</h1>
      {/* <span className='text-sm mt-1 text-content-text text-start md:text-center w-full'>{!showOtpInput ? "Please verify your phone number before proceeding" : `Please enter the otp sent to your phone number ${phoneNumber.trim()}! `}</span> */}
      <span className="text-sm mt-1 text-content-text text-start md:text-center w-full">
        {!showOtpInput ? (
          'Please verify your phone number before proceeding'
        ) : (
          <>
            <p className="text-sm mt-1 text-content-text  ">
              Please enter the OTP sent to your phone number! <strong className="font-bold text-lg">{phoneNumber.trim()} </strong>
              <strong
                onClick={() => {
                  setShowOtpInput(false);
                }}
                className="font-bold text-primary-darker cursor-pointer inline-flex items-center"
                style={{ transition: 'color 0.3s' }}
              >
                <FaEdit size={20} />
              </strong>
              {/* <span className="">
     <strong className="font-bold text-lg">{phoneNumber.trim()} </strong>
     <strong
       onClick={() => { 
         console.log("first");
         setShowOtpInput(false);
       }}
       className="font-bold text-primary-darker cursor-pointer inline-flex items-center"
       style={{ transition: 'color 0.3s' }}
     >
       <FaEdit size={20} />
     </strong>
   </span> */}
            </p>
          </>
        )}
      </span>
      <form className="flex flex-col items-center m-4 w-full justify-center" onSubmit={handleSignIn}>
        <div className="mb-4 flex w-full md:w-2/4">
          {!showOtpInput && (
            <div className="mb-4 flex flex-col w-full">
              <h1 className="text-sm font-bold mt-2 text-start w-full">Phone Number</h1>
              <PhoneInput
                style={{ padding: '12px', marginTop: '8px', border: 'none', outline: 'none' }}
                className={'input-phone-number w-full  bg-background-secondary rounded-md focus:outline-0'}
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={(v) => {
                  setPhoneNumber(v);
                }}
                defaultCountry="IN"
                addInternationalOption={false}
                countries={['IN']}
                countryCallingCodeEditable={false}
                numberInputProps={{
                  className: 'rounded-md px-4 focus:outline-none bg-background-secondary',
                }}
                inputProps={{
                  required: true,
                  autoFocus: false,
                }}
              />
              <span className="text-xs mt-3 text-content-text text-center w-full">By tapping "Verify Phone Number", an SMS may be sent. Message & data rates may apply.</span>
            </div>
          )}
          {/* <input
          type="text"
          className=" w-2/4 p-2 rounded-md shadow-sm"
          placeholder="Enter Mobile Number"
          {...register('phone', { required: true })}
        /> */}
        </div>
        {/* <span className='text-xs mt-2 text-content-text text-center w-full'>By tapping "Verify Phone Number", an SMS may be sent. Message & data rates may apply.</span> */}
        {!showOtpInput && !loading && (
          <button type="submit" className="bg-primary w-full mt-6 md:w-1/4 hover:bg-primary-darker text-sub-text font-bold py-2 px-4 rounded-md shadow-md">
            Verify Phone Number
          </button>
        )}
        {
          showOtpInput && (
            <div className="md:w-1/3 w-full">
              <OTPInput
                inputType="tel"
                inputStyle={{
                  width: '100%',
                  border: 'solid 1px gray',
                  padding: '8px',
                  margin: '8px',
                  borderRadius: '4px',
                }}
                value={otp}
                onChange={setOtp}
                renderInput={(props) => <input {...props} />}
                numInputs={6}
              />
            </div>
          )
          //   <input
          //   type="tel"
          //   maxLength={6}
          //   min={0}
          //   className="w-full md:w-2/4 p-3 bg-background-secondary rounded-md shadow-sm outline-none"
          //   placeholder="Enter 6 digit Otp"
          //   onChange={(e)=>{setOtp(e.target.value)}}
          // />
        }
        {showOtpInput && !resendOtp && <span className="text-sm mt-2 text-content-text text-start md:text-center w-full">Resend OTP in: {seconds}</span>}
        {showOtpInput && resendOtp && (
          <button type="submit" className="text-sm mt-2 text-start md:text-center font-bold w-full text-accent mb-4 underline">
            Resend OTP
          </button>
        )}
        {verifyCaptcha && <div id="recaptcha-container" className="bottom-4 right-4 left-4 m-auto"></div>}
        {/* {!showOtpInput && resendOtp && <div id='recaptcha-container-resend' className='bottom-4 right-4 left-4 m-auto'></div>} */}
        {showOtpInput && !loading && (
          <button onClick={handleVerifyCode} type="button" className="bg-primary mt-6 w-full md:w-1/4 hover:bg-primary/75 text-sub-text font-bold py-2 px-4 rounded-md shadow-md">
            Verify OTP
          </button>
        )}
        {loading && (
          <div role="status">
            <svg aria-hidden="true" className="w-8 h-8 mr-2 text-sub-text animate-spin dark:text-content-text fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg>
            {/* <span className="sr-only">Loading...</span> */}
          </div>
        )}
      </form>
    </div>
  );
};

export default Login;
