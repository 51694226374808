import React from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

export default function ShimmerMerchantCards(){
    return (
        <div className={`mb-24   grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 p-4 items-center justify-center w-full gap-6`}>
            <ShimmerThumbnail height={150} rounded />
            <ShimmerThumbnail height={150} rounded />
            <ShimmerThumbnail height={150} rounded />
            <ShimmerThumbnail height={150} rounded />
            
        </div>
    )
}