import { useState,useEffect } from "react"
import { FaShoppingCart } from "react-icons/fa"
import { HiArchiveBoxXMark } from "react-icons/hi2"
import { IoMdRemove } from "react-icons/io"

export default function CartItemCard({product, handleSetCart, removeProduct}){
  
  const [quantity, setQuantity] = useState(product.originalQuantity)
 

     useEffect(() => {
      setQuantity(product?.originalQuantity);
    }, [product.originalQuantity]);

    return (
        <div key={product.id} className=" mt-2 bg-background-primary border">
        <li className="flex p-3">
          <div className="">
           {product.image && <img
              src={product.image}
              alt={product.name}
              className="h-24 w-32 rounded-md object-cover object-center"
            />}
            {
                !product.image && <img
                src="/placeholder.jpg"
                alt={product.name}
                className="h-32 w-32 rounded-md object-cover object-center "
              />
            }
          </div>
          <div className="flex w-full justify-between flex-col md:flex-row">

          
          <div className="ml-4 flex flex-col justify-between sm:ml-6">
            <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
              <div className="text-start">
                <div className="flex justify-between">
                  <h3 className="text-sm break-all w-full text-header-text font-bold">
                  {product.name}
                  </h3>
                </div>
                
                <div className="mt-1 flex items-end">
                  {product.discount !== 0 && <p className="text-xs line-through font-medium text-content-text">
                  ₹{product.unit === "g" || product.unit === "ml" ? (product.price/1000 ).toFixed(3): product.price}
                  </p>}
                  <p className="text-sm font-medium text-header-text">
                  ₹{product.unit === "g" || product.unit === "ml" ? (product.price/1000).toFixed(3) - (product.discount/1000).toFixed(3) : product.price - product.discount}
                  </p>
                </div>
                <p className="text-sm font-medium text-secondary">
               { product.discount !== 0 ?  `Saved: ₹${product.discount}` : ''}
                  </p>
                  <p className="text-sm mt-2 font-medium text-content-text">
                   {product.unit}
                  </p>
                  {/* <p className={`text-sm mt-2 font-medium ${product.availability === true ? "text-secondary" : "text-alert"}`}>
                    {product.availability === true ? "In Stock" : "Out Of Stock"}
                  </p> */}
              </div>
            </div>
          </div>
          <div className="flex mb-2 gap-2">
          <div className="flex min-w-24  gap-2 ml-4 mt-4 md:mt-0 md:ml-0 items-center">
            <HiArchiveBoxXMark onClick={()=>{removeProduct(product)}} size={24} className="text-xl fill-primary"/>
            <div className="flex min-w-24 bg-secondary font-bold p-0.5 text-sub-text rounded-md shadow-md gap-1 items-center">
            <button
             onClick={(e) => {
             
               quantity === 0 ? setQuantity(0) : setQuantity(quantity-1)
               quantity === 0 ?  handleSetCart(product, 0) :  handleSetCart(product, quantity - 1)
              }}
              type="button"
              className="h-7 w-7  "
            >
              -
            </button>
            <input
              type="text"
              className="h-7 w-9 text-center bg-secondary focus:outline-none text-sub-text"
              min={0}
              value={quantity}
              inputMode="numeric" 
              pattern="[0-9]*" 
              onChange={(e) => {
                if (e.target.value.trim().length === 0) {
                  setQuantity(0)
                  handleSetCart(product,0)

                }
                else if(typeof e.target.value !== NaN) {
                  if (/^[0-9]*$/.test(e.target.value)) {
                    setQuantity(Number.parseInt(e.target.value) < 0 ? 0 : Number.parseInt(e.target.value))
                    handleSetCart(product,Number.parseInt(e.target.value) < 0 ? 0 : Number.parseInt(e.target.value))
  
                }
                } 
              }}
            />
            <button
            onClick={(e) => {
              setQuantity(quantity+1)
              handleSetCart(product, quantity+1)
            }}
              type="button"
              className="h-7 w-7  flex justify-center items-center"
            >
              +
            </button>
          </div>
          </div>
        </div>
        </div>
        </li>
       
      </div>
    )
}