import moment from "moment";
import { useEffect, useState } from "react";
import { FaStore } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer, Slide, Zoom } from "react-toastify";

export default function OrderCard({order}){
  const navigate = useNavigate()
  const handleOrderClick = () =>{
    navigate('/orders/detail', {state:{order:order}})
  }
  const handleCall = () => {
    window.location.href = `tel:${order.attributes.phoneMerchant}`;
  };

  const productNames = order.attributes.products.map((p)=>{
    return p.name
  })

  

  const [refreshData, setRefreshData] = useState(false);


    return (
        <div onClick={()=>{handleOrderClick()}} className="flex justify-center shadow-lg border rounded-lg p-2 bg-background-primary ">
                {/* <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
      
        rtl={false}
        pauseOnFocusLoss
        closeOnClick={true} 
          draggable={true}
        pauseOnHover
        theme="colored"
        transition={Zoom}
      /> */}
                <div className="flex flex-col w-full space-y-2">
                  <div className=" text-yellow-500 flex gap-2 justify-between self-start tracking-wide text-sm  font-semibold">
                  <div className="w-28 h-20">
                  <LazyLoadImage
                    placeholder={<FaStore className="items-center flex justify-center"/>}
                    src={order.attributes.merchant.data.attributes.image.replace("/upload/","/upload/w_auto/")} className="w-full h-full object-cover rounded-md"/>

                    </div>               
                  <div className="text-start w-full">
                    <h3 className=" text-md font-bold line-clamp-1 w-full text-header-text">{order.attributes.merchant.data.attributes.name}</h3>
                    <p className="text-xs line-clamp-1 mb-2 font-semibold text-content-text">{order.attributes.merchant.data.attributes.geolocation}</p>

                   {/* <p className="text-sm line-clamp-1 mb-2 font-semibold text-content-text">{order.attributes.merchant.data.attributes.phone}</p> */}
                  
                   
                    <span className={` px-1 rounded-md py-1 text-sm mt-2 font-bold ${order.attributes.status === "Pending" ? "text-yellow-500 bg-yellow-200" : order.attributes.status === "Packaging" ? "text-blue-500 bg-blue-200" : order.attributes.status === "Ready" ? "text-purple-600 bg-purple-200" : order.attributes.status === "Delivered" ? "text-green-500 bg-success-lighter" : order.attributes.status === "Cancelled" || order.attributes.status === "User-Cancelled" ? "text-red-500 bg-red-200" :  "text-primary bg-primary-lighter"}`}>{order.attributes.status === "User-Cancelled" ? "Cancelled" : order.attributes.status}</span>
                  </div>
                  </div>
                  <hr/>
                  {/* <p className=" text-header-text  justify self-start tracking-wide text-sm  font-bold">
                    Order number: {order.id}
                  </p> */}
                 
                
                  <div className="flex gap-2 items-start">
                  <p className="text-start text-header-text justify self-start tracking-wide text-sm  font-semibold">
                     Items: 
                    </p>
                    
                     <p className="text-start w-full line-clamp-1 text-header-text justify text-sm  font-semibold">
                     {productNames.join(", ")}
                    </p>
                 
                  
                   </div>
                
                  {/* <p className=" text-content-text font-semibold justify self-start tracking-wide text-sm  ">
                    Purchase method : {order.attributes.purchaseMethod}
                  </p> */}

                  <p className=" text-content-text justify self-start tracking-wide text-sm  font-semibold">
                   Date: {moment(order.attributes.date).format("HH:mm DD/MM/YY")}
                  </p>
                  <div className="flex justify-between">
                { order.attributes.discount > 0 &&   <p className=" text-secondary justify self-start tracking-wide text-sm  font-bold">
                   Discount: {order.attributes.discount}
                  </p> 
                  }
                  <p className=" text-header-text justify self-start tracking-wide text-sm  font-bold">
                    Total: ₹{order.attributes.total-order.attributes.discount}
                  </p>
                  </div>
                 <hr/>
                  <div className="inline-flex gap-2 justify-center ">
                    {/* <button
                      onClick={()=>{handleCall()}}
                      className="w-full flex rounded-md items-center justify-center py-2
  uppercase bg-background-primary text-sm  text-primary font-bold  rounded-l border"
                    >
                      Call
                    </button> */}
                    {/* <button  className="w-full items-center justify-center p-4 flex rounded-md border uppercas text-sm   text-primary font-bold py-2 px-4">
                      View Details
                    </button> */}
                  </div>
                </div>
              </div>
    )
}