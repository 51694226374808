import React, { use, useEffect, useState } from 'react';
import { useUpdateUserStatusMutation } from '../../state/api';
import { HiCurrencyRupee, HiPencil } from 'react-icons/hi2';
import { FaCross, FaRupeeSign, FaTimes } from 'react-icons/fa';
import ons from 'onsenui';

import { FaUserEdit, FaEdit } from 'react-icons/fa';
import { BiSolidEdit } from 'react-icons/bi';
const ProfilePage = ({ showMap, setShowMap, user, edit, setEdit }) => {
  const [name, setName] = useState(user.attributes.name);

  const [enableUpdate, setEnableUpdate] = useState(false);
  // const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState(user.attributes.address);
  const [updateUser] = useUpdateUserStatusMutation();
  const [tempAddress, setTempAdress] = useState('');
  const [tempName, setTempName] = useState(user.attributes.name);
  const [loading, setLoading] = useState(false);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Perform form submission or API request to update user information
    // You can update the state or show a success message here
  };

  const handleUpdateUser = () => {
    if (tempName.trim().length > 0) {
      setLoading(true);
      let data = { name: tempName, id: user.id, address: tempAddress };
      updateUser({ userData: data, token: localStorage.getItem('token') })
        .unwrap()
        .then((d) => {
          setAddress(d.data.attributes.address);
          setName(d.data.attributes.name);
          setEdit(false);
          setShowMap(false);
          setLoading(false);
          window.location.reload();
        })
        .catch((e) => {
          alert(e.message);
          setLoading(false);
        });
    } else {
      alert('Enter valid name');
    }
  };

  useEffect(() => {
    ons.ready(() => {
      ons.enableDeviceBackButtonHandler();

      ons.setDefaultDeviceBackButtonListener((ev) => {
        if (edit === true) {
          setShowMap(false);
          setEdit(false);
        } else {
          window.location.replace('/home');
        }
      });
    });
  }, [edit]);

  return (
    <div className=" scrollbar-hide">
      <div className="md:bg-background-primary w-full text-start">
        {/* <h2 className="text-2xl font-bold mb-4">Profile</h2> */}
        {/* <p className="text-lg font-semibold text-primary">Profile Info</p> */}
        {/* User Information */}

        <div className="mb-6 ml-2 mr-2 mt-6 shadow-lg border rounded-md p-2">
          <div
            onClick={() => {
              setShowMap(true);
              setEdit(true);
            }}
            className="flex items-center mb-2 w-full justify-between cursor-pointer"
          >
            {/* <span className=" mr-2">Name:</span> */}
            <p className="text-md text-header-text font-semibold">{name}</p>

            <p className="text-sm text-primary font-semibold cursor-pointer underline ">
              <FaEdit size={25} />
            </p>
          </div>
          <div className="flex items-center mb-2">
            {/* <span className="mr-2">Phone Number:</span> */}
            <p className="text-sm font-semibold text-header-text">{user.attributes.phone}</p>
          </div>
          {/* <div className="flex items-center">
            <span className="mr-2">Address: </span>
            <p className="ext-lg font-semibold ">{address}</p>
          </div> */}
          <div className="bg-gradient-to-r md:w-1/2 mt-4 flex items-top justify-around from-secondary to-secondary/75 rounded-lg p-2 w-full">
            <h2 className="text-sub-text text-sm font-semibold flex items-center">Savings Made with {process.env.REACT_APP_WHITELISTED_NAME}</h2>

            <div className="text-center flex p-4 text-secondary text-lg font-bold items-center rounded-full bg-background-primary">
              <FaRupeeSign />
              {user.attributes.savings}
              {/* <p className="text-sub-text text-lg font-bold">Rs. {user.attributes.savings}</p> */}
            </div>
          </div>
        </div>

        {/* Form to Update User Information */}
        {
          <div className={` z-60  bottom-0 flex ${edit ? 'h-full' : 'h-0'} bottom-0  w-full text-start bg-opacity-40 bg-background-secondary fixed`}>
            <form onSubmit={handleFormSubmit} className="">
              <div style={{ height: edit ? '30%' : '0px', bottom: edit ? '0px' : '-50px', transition: 'height 0.5s', transitionTimingFunction: 'linear' }} className="w-full p-3 rounded-t-lg shadow-lg  fixed bg-background-primary">
                <div className="flex flex-col">
                  <div className="flex gap-2 items-center mb-2">
                    <FaTimes
                      onClick={() => {
                        setShowMap(false);
                        setEdit(false);
                      }}
                    />
                    {/* <label htmlFor="name" className="text-sm font-semibold">
              Username
            </label> */}
                  </div>
                  <label htmlFor="name" className="text-sm font-semibold">
                    Name
                  </label>
                  <input type="text" id="name" placeholder="Enter your name" value={tempName} onChange={(e) => setTempName(e.target.value.replace(/[^a-z]/gi, ''))} className="block mt-2 w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-accent focus:border-accent" />
                </div>

                {/* <div>
            <label htmlFor="phoneNumber" className="text-sm font-semibold">
              Phone Number
            </label>
            <input
              type="text"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="block w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-accent focus:border-accent"
            />
          </div> */}

                {/* <div>
            <label htmlFor="address" className="text-sm font-semibold">
              Address
            </label>
            <input
              type="text"
              id="address"
              value={tempAddress}
              onChange={(e) => setTempAdress(e.target.value.replace(/[^\w\s]/gi,''))}
              className="block w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-accent focus:border-accent"
            />
          </div> */}

                {!loading && (
                  <button
                    onClick={() => {
                      handleUpdateUser();
                    }}
                    type="submit"
                    className="bg-accent mt-4 text-sub-text py-2 px-4 rounded-md hover:bg-accent/75"
                  >
                    Update
                  </button>
                )}
                {loading && (
                  <div className="p-4 flex flex-col w-full items-center justify-center mt-1 gap-4">
                    {' '}
                    <div role="status" className="flex items-center justify-center h-8 w-8">
                      <svg className="w-24 h-24 mr-2 text-sub-text animate-spin dark:text-content-text fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                      </svg>
                      {/* <span className="sr-only">Loading...</span> */}
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        }
      </div>
    </div>
  );
};

export default ProfilePage;
