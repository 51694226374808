import { Autocomplete, GoogleMap, Circle, Marker, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useRef, useState } from 'react';
import HomeFloatingButton from '../items/HomeFloatingButton';
import { useNavigate } from 'react-router-dom';
import { IoMdArrowBack, IoMdCheckmark, IoMdHome, IoMdPin } from 'react-icons/io';
const mapContainerStyle = {
  width: '100%',
  height: '100%',
};
const libraries = ['places'];

export default function Map({ address, mapError, uniqueKey, handleSetLocation, userLocation, setUserLocation, setShowMap, showMap }) {
  const markerRef = useRef(null);
  const circleRef = useRef(null);
  const [latlng, setLatLng] = useState();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleLocationSuccess, handleLocationError);
    }
  }, []);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries: libraries,
  });
  const handleLocationSuccess = (position) => {
    const { latitude, longitude } = position.coords;

    // setLatLng({lat:latitude, lng:longitude });
  };

  const handleLocationError = (error) => {
    // console.log('Error getting user location:', error);
  };
  const handleMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();

    setLatLng(e.latLng);
    setUserLocation({ latitude: e.latLng.lat(), longitude: e.latLng.lng() });
    //   const updatedPlace = { ...place };
    //   updatedPlace.geometry.location = e.latLng;
    //   onMapClick(updatedPlace);
  };

  const autocompleteRef = useRef(null);
  const [loadMap, setLoadMap] = useState(false);

  function handlePlaceSelect() {
    if (autocompleteRef.current !== null) {
      const selectedPlace = autocompleteRef.current.getPlace();
      // setPlace(selectedPlace);
      if (selectedPlace && selectedPlace.geometry && selectedPlace.geometry.location) {
        const lat = selectedPlace.geometry.location.lat();
        const lng = selectedPlace.geometry.location.lng();
        setLatLng(selectedPlace.geometry.location);
        setUserLocation({ latitude: lat, longitude: lng });

        // Perform any additional actions with the lat and lng values
      }
    }
  }

  const handleLocateMe = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setLatLng({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.log(`Geolocation error: ${error.message}`);
        },
      );
    } else {
      console.log('Geolocation is not supported by your browser.');
    }
  };

  return (
    <div className="z-50 bg-background-primary w-full h-full inset-0 fixed">
      <div className="flex justify-between w-full">
        <div className="w-full">
          {showMap && isLoaded && (
            <Autocomplete className="w-full" onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)} onPlaceChanged={handlePlaceSelect}>
              <div className="flex items-center w-full p-3 gap-2">
                <IoMdArrowBack
                  onClick={() => {
                    setShowMap(false);
                  }}
                  size={24}
                  className=""
                />
                <input className="w-full focus:outline-none bg-background-primary" placeholder="Search Location" />
              </div>
            </Autocomplete>
          )}
        </div>
        {/* <button
        onClick={()=>{
            handleSetLocation()
            setShowMap(false)
           
          }
          
          }
            
        className="fixed top-14 z-30 right-4 p-4 bg-secondary text-sub-text rounded-full shadow-lg"
      >
        <IoMdCheckmark className="h-6 w-6" />
      </button> */}
      </div>
      {showMap && isLoaded && (
        <GoogleMap
          options={{ streetViewControl: false, mapTypeControl: false, fullscreenControl: false, zoomControl: false }}
          key={uniqueKey}
          mapContainerStyle={mapContainerStyle}
          center={{ lat: userLocation.latitude, lng: userLocation.longitude }}
          zoom={17}
          onClick={(e) => {
            const lat = e.latLng.lat();

            const lng = e.latLng.lng();
            setLatLng(e.latLng);

            // console.log(e.latLng)
            setUserLocation({ latitude: e.latLng.lat(), longitude: e.latLng.lng() });
            //   const updatedPlace = { ...place };
            //  updatedPlace.geometry.location = e.latLng;
            //   onMapClick(updatedPlace);
          }}
          onLoad={(e) => {
            setLatLng(e.getCenter());
          }}
        >
          {latlng && (
            <MarkerF
              onFlatChanged={() => {
                console.log('changed');
              }}
              position={latlng}
              draggable={true}
              ref={markerRef}
              onDragEnd={handleMarkerDragEnd}
              onLoad={(marker) => (markerRef.current = marker)}
            />
          )}
        </GoogleMap>
      )}
      <div className="bottom-0 fixed w-full">
        <div className="flex justify-center w-full">
          <button
            className="animate-bounce flex gap-2 justify-center items-center bg-accent-lighter rounded-md py-1 px-2 bg-opacity-80"
            onClick={() => {
              handleLocateMe();
            }}
          >
            <div className="w-2 h-2 bg-primary rounded-full animate-ping"></div>
            <div className=" font-semibold text-sm">Locate Me</div>
          </button>
        </div>
        <div className="w-full bg-background-primary rounded-t-lg flex items-start justify-start flex-col p-4 ">
          <span className="text-sm text-content-text font-semibold">Select Location</span>
          <div className="text-header-text flex items-center gap-1 text-start font-bold text-lg">
            <IoMdPin className=" fill-primary" /> {address.split(' ')[0]}
          </div>
          <span className="text-sm text-content-text text-start font-medium">{address}</span>
          <button
            onClick={() => {
              handleSetLocation();
              setShowMap(false);
            }}
            className="w-full p-1 rounded-md mt-2 bg-primary text-sub-text"
          >
            Confirm Location
          </button>
        </div>
      </div>
    </div>
  );
}
