import React, { useState, useEffect } from 'react';
import CartItemCard from '../items/CartItemCard';
import { useCreateOrderMutation, useDeleteCartMutation, useGetCartQuery, useSendNotificationMutation, useUpdateCartMutation, useCreateCartMutation } from '../../state/api';
import { Preferences } from '@capacitor/preferences';
import { HiMapPin } from 'react-icons/hi2';
import { ToastContainer, toast } from 'react-toastify';
import Geocode from 'react-geocode';
import { useLocation, useNavigate } from 'react-router-dom';
import Map from './Map';
import { IoMdArrowBack } from 'react-icons/io';
import WhitelistedItemCard from './WhitelistedItemCard';

const WhitelistedCart = ({ isOpen, toggleDrawer, children, isOtherMerchant, otherCartId, merchant, user, cart, dbCart, handleSetCart, updateCartData }) => {
  const [orderCreate] = useCreateOrderMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOrderPlace, setIsOrderPlaced] = useState(false);
  const [address, setAddress] = useState('');
  const [points, setPoints] = useState(1);
  const [loading, setLoading] = useState(false);
  const [userAddress, setUserAddress] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [userLocation, setUserLocation] = useState();
  const [sendNotification] = useSendNotificationMutation();
  const [progressWidth, setProgressWidth] = useState(10);
  const [isUserWithinRadius, setIsUserWithingRadius] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [purchaseMethod, setPurchaseMethod] = useState('In-Store');
  const [mapError, setMapError] = useState(false);
  const handlePlaceOrder = (isRedeem) => {
    if (!merchant.acceptingOrders) {
      alert('Merchant is not accepting orders at the moment');
      return;
    }

    if (isRedeem && points * user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.loyalty.data.attributes.pointWorth > totalPrice) {
      alert('Points cannot exceed the total');
      return;
    }
    if (purchaseMethod === 'Home-Delivery' && address.trim().length <= 0) {
      alert('Please enter detailed address');
      return;
    } else {
      setIsOrderPlaced(true);
      setLoading(true);
      let placeOrder = isRedeem
        ? {
            userLatitude: userLocation.latitude,
            userLongitude: userLocation.longitude,
            isPointsRedeemed: true,
            pointsToRedeem: points,
            products: cart,
            address: address,
            name: user.attributes.name,
            merchant: merchant.id,
            customer: user.id,
            phoneClient: user.attributes.phone,
            date: new Date(),
            total: totalPrice,
            discount: totalDiscount,
            purchaseMethod: purchaseMethod,
            phoneMerchant: merchant.phone,
          }
        : {
            userLatitude: userLocation.latitude,
            userLongitude: userLocation.longitude,
            products: cart,
            address: address,
            name: user.attributes.name,
            merchant: merchant.id,
            customer: user.id,
            phoneClient: user.attributes.phone,
            date: new Date(),
            total: totalPrice,
            discount: totalDiscount,
            purchaseMethod: purchaseMethod,
            phoneMerchant: merchant.phone,
          };
      orderCreate({ order: placeOrder, token: localStorage.getItem('token') })
        .unwrap()
        .then((d) => {
          if (dbCart) {
            deleteCart({ cart: dbCart, token: localStorage.getItem('token') })
              .unwrap()
              .then((c) => {
                const data = {
                  to: `merchant_${merchant.phone.replace('+', '')}`,
                  title: 'New Order!',
                  content: 'You have a new order!',
                };
                sendNotification({
                  body: data,
                  token: localStorage.getItem('token'),
                })
                  .unwrap()
                  .then((v) => {
                    setLoading(false);
                    toast.success('Order placed successfully!');
                    let tempOrder = {
                      id: d.data.id,
                      attributes: {
                        ...d.data.attributes,
                        merchant: {
                          data: { id: merchant.id, attributes: merchant },
                        },
                      },
                    };

                    navigate('/orders/detail', {
                      state: { order: tempOrder, cart: true },
                      replace: true,
                    });
                    setIsOrderPlaced(false);
                    updateCartData([]);
                    setShowCircularProgress(false);
                    setProgressWidth(10);
                  })
                  .catch((e) => {
                    setLoading(true);
                    toast.error(e.message);
                    setIsOrderPlaced(false);
                    setShowCircularProgress(false);
                    setProgressWidth(10);
                  });
              })
              .catch((e) => {
                setLoading(false);
                toast.error(e.message);
                setIsOrderPlaced(false);
                setShowCircularProgress(false);
                setProgressWidth(10);
              });
          } else {
            const data = {
              to: `merchant_${merchant.phone.replace('+', '')}`,
              title: 'New Order!',
              content: 'You have a new order!',
            };
            sendNotification({
              body: data,
              token: localStorage.getItem('token'),
            })
              .unwrap()
              .then((v) => {
                setLoading(true);
                toast.success('Order placed successfully!');
                let tempOrder = {
                  id: d.data.id,
                  attributes: {
                    ...d.data.attributes,
                    merchant: {
                      data: { id: merchant.id, attributes: merchant },
                    },
                  },
                };

                navigate('/orders/detail', {
                  state: { order: tempOrder, cart: true },
                  replace: true,
                });
                updateCartData([]);
                setShowCircularProgress(false);
                setProgressWidth(10);
              })
              .catch((e) => {
                setLoading(false);
                setShowCircularProgress(false);
                setProgressWidth(10);
                toast.error(e.message);
                setIsOrderPlaced(false);
              });
          }
        })
        .catch((e) => {
          setLoading(false);
          setIsOrderPlaced(false);
          setShowCircularProgress(false);
          setProgressWidth(10);
          toast.error(e.message);
        });
      // console.log(placeOrder)
    }
  };
  const [redeemLoading, setRedeemLoading] = useState(false);
  const [showCircularProgress, setShowCircularProgress] = useState(false);

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180); // Difference in latitude in radians
    const dLon = (lon2 - lon1) * (Math.PI / 180); // Difference in longitude in radians
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance;
  }

  function isWithinRadius(userLatitude, userLongitude, targetLatitude, targetLongitude, radiusInKm) {
    const distance = calculateDistance(userLatitude, userLongitude, targetLatitude, targetLongitude);
    return distance <= radiusInKm;
  }

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
  Geocode.setLanguage('en');

  useEffect(() => {
    Preferences.get({ key: 'userLocation' }).then((v) => {
      var userLocationTemp = JSON.parse(v.value);
      setUserLocation(userLocationTemp);
      const userLatitude = userLocationTemp.latitude; // User's latitude
      const userLongitude = userLocationTemp.longitude; // User's longitude
      const targetLatitude = merchant.latitude; // Target location's latitude
      const targetLongitude = merchant.longitude; // Target location's longitude
      const radiusInKm = merchant.deliveryRadius; // Radius in kilometers

      const isWithinRadiusCheck = isWithinRadius(userLatitude, userLongitude, targetLatitude, targetLongitude, radiusInKm);

      if (isWithinRadiusCheck) {
        setIsUserWithingRadius(true);
      } else {
        setIsUserWithingRadius(false);
      }
      geocodeAddress(userLocationTemp.latitude, userLocationTemp.longitude);
    });
  }, []);
  const geocodeAddress = (latitude, longitude) => {
    Geocode.fromLatLng(latitude, longitude)
      .then((response) => {
        if (response.results[0].address_components.length > 3) {
          const shortAddress = response.results[0].address_components[1].long_name + ', ' + response.results[0].address_components[2].long_name + ', ' + response.results[0].address_components[3].long_name;

          setUserAddress(shortAddress);
        } else {
          const shortAddress = response.results[0].formatted_address;
          setUserAddress(shortAddress);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const removeProduct = (product) => {
  //   console.log("asadsdareasd",cart)
  //   let orderTemp = cart;
  //   orderTemp = cart.filter((p) => p.id != product.id);

  //   // localStorage.setItem("order", JSON.stringify(orderTemp))
  //   // setOrder(orderTemp)

  //   // handleSetCart(orderTemp);

  //   let items = 0;
  //   let price = 0;
  //   let disc = 0;
  //   for (let index = 0; index < orderTemp.cart.length; index++) {
  //     const element = orderTemp.cart[index];
  //     items += element.originalQuantity;
  //     price +=
  //       (element.unit === "g" || element.unit === "ml"
  //         ? element.price / 1000
  //         : element.price) * element.originalQuantity;
  //     disc +=
  //       (element.unit === "g" || element.unit === "ml"
  //         ? element.discount / 1000
  //         : element.discount) * element.originalQuantity;
  //   }
  //   setTotalItems(items);
  //   setTotalDiscount(disc);
  //   setTotalPrice(price);
  // };

  const removeProduct = (product) => {
    // Filtering the cart based on the condition
    let orderTemp = cart.filter((p) => p.id != product.id && Object.keys(p).length !== 0); // Exclude empty objects
    updateCartData(orderTemp);

    if (orderTemp.length === 0) {
      if (dbCart) {
        deleteCart({ cart: dbCart, token: localStorage.getItem('token') });
      }
    }

    // Calculate items, price, and discount from the filtered cart
    let items = 0;
    let price = 0;
    let disc = 0;
    // for (let index = 0; index < orderTemp.length; index++) {
    //     const element = orderTemp[index];
    //     items += element.originalQuantity;
    //     price +=
    //         (element.unit === "g" || element.unit === "ml"
    //             ? element.price / 1000
    //             : element.price) * element.originalQuantity;
    //     disc +=
    //         (element.unit === "g" || element.unit === "ml"
    //             ? element.discount / 1000
    //             : element.discount) * element.originalQuantity;
    // }

    // // Update state variables with the new values
    // setTotalItems(items);
    // setTotalDiscount(disc);
    // setTotalPrice(price);
  };

  const [carts, setCart] = useState([]);

  const handlesetCart = (item, quantity, unit) => {
    let itemTemp = item.attributes;
    itemTemp = {
      ...itemTemp,
      quantity: quantity,
      originalQuantity: quantity,
      id: item.id,
      unit: unit,
    };

    const index = cart.findIndex((i) => i.id == item.id);

    if (quantity === 0) {
      if (index !== -1) {
        updateCartData((prevItems) => prevItems.filter((cart) => cart.id != item.id));
        if (cart.length === 1) {
          navigate('/store-details', {
            state: {
              merchant: merchant,
              user: user,
              refreshCart: true,
              cart: [],
              dbCart: dbCart,
            },
            replace: true,
          });
        }
      }
    } else {
      if (index === -1) {
        // Item does not exist in the array, so add it
        updateCartData((prevItems) => prevItems.concat(itemTemp));
      } else {
        // Item exists in the array, so update it
        updateCartData((prevItems) =>
          prevItems.map((i) => {
            if (i.id == item.id) {
              return {
                ...i,
                quantity: quantity,
                originalQuantity: quantity,
              };
            }
            return i;
          }),
        );
      }
    }
  };

  useEffect(() => {
    // console.log(order)
    if (cart) {
      // localStorage.removeItem("order")
      if (cart.length === 0) {
        let items = 0;
        let price = 0;
        let disc = 0;
        for (let index = 0; index < location.state.cart.length; index++) {
          const element = location.state.cart[index];
          items += element.originalQuantity;
          price += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 : element.price) * element.originalQuantity;
          disc += (element.unit === 'g' || element.unit === 'ml' ? element.discount / 1000 : element.discount) * element.originalQuantity;
        }
        setTotalItems(items);
        setTotalDiscount(disc);
        setTotalPrice(price);
      } else {
        let items = 0;
        let price = 0;
        let disc = 0;
        for (let index = 0; index < cart.length; index++) {
          const element = cart[index];
          items += element.originalQuantity;
          price += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 : element.price) * element.originalQuantity;
          disc += (element.unit === 'g' || element.unit === 'ml' ? element.discount / 1000 : element.discount) * element.originalQuantity;
        }
        setTotalItems(items);
        setTotalDiscount(disc);
        setTotalPrice(price);
      }
    }
  }, [cart]);
  const [createCart] = useCreateCartMutation();
  const [updateCart] = useUpdateCartMutation();
  const [deleteCart] = useDeleteCartMutation();
  const [cartDeleted, setCartDeleted] = useState(false);

  const handleCartApi = (isBackNavigation) => {
    if (!user) {
      return navigate(-1);
    }
    if (cartDeleted) {
      window.location.replace('/');
    } else {
      if (dbCart && cart.length > 0) {
        let total = 0;
        let discount = 0;
        let subtotal = 0;

        for (let index = 0; index < cart.length; index++) {
          const element = cart[index];
          subtotal += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 : element.price) * element.originalQuantity;
          total += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 - element.discount / 1000 : element.price - element.discount) * element.originalQuantity;
          discount += (element.unit === 'g' || element.unit === 'ml' ? element.discount / 1000 : element.discount) * element.originalQuantity;
        }
        const cartData = {
          id: dbCart.id,
          merchant: merchant?.id,
          customer: user?.id,
          products: cart,
          total: total,
          discount: discount,
          subtotal: subtotal,
        };
        updateCart({ cart: cartData, token: localStorage.getItem('token') })
          .unwrap()
          .then((d) => {
            window.history.back();
          })
          .catch((e) => {
            window.history.back();
          });
      } else if (dbCart && cart.length === 0) {
        deleteCart({ cart: dbCart, token: localStorage.getItem('token') })
          .unwrap()
          .then((c) => {
            window.history.back();
          })
          .catch((e) => {
            window.history.back();
          });
      } else if (!dbCart && cart?.length > 0) {
        let total = 0;
        let discount = 0;
        let subtotal = 0;

        for (let index = 0; index < cart.length; index++) {
          const element = cart[index];

          subtotal += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 : element.price) * element.originalQuantity;
          total += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 - element.discount / 1000 : element.price - element.discount) * element.originalQuantity;
          discount += (element.unit === 'g' || element.unit === 'ml' ? element.discount / 1000 : element.discount) * element.originalQuantity;
        }

        if (otherCartId && isOtherMerchant) {
          const cartData = {
            id: otherCartId,
            merchant: merchant?.id,
            customer: user?.id,
            products: cart,
            total: total,
            discount: discount,
            subtotal: subtotal,
          };
          updateCart({ cart: cartData, token: localStorage.getItem('token') })
            .unwrap()
            .then((d) => {
              window.history.back();
            })
            .catch((e) => {
              window.history.back();
            });
        } else {
          const order = {
            merchant: merchant?.id,
            customer: user?.id,
            products: cart,
            total: total,
            discount: discount,
            subtotal: subtotal,
          };
          createCart({ cart: order, token: localStorage.getItem('token') })
            .unwrap()
            .then((d) => {
              window.history.back();
            })
            .catch((e) => {
              window.history.back();
            });
        }
      } else {
        window.history.back();
      }
    }
  };

  return (
    <div className="mt-0 mb-32">
      <div className="bg-background-primary z-10 fixed top-0 text-header-text w-full py-3 px-4 flex justify-start items-center gap-8 shadow-sm">
        <div className="flex items-center gap-4">
          <IoMdArrowBack
            onClick={() => {
              handleCartApi(true);
              toggleDrawer();
            }}
            size={24}
          />
          <span className="text-sm font-extrabold">{merchant.name}</span>
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row mt-2">
        <div className="w-full md:shadow-md md:h-screen md:w-2/4 text-sm px-2">
          {/* Cart summary */}

          <div className=" bg-background-primary rounded-md p-4">
            <h2 className="text-md font-bold mb-4 text-start">Bill Details</h2>
            {!isUserWithinRadius && (
              <div className="mb-2 w-full flex items-start">
                <span className="text-sm text-content-text w-full text-start">*Delivery unavailable in your area</span>
              </div>
            )}
            <div className="flex justify-between mb-2">
              <span className="text-sm">Purchase Method</span>
              <select
                onChange={(e) => {
                  setPurchaseMethod(e.target.value);
                }}
                className="bg-background-primary focus:outline-none font-bold text-sm"
              >
                <option value="In-Store">In Store</option>
                <option value="Book-And-Pickup">Book And Pickup</option>
                {merchant.deliveryRadius && merchant.deliveryRadius > 0 && isUserWithinRadius && <option value="Home-Delivery">Home Delivery</option>}
              </select>
            </div>

            <div className="flex text-sm justify-between mb-2 mt-2">
              <span>Subtotal</span>
              <span className="font-bold text-sm">₹{totalPrice.toFixed(2)}</span>
            </div>
            <div className="flex text-sm justify-between mb-2">
              <span>Discount</span>
              <span className="font-bold text-sm text-secondary">₹{totalDiscount.toFixed(2)}</span>
            </div>
            <div className="flex text-sm justify-between mb-2">
              <span>Total</span>
              <span className="font-bold text-sm">₹{(totalPrice - totalDiscount).toFixed(2)}</span>
            </div>
          </div>
          {purchaseMethod === 'Home-Delivery' && (
            <div className="text-start p-4">
              <div className="flex w-full justify-between items-center">
                <div className="flex gap-1 items-center">
                  <HiMapPin className="text-primary" />
                  <span className="text-start mt-0.5 text-sm font-semibold">Delivering to</span>
                </div>
                <span
                  className="text-start text-primary text-sm underline"
                  onClick={() => {
                    setShowMap(true);
                  }}
                >
                  Change
                </span>
              </div>
              {<h4 className="text-start mt-2 mb-2 text-sm">{userAddress}</h4>}
              <input
                className="appearance-none border rounded w-full py-2 px-3 text-header-text leading-tight focus:outline-none focus:shadow-outline"
                id="address"
                type="text"
                placeholder="Enter house no./building/block"
                name="address"
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
          )}

          {/* Place order button */}
          {!loading && (
            <div className="p-4">
              <button
                onClick={() => {
                  handlePlaceOrder(false);
                }}
                className="bg-secondary font-bold shadow-md shadow-content-text text-sub-text py-3 px-6 rounded-md w-full mt-8"
              >
                Place Order
              </button>
            </div>
          )}
          {loading && !showCircularProgress && (
            <div className="p-4">
              <div className="w-full bg-background-secondary rounded-full ">
                <div
                  className="bg-secondary text-xs font-medium text-accent-lighter text-center p-3 leading-none rounded-full"
                  style={{
                    width: `${progressWidth}%`,
                    transition: 'width 2s',
                    transitionTimingFunction: 'linear',
                  }}
                ></div>
              </div>
            </div>
          )}
          {showCircularProgress && (
            <div className="p-4">
              <div role="status" className="flex items-center justify-center w-full">
                <svg className="w-12 h-12 mr-2 text-sub-text animate-spin  fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
          )}

          {user.attributes.customer_points && user.attributes.customer_points.data && user.attributes.customer_points.data?.length > 0 && user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id && cp.attributes.points > 0)?.length > 0 && (
            <div className="  bg-gradient-to-tl from-background-primary to-accent-lighter shadow-md m-4 rounded-lg  p-4">
              <h2 className="text-md font-bold mb-4 text-[#336288] text-start">Redeem Points</h2>
              <div className="flex justify-between items-center">
                <span className="text-sm mt-2">Redeem</span>
                <div className="justify-center items-center flex flex-col">
                  <span className="text-sm font-normal text-content-text"> 1 point = ₹{user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.loyalty.data.attributes.pointWorth}</span>
                  <div className="flex gap-2 mb-2 items-center bg-[#336288] rounded-md">
                    <button
                      onClick={(e) => {
                        points - 1 === 0 ? setPoints(1) : setPoints(points - 1);
                      }}
                      type="button"
                      className="h-7 w-7 text-sub-text font-bold  "
                    >
                      -
                    </button>
                    <input
                      type="number"
                      className="h-7 w-9 text-center bg-[#336288] focus:outline-none text-sub-text font-bold"
                      min={1}
                      value={points}
                      //  defaultValue={points}
                      max={user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.points < totalPrice ? user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.points : totalPrice}
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onChange={(e) => {
                        if (e.target.value.trim()?.length === 0) {
                          setPoints(1);
                        } else if (typeof e.target.value !== NaN) {
                          if (/^[0-9]*$/.test(e.target.value)) {
                            if (user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.points * user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.loyalty.data.attributes.pointWorth < totalPrice) {
                              if (Number.parseInt(e.target.value) <= user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.points) {
                                setPoints(Number.parseInt(e.target.value));
                              }
                            } else {
                              if (Number.parseInt(e.target.value) * user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.loyalty.data.attributes.pointWorth <= totalPrice) {
                                setPoints(Number.parseInt(e.target.value));
                              }
                            }
                          }
                        }
                      }}
                    />

                    <button
                      onClick={(e) => {
                        if (user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.points * user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.loyalty.data.attributes.pointWorth < totalPrice) {
                          if (points + 1 <= user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.points) {
                            setPoints(points + 1);
                          }
                        } else {
                          if ((points + 1) * user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.loyalty.data.attributes.pointWorth <= totalPrice) {
                            setPoints(points + 1);
                          }
                        }
                      }}
                      type="button"
                      className="h-7 w-7 text-sub-text font-bold flex justify-center items-center"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <span className="text-xs w-full mb-2">*You can redeem max {user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.points * user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.loyalty.data.attributes.pointWorth < totalPrice ? user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.points : Number.parseInt(totalPrice / user.attributes.customer_points.data.filter((cp) => cp.attributes.merchant.data && cp.attributes.merchant.data.id === merchant.id)[0].attributes.loyalty.data.attributes.pointWorth)} points</span>
              {!loading && (
                <button
                  onClick={() => {
                    handlePlaceOrder(true);
                  }}
                  className="bg-[#237890] font-bold shadow-md text-sub-text py-2 px-6 rounded-md w-full mt-4"
                >
                  Redeem & Place Order
                </button>
              )}
              {redeemLoading && (
                <div className="w-full bg-background-secondary rounded-full ">
                  <div
                    className="bg-[#237890] text-xs font-medium text-accent-lighter text-center p-3 leading-none rounded-full"
                    style={{
                      width: `${progressWidth}%`,
                      transition: 'width 2s',
                      transitionTimingFunction: 'linear',
                    }}
                  ></div>
                </div>
              )}
              {/* <div className="flex text-sm justify-between mb-2 mt-2">
                    <span>Subtotal</span>
                    <span className="font-bold text-sm">₹{totalPrice.toFixed(2)}</span>
                  </div>
                  <div className="flex text-sm justify-between mb-2">
                    <span>Discount</span>
                    <span className="font-bold text-sm text-secondary">₹{totalDiscount.toFixed(2)}</span>
                  </div>
                  <div className="flex text-sm justify-between">
                    <span>Total</span>
                    <span className="font-bold text-sm">₹{(totalPrice-totalDiscount).toFixed(2)}</span>
                  </div> */}
            </div>
          )}
        </div>

        <div className="container mx-auto px-4 py-8 border-b-2 border-dashed md:border-none text-sm">
          {/* <h2 className="text-sm font-bold mb-4 text-start">Order Detail</h2> */}

          {/* Cart items */}
          {cart?.length > 0 && cart.map((item, i) => <CartItemCard removeProduct={removeProduct} handleSetCart={handlesetCart} product={item} key={item.id} />)}
        </div>

        {/* Modal Template */}
        {/* <Modal isOpen={isModalOpen} closeModal={closeModal}>
<div>Please enter detailed address</div>
        </Modal> */}
      </div>
    </div>
  );
};

export default WhitelistedCart;
