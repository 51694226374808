import { FaPhone, FaTimes } from "react-icons/fa"
import { HiPhone } from "react-icons/hi2"

export default function ContactBottomSheet({showBottom, setShowBottom, showMap,setShowMap, contacts}){
    return (
        <div>
             { <div className={` z-50  bottom-0 flex ${showBottom ? "h-full" : "h-0"} bottom-0   w-full text-start bg-opacity-40 bg-background-primary fixed`} ><div className="">
        <div style={{height: showBottom ? "40%" : "0px", bottom: showBottom ? "0px" : "-50px",transition:"height 0.5s", transitionTimingFunction:"linear" }} className='w-full overflow-auto rounded-t-xl shadow-lg  fixed bg-background-primary'>
         <div className='flex flex-col '>
            <div className='flex gap-2  p-3 fixed bg-background-primary z-10 rounded-xl w-full items-center'>
            <FaTimes onClick={()=>{
              setShowMap(false)
              setShowBottom(false)}}/>
            <label htmlFor="name" className="text-sm font-semibold  rounded-md">
              Available Contacts
            </label>
           
            </div>
            <div className=" w-full  p-3 mt-6 flex flex-col ">

         {contacts && contacts.map((c)=>{
            return  <div onClick={()=>{
                window.location.href = `tel:${c}`;
            }} className="flex w-full bg-background-secondary mt-4 rounded-md p-2 font-semibold ">
            <HiPhone className="text-primary mr-2" size={18}/>
          <label htmlFor="name" className="text-sm  w-full bg-background-secondary underline rounded-md font-semibold ">
             {c}
            </label>
          </div>
         })}
           
            </div>
          </div>
 
          </div>
        </div>
        </div>}
        </div>
    )
}