import React, { useState } from 'react';

//React Drawer
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { HiMapPin } from 'react-icons/hi2';
import WhitelistedCart from './WhitelistedCart';
import { IoMdArrowBack } from 'react-icons/io';
import { IoCart } from 'react-icons/io5';
import { IoCartOutline } from 'react-icons/io5';
import { FaCircle } from 'react-icons/fa';
import { IoRemoveOutline } from 'react-icons/io5';
import { useCreateOrderMutation, useDeleteCartMutation, useGetCartQuery, useSendNotificationMutation, useUpdateCartMutation, useCreateCartMutation } from '../../state/api';

const WhitelistedDrawer = ({ isOtherMerchant, otherCartId, merchant, user, cart, dbCart, toggleDrawer, isOpen, handleSetCart, updateCartData }) => {
  let totalItems = 0;
  for (let index = 0; index < cart?.length; index++) {
    const element = cart[index];
    totalItems += element.originalQuantity;
  }

  const [createCart] = useCreateCartMutation();
  const [updateCart] = useUpdateCartMutation();
  const [deleteCart] = useDeleteCartMutation();
  const handleCartApi = (isBackNavigation) => {
    if (dbCart && cart.length > 0) {
      let total = 0;
      let discount = 0;
      let subtotal = 0;

      for (let index = 0; index < cart.length; index++) {
        const element = cart[index];
        subtotal += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 : element.price) * element.originalQuantity;
        total += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 - element.discount / 1000 : element.price - element.discount) * element.originalQuantity;
        discount += (element.unit === 'g' || element.unit === 'ml' ? element.discount / 1000 : element.discount) * element.originalQuantity;
      }
      const cartData = {
        id: dbCart.id,
        merchant: merchant?.id,
        customer: user?.id,
        products: cart,
        total: total,
        discount: discount,
        subtotal: subtotal,
      };
      updateCart({ cart: cartData, token: localStorage.getItem('token') })
        .unwrap()
        .catch((e) => {
          window.history.back();
        });
    } else if (dbCart && cart.length === 0) {
      deleteCart({ cart: dbCart, token: localStorage.getItem('token') })
        .unwrap()
        .catch((e) => {
          window.history.back();
        });
    } else {
      if (!dbCart && cart?.length > 0) {
        let total = 0;
        let discount = 0;
        let subtotal = 0;

        for (let index = 0; index < cart.length; index++) {
          const element = cart[index];

          subtotal += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 : element.price) * element.originalQuantity;
          total += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 - element.discount / 1000 : element.price - element.discount) * element.originalQuantity;
          discount += (element.unit === 'g' || element.unit === 'ml' ? element.discount / 1000 : element.discount) * element.originalQuantity;
        }

        if (otherCartId && isOtherMerchant) {
          const cartData = {
            id: otherCartId,
            merchant: merchant?.id,
            customer: user?.id,
            products: cart,
            total: total,
            discount: discount,
            subtotal: subtotal,
          };
          updateCart({ cart: cartData, token: localStorage.getItem('token') })
            .unwrap()
            .catch((e) => {
              window.history.back();
            });
        } else {
          const order = {
            merchant: merchant?.id,
            customer: user?.id,
            products: cart,
            total: total,
            discount: discount,
            subtotal: subtotal,
          };
          createCart({ cart: order, token: localStorage.getItem('token') })
            .unwrap()
            .catch((e) => {
              window.history.back();
            });
        }
      }
    }
  };

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        toggleDrawer();
        if (cart.length > 0) {
          handleCartApi(true);
        }
      }}
      direction="right"
      className="bg-background-primary" // Change this class to whatever Tailwind class you want for the background color
      // size="80vw" // 50% of the viewport width, adjust as needed
      size={window.innerWidth > 768 ? '80vw' : '90vw'}
      lockBackgroundScroll={true}
    >
      <div className="h-full overflow-y-auto bg-background-primary ">
        {totalItems === 0 && (
          <div className="bg-background-primary z-10 fixed top-0 text-header-text w-full py-3 px-4 flex justify-start items-center gap-8 shadow-sm mb-24">
            <div className="flex items-center gap-4  ">
              <IoMdArrowBack
                onClick={() => {
                  toggleDrawer();
                }}
                size={24}
              />
              <span className="text-sm font-extrabold">{merchant.name}</span>
            </div>
          </div>
        )}
        {totalItems !== 0 ? (
          <WhitelistedCart
            isOtherMerchant={isOtherMerchant}
            otherCartId={otherCartId}
            dbCart={dbCart}
            merchant={merchant}
            user={user}
            cart={cart}
            handleSetCart={handleSetCart}
            toggleDrawer={toggleDrawer}
            updateCartData={updateCartData} // Pass handleSetCart as a prop
          />
        ) : (
          //     <div className="h-full overflow-y-auto bg-background-primary flex justify-center items-center relative">
          //     {/* Render the circle */}
          //     <div className="absolute">
          //       <FaCircle className="text-secondary-lighter translate-x-2 " style={{ fontSize: "150px" }} />
          //     </div>

          //     <div className="py-6 px-2 text-md relative">
          //       {/* Position the cart icons */}
          //       <div className="absolute top-0 left-0 transform -translate-x-4">
          //         <IoCart className="text-primary-lighter" />
          //       </div>
          //       <div className="absolute top-0 left-0 transform -translate-x-4">
          //         <IoCartOutline className="text-header" />
          //       </div>
          //       {/* You can adjust the positioning using top, bottom, left, right properties */}
          //     </div>

          //   </div>

          <div className="h-full overflow-y-auto bg-background-primary flex justify-center items-center relative mb-32">
            <div className="absolute">
              {/* Render the circle */}
              <FaCircle className="text-secondary-lighter translate-x-2 -translate-y-2" style={{ fontSize: '120px' }} />
            </div>

            <div className="py-6 px-2 text-md relative">
              {/* Position the cart icons */}
              <div className="absolute top-0 left-0 transform -translate-x-4 ">
                <IoCart className="text-primary-lighter" />
              </div>
              {/* <div className="absolute top-0 left-0 transform -translate-x-4 translate-y-7">
          <IoRemoveOutline className="text-primary-lighter" />
           
          </div> */}
              <div className="absolute top-0 left-0 transform -translate-x-4">
                <IoCartOutline className="text-header" />
              </div>
              <div className="absolute  transform -translate-x-4 translate-y-12 ">
                <div className="text-center font-medium text-sm mt-4">Your cart is empty</div>
              </div>
              {/* You can adjust the positioning using top, bottom, left, right properties */}
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default WhitelistedDrawer;
