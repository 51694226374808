import React, { useEffect, useState } from 'react';
import { IoMdQrScanner } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import { FaShoppingCart } from 'react-icons/fa';
import { BrowserView } from 'react-device-detect';
import WhitelistedDrawer from '../home/WhitelistedDrawer';
import { useCreateOrderMutation, useDeleteCartMutation, useGetCartQuery, useSendNotificationMutation, useUpdateCartMutation, useCreateCartMutation } from '../../state/api';
const FloatingButton = ({ merchant, cart, isOtherMerchant, otherCartId, dbCart, user, handleCartApi, handleSetCart, updateCartData }) => {
  const navigate = useNavigate();
  let totalItems = 0;
  let totalPrice = 0;
  for (let index = 0; index < cart?.length; index++) {
    const element = cart[index];
    totalItems += element.originalQuantity;
  }

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
    // Toggle body overflow
    document.body.style.overflow = isOpen ? 'auto' : 'hidden';
  };

  const [lastSavedCart, setLastSavedCart] = React.useState([]);

  const [createCart] = useCreateCartMutation();
  const [updateCart] = useUpdateCartMutation();
  const [deleteCart] = useDeleteCartMutation();
  const handleCartSave = (isBackNavigation) => {
    if (dbCart && cart.length > 0) {
      let total = 0;
      let discount = 0;
      let subtotal = 0;

      for (let index = 0; index < cart.length; index++) {
        const element = cart[index];
        subtotal += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 : element.price) * element.originalQuantity;
        total += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 - element.discount / 1000 : element.price - element.discount) * element.originalQuantity;
        discount += (element.unit === 'g' || element.unit === 'ml' ? element.discount / 1000 : element.discount) * element.originalQuantity;
      }
      const cartData = {
        id: dbCart.id,
        merchant: merchant?.id,
        customer: user?.id,
        products: cart,
        total: total,
        discount: discount,
        subtotal: subtotal,
      };
      updateCart({ cart: cartData, token: localStorage.getItem('token') })
        .unwrap()
        .catch((e) => {
          window.history.back();
        });
    } else if (dbCart && cart.length === 0) {
      deleteCart({ cart: dbCart, token: localStorage.getItem('token') })
        .unwrap()
        .catch((e) => {
          window.history.back();
        });
    } else {
      if (!dbCart && cart?.length > 0) {
        let total = 0;
        let discount = 0;
        let subtotal = 0;

        for (let index = 0; index < cart.length; index++) {
          const element = cart[index];

          subtotal += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 : element.price) * element.originalQuantity;
          total += (element.unit === 'g' || element.unit === 'ml' ? element.price / 1000 - element.discount / 1000 : element.price - element.discount) * element.originalQuantity;
          discount += (element.unit === 'g' || element.unit === 'ml' ? element.discount / 1000 : element.discount) * element.originalQuantity;
        }

        if (otherCartId && isOtherMerchant) {
          const cartData = {
            id: otherCartId,
            merchant: merchant?.id,
            customer: user?.id,
            products: cart,
            total: total,
            discount: discount,
            subtotal: subtotal,
          };
          updateCart({ cart: cartData, token: localStorage.getItem('token') })
            .unwrap()
            .catch((e) => {
              window.history.back();
            });
        } else {
          const order = {
            merchant: merchant?.id,
            customer: user?.id,
            products: cart,
            total: total,
            discount: discount,
            subtotal: subtotal,
          };
          createCart({ cart: order, token: localStorage.getItem('token') })
            .unwrap()
            .catch((e) => {
              window.history.back();
            });
        }
      }
    }
  };

  return (
    <div>
      {/* <BrowserView className=""> */}
      {totalItems !== 0 && (
        <button
          onClick={() => {
            toggleDrawer();
            handleCartSave(true);
          }}
          className="fixed bottom-20 z-20 right-4 p-4 bg-primary text-sub-text rounded-full shadow-lg hover:bg-primary-darker"
        >
          <FaShoppingCart className="h-5 w-5" />
          {totalItems !== 0 && <div className=" absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500  rounded-full -top-1 -end-1 ">{totalItems}</div>}
        </button>
      )}
      {/* </BrowserView> */}

      <button
        onClick={() => {
          navigate(`/items/scan`, {
            state: {
              isOtherMerchant: isOtherMerchant,
              otherCartId: otherCartId,
              dbCart: dbCart,
              merchant: merchant,
              user: user,
              cart: cart,
            },
          });
        }}
        className="fixed bottom-2 z-20 right-4 p-4 bg-primary text-sub-text rounded-full shadow-lg hover:bg-primary-darker"
      >
        <IoMdQrScanner className="h-6 w-6" />
      </button>

      <WhitelistedDrawer
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        isOtherMerchant={isOtherMerchant}
        otherCartId={otherCartId}
        dbCart={dbCart}
        merchant={merchant}
        user={user}
        handleCartApi={handleCartApi}
        cart={cart}
        handleSetCart={handleSetCart} // Update prop name here
        updateCartData={updateCartData}
      />
    </div>
  );
};

export default FloatingButton;
