import React, { useState } from 'react';


function CancelButton({handleOrderUpdate}) {
 const [isCancelled, setIsCancelled] = useState(false);


 const cancelOrder = () => {
   handleOrderUpdate()
 };


 return (
   <div className='w-full shadow-md '>
     {!isCancelled ? (
       <button
       type="button"
       className=" w-full  items-center px-1 py-1  text-xs font-semibold text-primary-darker border border-primary-darker rounded-md "
       onClick={cancelOrder}
     >
       
       <span className='text-xs'>CANCEL ORDER</span>
     </button>



     ) : (
        <button
    type="button"
    className="inline-flex w-full items-center px-4 py-2 text-sm font-medium text-header-text bg-background-primary border-2 border-primary rounded-lg bg-background-primary hover:text-accent-darker focus:z-10 focus:ring-2 focus:ring-accent-darker focus:text-accent-darker  dark:border-content-text dark:text-sub-text dark:hover:text-sub-text  dark:focus:ring-accent dark:focus:text-sub-text">
    <span>Cancelled</span>
    </button>

     )}
   </div>
 );
}


export default CancelButton;