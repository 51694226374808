import { GoogleMap, useJsApiLoader, DirectionsRenderer, MarkerF } from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { app, fireBaseVapidKey } from '../../../firebaseConfig';
import { Capacitor, Plugins } from '@capacitor/core';
import axios from 'axios';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoMdPin, IoMdArrowBack } from 'react-icons/io';
import ons from 'onsenui';
import Modal from '../../../components/items/Modal';
import PermissionModal from '../../../components/items/PermissionModal';
import Loading from '../../../components/Loading';
import LostComponent from '../../../components/LostComponent';

const centerPoints = { lat: 15.3517777, lng: 73.892076 };
const zoom = 25;
const mapContainerStyle = {
  width: '100%',
  height: '100vh',
};
const mapOptions = {
  zoomControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
};

const TrackOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const orderId = params.get('order');

  const [order, setOrder] = useState(null);
  const [permission, setPermission] = useState('default');
  const [showPermissionDenied, setShowPermissionDenied] = useState(false);
  const [showPermissionRequired, setShowPermissionRequired] = useState(false);
  const [riderLocation, setRiderLocation] = useState(null);

  const [directionResponse, setDirectionResponse] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);

  const [showLoading, setShowLoading] = useState(false);

  const [showBottom, setShowBottom] = useState(false);

  // useEffect(() => {
  //  // keep this to understand the structure of the rider payload
  //   setRiderLocation({
  //     id: 3,
  //     latitude: 15.3517777,
  //     longitude: 73.892076,
  //   });
  // }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY ?? '',
    libraries: ['places'],
  });

  useEffect(() => {
    const getFCMToken = async () => {
      const messaging = await getMessaging(app);

      getToken(messaging, { vapidKey: fireBaseVapidKey })
        .then(async (currentToken) => {
          if (!currentToken) {
            return toast.error('Unable to notification token');
          } else {
            localStorage.setItem('fcmToken', currentToken);
          }
        })
        .catch((err) => {
          setShowPermissionRequired(false);
        });

      onMessage(messaging, (payload) => {
        if (payload.data.rider) {
          const riderPayload = JSON.parse(payload.data.rider);
          if (riderPayload.rider.toString() === params.get('order')) {
            setRiderLocation(JSON.parse(payload.data.rider));
          }
        }
      });
    };

    if (Capacitor.getPlatform() === 'web') getFCMToken();
  }, [Capacitor.getPlatform()]);

  useEffect(() => {
    if (order !== null || order !== undefined) {
      setShowLoading(false);
    }
  }, [order]);

  useEffect(() => {
    if (!order) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/orders/${orderId}?populate=*`, {
          headers: {
            Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
          },
        })
        .then((res) => {
          setOrder(res.data.data);

          if (res.data.data.attributes.purchaseMethod === 'Home-Delivery' && res.data.data.attributes.status !== 'Out-for-delivery') {
            setModalOpen(true);
            closeModalWithTimeout();
          }

          if (Capacitor.getPlatform() === 'web') {
            const data = {
              topic: `track-orders`,
              token: localStorage.getItem('fcmToken'),
            };

            if (res.data.data.attributes.purchaseMethod === 'Home-Delivery' || res.data.data.attributes.status === 'Out-for-delivery') {
              axios
                .post(`${process.env.REACT_APP_BASE_URL}/api/auth/subscribe-to-topic`, data, {
                  headers: {
                    Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
                  },
                })
                .then((resp) => {
                  const subscriptionConfirmation = resp.data;
                })
                .catch((error) => {
                  // return toast.error('Error!! wont be able to recieve notification');
                  // window.location.reload();
                });
            } else {
              axios
                .post(`${process.env.REACT_APP_BASE_URL}/api/auth/unsubscribe-from-topic`, data, {
                  headers: {
                    Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
                  },
                })
                .then((resp) => {
                  const topic = resp;
                })
                .catch((error) => {
                  const err = error;
                });
            }
          }
        })
        .catch((error) => {
          toast.error(error.message);
          setShowLoading(false);
          navigate('/home');
        });
    }
  }, []);

  useEffect(() => {
    ons.ready(() => {
      ons.enableDeviceBackButtonHandler();
      ons.setDefaultDeviceBackButtonListener((ev) => {
        if (showBottom === true) {
          setShowBottom(false);
        } else {
          navigate(-1);
        }
      });
    });
  }, [showBottom]);

  useEffect(() => {
    window.addEventListener('ack', function (msg) {
      if (msg.ack === 'zm200' || msg.ack === 'zm301') {
        setModalOpen(true);
        closePermissionModalWithTimeout();
        refreshData();
      }
    });
  }, []);

  useEffect(() => {
    if (window && order) {
      window.addEventListener('rider', function (msg) {
        if (msg.rider) {
          if (orderId === msg.rider.rider) {
            setRiderLocation(msg.rider);
          }
        }
      });
    }
  }, [order]);

  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
    navigate(-1);
  };

  const closeModalWithTimeout = () => {
    setTimeout(() => {
      closeModal();
    }, 5000);
  };
  const closePermissionModal = () => {
    setShowPermissionDenied(false);
    setShowPermissionRequired(false);
  };
  const closePermissionModalWithTimeout = () => {
    // Close the modal after 5000 milliseconds (5 seconds)
    setTimeout(() => {
      closePermissionModal();
    }, 5000);
  };

  const refreshData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/orders/${order.id}?populate=*`, {
        headers: { Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '' },
      })
      .then((response) => {
        setOrder(response.data.data);
      })
      .catch((error) => {
        const err = error;
      });
  };

  const calculateRoute = async () => {
    if (!origin || !destination || !window.google?.maps) {
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: origin,
      destination: destination,
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    if (results && results?.routes && results?.routes?.length > 0) {
      setDirectionResponse(results);
      setDistance(results.routes[0]?.legs[0]?.distance?.text ?? '');
      setDuration(results.routes[0]?.legs[0]?.duration?.text ?? '');
    }
  };

  useEffect(() => {
    if (origin && destination && isLoaded && !directionResponse) {
      calculateRoute();
    }
  }, [origin, destination, isLoaded]);

  useEffect(() => {
    if (order) {
      const { userLatitude, userLongitude } = order.attributes;
      if (userLatitude && userLongitude && !destination) {
        setDestination({ lat: parseFloat(userLatitude), lng: parseFloat(userLongitude) });
      }
    }
  }, [order]);

  useEffect(() => {
    const { status } = order?.attributes || {};
    const { latitude, longitude } = riderLocation || {};

    if (status === 'Out-for-delivery' && latitude && longitude && !origin) {
      setOrigin({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
    }
  }, [order, riderLocation]);

  useEffect(() => {
    const merchant = order?.attributes?.merchant?.data;
    if (order?.attributes?.purchaseMethod === 'Home-Delivery' && merchant?.id) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/merchant-features-table?filters[$and][0][merchant][id][$eq]=${merchant?.id}&filters[feature]=delivery-partner`, {
          headers: {
            Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
          },
        })
        .then((res) => {
          const merchantFeatures = res.data.data;
          if (merchantFeatures?.length > 0) {
            const ZundermartRider = merchantFeatures.find((item) => item.attributes.feature === 'delivery-partner');
            if (!ZundermartRider || !ZundermartRider?.attributes?.zmStatus || !ZundermartRider?.attributes.merchantToggle) {
              alert('Merchant does not support this feature currently');
              navigate(-1);
            }
          }
        })
        .catch((error) => {
          alert('Merchant does not support this feature currently');
          // navigate(-1);
        });
    }
  }, [order]);

  useEffect(() => {
    if (permission === 'default') {
      Notification.requestPermission().then((result) => {
        setPermission(result);
        if (result === 'denied') {
          setShowPermissionDenied(true);
          setShowPermissionRequired(false);
        }

        if (result === 'granted') {
          setShowPermissionRequired(false);
        }
      });
    }

    if (Notification.permission === 'default') {
      setShowPermissionRequired(true);
    }
  }, [permission]);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-1 bg-background-secondary select-none ">
        <div className="bg-background-primary z-10 fixed top-0 text-header-text w-full py-3 px-4 flex justify-center shadow-sm items-center ">
          <div className="bg-background-primary z-10 fixed top-0 text-header-text w-full py-3 px-4 flex justify-center shadow-sm items-center ">
            <div className="flex w-full justify-between items-center">
              <div className="flex items-center gap-4">
                <IoMdArrowBack
                  onClick={() => {
                    navigate(-1);
                  }}
                  size={24}
                />
              </div>
              <div className="w-full font-bold  text-lg items-center text-center flex justify-center">Track Order</div>
              <div
                onClick={() => {
                  const phoneNumber = order?.attributes?.phoneMerchant || '+919940285902';

                  window.open(`tel:${phoneNumber}`);
                }}
                className=" font-normal text-md underline gap-1 text-primary items-center text-end flex justify-center"
              >
                Support
              </div>
            </div>
          </div>
        </div>

        {showLoading ? (
          <Loading />
        ) : (
          order && (
            <div>
              {order?.attributes?.purchaseMethod !== 'Home-Delivery' ? (
                <LostComponent />
              ) : (
                <div>
                  {order?.attributes?.status === 'Out-for-delivery' && (
                    <div>
                      <div className="flex-1 bg-background-primary  mt-10">
                        {isLoaded && (
                          <GoogleMap mapContainerStyle={mapContainerStyle} zoom={zoom} center={centerPoints} options={mapOptions}>
                            {directionResponse && <DirectionsRenderer directions={directionResponse} />}

                            {riderLocation && (
                              <MarkerF
                                position={{
                                  lat: parseFloat(riderLocation.latitude),
                                  lng: parseFloat(riderLocation.longitude),
                                }}
                              />
                            )}
                          </GoogleMap>
                        )}
                      </div>

                      <div className="bottom-0 fixed w-full">
                        <div className="flex justify-center w-full"></div>
                        <div className="w-full bg-background-primary rounded-t-lg flex items-start justify-start flex-col p-4 ">
                          <span className="text-sm text-content-text font-semibold">Delivery Details</span>

                          <span className="text-lg text-content-text font-semibold">Total delivery time: {duration ? <span>{duration}</span> : <span className="animate-pulse">Calculating...</span>}</span>

                          <span className="text-lg text-content-text font-semibold">Total distance: {duration ? <span>{distance}</span> : <span className="animate-pulse">Calculating...</span>}</span>

                          <div className="text-header-text flex items-center gap-1 text-start font-semibold text-md">
                            <IoMdPin className=" text-accent" />
                            {order?.attributes?.address || ''}
                          </div>
                          <span className="text-sm text-content-text text-start font-medium">
                            <p className="text-content-text my-1 text-sm ">
                              Name: <span className=" text-content-text font-semibold ">{order.attributes.rider.data?.attributes?.name}</span>
                            </p>
                          </span>

                          <span className="text-sm text-content-text text-start font-medium">
                            <p className="text-content-text my-1 text-sm ">
                              Delivery status: <span className=" text-content-text font-semibold ">Out for delivery</span>
                            </p>
                          </span>

                          <Link href={`tel:${order.attributes.phoneRider}`} className="w-full py-2 rounded-md mt-2 bg-accent font-medium text-sub-text justify-center text-center">
                            <div onClick={() => {}}>Call Delivery Partner</div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}

                  <Modal isOpen={isModalOpen} closeModal={closeModal}>
                    <div className="text-center py-2 max-w-md">
                      {order?.attributes?.status === 'Delivered' ? <h2 className="text-xl font-semibold  text-accent ">Order has been delivered!</h2> : order?.attributes?.status === 'Cancelled' || order?.attributes?.status === 'User-Cancelled' ? <h2 className="text-xl font-semibold  text-alert ">Order has been cancelled!</h2> : <h2 className="text-xl font-semibold  text-accent ">Tracking Not available yet</h2>}

                      {order?.attributes?.status === 'Delivered' ? <img src="/delivered.png" alt="accepted" width={500} height={500} /> : order?.attributes?.status === 'Cancelled' || order?.attributes?.status === 'User-Cancelled' ? <img src="/cancelled.png" alt="cancelled" width={500} height={500} /> : <img src="/pending.png" alt="pending" width={500} height={500} />}

                      <div className="flex justify-between mt-2 ">
                        <button
                          onClick={() => {
                            closeModal();
                          }}
                          className={`${'bg-success/90 hover:bg-success active:bg-success-darker'} text-sub-text font-semibold py-2 px-4 rounded-md w-full`}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </Modal>
                </div>
              )}
            </div>
          )
        )}

        <PermissionModal isOpen={showPermissionDenied} closeModal={closePermissionModal}>
          <div className="text-center py-2 ">
            <img className=" object-contain" src="/notification_denied.png" alt="accepted" />
          </div>
        </PermissionModal>

        <PermissionModal isOpen={showPermissionRequired} closeModal={closePermissionModal}>
          <div className="text-center py-2 ">
            <img className=" object-contain" src="/permission_allow.png" alt="accepted" />
          </div>
        </PermissionModal>
      </div>
    </div>
  );
};

export default TrackOrder;
