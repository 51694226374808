import React from 'react';

const TrackOrderCard = ({ orderID, date, status, selectedStatus, setStatus }) => {
  const getStatusClass = () => {
    switch (status) {
      case 'Pending':
        return 'bg-yellow-500';
      case 'Accepted':
        return 'bg-orange-500';
      case 'Packaging':
        return 'bg-blue-500';
      case 'Ready':
        return 'bg-purple-500';
      case 'Out-for-delivery':
        return 'bg-purple-500';
      case 'Delivered':
        return 'bg-green-500';
      case 'Cancelled':
        return 'bg-red-500';
      case 'User-Cancelled':
        return 'bg-red-500';
      case 'User-Cancelled':
        return 'bg-red-500';
      default:
        return 'bg-gray-500';
    }
  };

  const getTextClass = () => {
    switch (status) {
      case 'Pending':
        return 'text-yellow-500';
      case 'Accepted':
        return 'text-orange-500';
      case 'Packaging':
        return 'text-blue-500';
      case 'Ready':
        return 'text-purple-500';
      case 'Out-for-delivery':
        return 'text-purple-500';
      case 'Delivered':
      case 'Delivered':
        return 'text-green-500';
      case 'Cancelled':
        return 'text-red-500';
      case 'User-Cancelled':
        return 'text-red-500';
      default:
        return 'text-gray-500';
    }
  };

  return (
    <div
      onClick={() => {
        status === 'All' ? setStatus('') : setStatus(status);
      }}
      className={`flex px-2 rounded-md hover:bg-primary-lighter/50 py-2 items-center cursor-pointer w-full ${selectedStatus === status ? 'bg-primary-lighter/50' : selectedStatus === '' && status === 'All' ? ' bg-primary-lighter/50' : 'bg-none'} mt-2`}
    >
      {/* Outer Circle */}
      <div>
        <div className="w-full">
          <div className="relative ">
            <div className="h-8 w-8 rounded-full border-2 border-dashed border-content-text" />

            {/* Inner Circle */}
            <div className={`absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 h-3 w-3 rounded-full ${getStatusClass()}`} />
          </div>
          {/* <div className='h-12 w-full flex justify-center items-center align-middle'>
      <div className='h-12 w-0 border-2 border-dashed border-content-text'>
        
        </div>
      </div> */}
        </div>
      </div>

      {/* Order Details */}
      <div className="ml-4 text-start">
        <h2 className={`text-lg font-bold ${getTextClass()}`}>{status}</h2>
        {/* <p className="text-header-text font-semibold">{orderID}</p>
        <p className="text-content-text">{date}</p> */}
      </div>
    </div>
  );
};

export default TrackOrderCard;
